import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import SmsConfirmation from "../pages/SmsConfirmation";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import PageNotFound from "../pages/PageNotFound";

export default function AuthRoutes() {
  const [sendNumberRes, setSendNumberRes] = useState({});
  return (
    <Routes>
      <Route path="*" element={<Login setSendNumberRes={setSendNumberRes} />} />
      <Route path="/login" element={<Login setSendNumberRes={setSendNumberRes} />} />
      <Route
        path="/signup"
        element={
          <SignUp
            setSendNumberRes={setSendNumberRes}
            sendNumberRes={sendNumberRes}
          />
        }
      />

      {!!sendNumberRes.status && sendNumberRes.status === 200 && (
        <Route
          path="/smsconfirmation"
          element={<SmsConfirmation sendNumberRes={sendNumberRes} />}
        />
      )}
    </Routes>
  );
}
