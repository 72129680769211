import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import MessageReadUser from "./MessageReadUser";

export default function TutorMessageItem({item}) {
  const [openMessage, setOpenMessage] = useState(false);

  const handleOpenMessage = () => setOpenMessage(!openMessage);


  return (
    <Box
      sx={{
        borderRadius: 3,
        px: 1,
        py: 2,
        mb: 1,
        background: (theme) =>
          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Avatar
            alt={item.fullname}
            src={item.img}
            sx={{ width: 24, height: 24, mr: 1 }}
          />
          <Typography
            color="text.primary"
            sx={{
              fontSize: 16,
              fontWeigth: 500,
              fontFamily: "Ubuntu, sans-serif",
            }}
          >
            {item.fullname}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: openMessage ? "auto" : 45,
          overflow: "hidden",
          position: "relative",
          transition: "all 0.3s linear !important",
        }}
      >
        {!openMessage && (
          <Box
            onClick={handleOpenMessage}
            sx={{
              width: "100%",
              height: "100%",
              background: (theme) =>
                theme.palette.mode === "light"
                  ? "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 65%, rgba(255, 255, 255, 1) 100%)"
                  : "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 65%, #262627 100%)",
              overflow: "hidden",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          ></Box>
        )}
        <Typography
          color="text.primary"
          sx={{
            fontSize: 16,
            fontFamily: "Ubuntu, sans-serif",
            fontWeight: 400,
            textAlign: "justify",
            mt: 1,
            textIndent: 20,
          }}
        >
          {item.xabar}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            color="text.secondary"
            sx={{
              fontSize: 14,
              fontWeigth: 500,
              fontFamily: "Ubuntu, sans-serif",
            }}
          >
            13:00 11/05/2023
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
