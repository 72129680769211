import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import fetching from '../lottie/fetching-loading.json';
import GroupData from './GroupData';
import PageNotFound from './PageNotFound';

export default function Group() {
	const params = useParams();
	const groups = useSelector((state) => state.groups);
	const name = params.groupId;
	const [sort, setSort] = useState('firstName');
	const [sortType, setSortType] = useState(true);
	const students = groups.find((item) => item.name === name).id;
	console.log(sortType);

	students.sort((a, b) => {
		if (sort == 'firstName') {
			if (sortType) {
				if (a.first_name < b.first_name) {
					return -1;
				}
			} else {
				if (a.first_name > b.first_name) {
					return -1;
				}
			}
		} else if (sort == 'secondName') {
			if (sortType) {
				if (a.second_name < b.second_name) {
					return -1;
				}
			} else {
				if (a.second_name > b.second_name) {
					return -1;
				}
			}
		}
	});
	console.log(students);
	return (
		<Box>
			{groups.length > 0 ? (
				groups.map((el) => name === el.name).includes(true) ? (
					<GroupData
						student={students}
						sort={sort}
						setSort={setSort}
						sortType={sortType}
						setSortType={setSortType}
					/>
				) : (
					<PageNotFound />
				)
			) : (
				<PageNotFound data={fetching} />
			)}
		</Box>
	);
}
