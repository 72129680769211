import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import PageNotFound from './PageNotFound';
import GroupsData from './GroupsData';
import fetching from '../lottie/fetching-loading.json';

export default function Groups() {
	const groups = useSelector((state) => state.groups);
	groups.sort((a, b) => {
		if (a.name < b.name) {
			return -1;
		}
	});

	return (
		<Box sx={{ position: 'relative' }}>
			{!groups.length ? (
				<PageNotFound data={fetching} />
			) : (
				<GroupsData groups={groups} />
			)}
		</Box>
	);
}
