import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

export default function SelectTutorItem({ data, selectTutor, tutors }) {
  let active =
    tutors.filter((el) => el.id == data.employee_id_number).length == 1;
  return (
    <Box
      onClick={() =>
        selectTutor([
          {
            id: data.employee_id_number,
          },
        ])
      }
      sx={{
        borderRadius: 3,
        background: (theme) =>
          theme.palette.mode === "light"
            ? active
              ? "#096bde3f"
              : "#fff"
            : active
            ? "#09090d"
            : "#09090d3f",
        border: (theme) =>
          theme.palette.mode === "light"
            ? "1px solid #0000002f"
            : "1px solid #ffffff2f",
        my: 0.5,
        display: "flex",
        flexDirection: "row",
        px: 1,
        py: 1,
        cursor: "pointer",
      }}
    >
      <Avatar
        sx={{ width: 24, height: 24, mr: 1 }}
        alt="Farid Tursunboyev"
        src={data.image}
      />
      <Typography
        color="text.primary"
        sx={{
          fontSize: 16,
          fontWeight: 400,
          fontFamily: "Ubuntu, sans-serif",
          textTransform: "capitalize !important",
        }}
      >
        {data.first_name.toLowerCase()} {data.second_name.toLowerCase()}
      </Typography>
    </Box>
  );
}
