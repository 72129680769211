import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
export default function StudentsPersonalInfo(props) {
  const month = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "Iyul",
    "Avgust",
    "Sentyabr",
    "Oktyabr",
    "Noyabr",
    "Dekabr",
  ];
  return (
    <Paper elevation={2} sx={{ borderRadius: 4, py: 3, px: 2, border: 'none' }}>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          color="text.secondary"
          sx={{
            fontSize: 20,
            fontWeight: "700",
            fontFamily: "Ubuntu, sans-serif",
          }}
        >
          Talaba ma'lumotlari
        </Typography>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "flex-start",
          "& .MuiTypography-root": {
            fontSize: 16,
            fontFamily: "Ubuntu, sans-serif",
          },
          "& .MuiBox-root": {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            mr: 1,
            my: 0.5,
            "& .MuiTypography-root": {
              color: "text.secondary",
              fontWeight: "400",
              minWidth: 130,
            },
          },
          "& .MuiStack-root": {
            width: "100%",
            pl: 1,
            "& .MuiTypography-root": {
              textTransform: 'capitalize',
              color: "text.primary",
              fontWeight: "500",
            },
          },
        }}
      >
        <Box>
          <Typography>Ismi:</Typography>
          <Stack>
            <Typography>{props.info.first_name.toLowerCase()} </Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Familiyasi:</Typography>
          <Stack>
            <Typography>{props.info.second_name.toLowerCase()}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Otasining ismi:</Typography>
          <Stack>
            {
              props.info.third_name.split(' ').length === 2 ? 
              <Typography>
                <span style={{textTransform: 'capitalize'}}>
                {props.info.third_name.split(' ')[0].toLowerCase()} &nbsp;
                </span>
                <span  style={{textTransform: 'lowercase'}}>
                {props.info.third_name.split(' ')[1].toLowerCase()}
                </span>
              </Typography>  
              : 
              <Typography>{props.info.third_name.toLowerCase()}</Typography>  
            }
          </Stack>
        </Box>
        <Box>
          <Typography>Jinsi:</Typography>
          <Stack>
            <Typography>{props.info.gender.name}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Tug'ilgan sana:</Typography>
          <Stack>
            <Typography>{`${new Date(props.info.birth_date * 1000).getDate()} ${
              month[new Date(props.info.birth_date * 1000).getMonth()]
            } ${new Date(props.info.birth_date * 1000).getFullYear()}`}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Mamlakat:</Typography>
          <Stack>
            <Typography>{props.info.country.name}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Viloyati:</Typography>
          <Stack>
          {
              props.info.province.name.split(' ').length === 2 ? 
              <Typography>
                <span style={{textTransform: 'capitalize'}}>
                {props.info.province.name.split(' ')[0].toLowerCase()} &nbsp;
                </span>
                <span  style={{textTransform: 'lowercase'}}>
                {props.info.province.name.split(' ')[1].toLowerCase()}
                </span>
              </Typography>  
              : 
              <Typography>{props.info.province.name.toLowerCase()}</Typography>  
            }
          </Stack>
        </Box>
        <Box>
          <Typography>Tumani:</Typography>
          <Stack>
          {
              props.info.district.name.split(' ').length === 2 ? 
              <Typography>
                <span style={{textTransform: 'capitalize'}}>
                {props.info.district.name.split(' ')[0].toLowerCase()} &nbsp;
                </span>
                <span  style={{textTransform: 'lowercase'}}>
                {props.info.district.name.split(' ')[1].toLowerCase()}
                </span>
              </Typography>  
              : 
              <Typography>{props.info.district.name.toLowerCase()}</Typography>  
            }
          </Stack>
        </Box>
        <Box>
          <Typography>Fuqaroligi:</Typography>
          <Stack>
            <Typography>{props.info.citizenship.name}</Typography>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
}
