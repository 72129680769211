import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import StudentsProfile from '../components/StudentsProfile';
import { useSelector } from 'react-redux';
import fetching from '../lottie/fetching-loading.json';

export default function Student(props) {
	const allStudents = useSelector((state) => state.allStudents);
	const params = useParams();
	const id =
		+params.studentId.split('_')[params.studentId.split('_').length - 1];
	const student = allStudents.filter((el) => {
		return el.id === id;
	});
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{!!allStudents.length ? (
				student.length === 1 &&
				params.studentId.includes('student_') ? (
					<StudentsProfile id={id} student={student[0]} />
				) : (
					<PageNotFound />
				)
			) : (
				<PageNotFound data={fetching} />
			)}
		</>
	);
}
