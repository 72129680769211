import { Box, Divider, Paper, Stack, Tooltip, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useState } from "react";
import StudentAvatar from "./StudentAvatar";
import CallNow from "./CallNow";
import { useSelector } from "react-redux";
import TutorPersonalInfo from "./TutorPersonalInfo";
import TutorFiles from "./TutorFiles";
import { useNavigate, useParams } from "react-router-dom";

export default function TutorData(props) {
  const params = useParams();
  const navigate = useNavigate();
  const allHemisId = useSelector((state) => state.allHemisId);
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => setWidth(window.innerWidth));
  const tel =
    allHemisId.length > 0 &&
    allHemisId.find((el) => el.hemis_id == props.tutor.employee_id_number)
      .username;
  props.tutor.tutorGroups.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
  });
  return (
    <Box>
      <Grid2 container>
        <Grid2 xs={12} md={6} sx={{ px: 1 }}>
          <Paper elevation={2} sx={{ borderRadius: 4, border: "none" }}>
            <Stack
              sx={{
                py: 3,
                px: 2,
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "column",
                  lg: "row",
                  overflow: "hidden",
                },
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <StudentAvatar img={props.tutor.image} />
              <Box
                sx={{
                  pl: { sm: 2 },
                  mt: { xs: 2, sm: 0, md: 2 },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: {
                    xs: "center",
                    sm: "flex-start",
                    md: "center",
                    lg: "flex-start",
                  },
                }}
              >
                <Tooltip title={props.tutor.full_name} placement="top" arrow>
                  <Typography
                    color="text.primary"
                    sx={{
                      fontSize: 20,
                      fontWeight: "600",
                      fontFamily: "Ubuntu, sans-serif",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                      pr: 1,
                    }}
                  >
                    {props.tutor.first_name.toLowerCase()}{" "}
                    {props.tutor.second_name.toLowerCase()}
                  </Typography>
                </Tooltip>
                <Typography
                  color="text.secondary"
                  sx={{
                    fontSize: {
                      xs: 15,
                      sm: 16,
                      md: 17,
                    },
                    fontWeight: "400",
                    fontFamily: "Ubuntu, sans-serif",
                    my: 1,
                    textAlign: { xs: "center", lg: "left" },
                  }}
                >
                  {`+${tel}`}
                </Typography>
              </Box>
            </Stack>
          </Paper>
          {navigator.userAgent.indexOf("Android") != -1 ? (
            <CallNow tel={tel} />
          ) : navigator.userAgent.indexOf("like Mac") != -1 ? (
            <CallNow tel={tel} />
          ) : (
            ""
          )}

          <Paper
            elevation={2}
            sx={{ borderRadius: 4, py: 3, px: 2, border: "none", mt: 2 }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                color="text.secondary"
                sx={{
                  fontSize: 20,
                  fontWeight: "700",
                  fontFamily: "Ubuntu, sans-serif",
                }}
              >
                Tyutor guruhlari
              </Typography>
            </Stack>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "flex-start",
                "& .MuiTypography-root": {
                  fontSize: { xs: 14, sm: 16 },
                  fontFamily: "Ubuntu, sans-serif",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  mr: 1,
                  my: 0.5,
                  "& .MuiTypography-root": {
                    color: "text.primary",
                    fontWeight: "400",
                    minWidth: 20,
                  },
                },
                "& .MuiStack-root": {
                  width: "100%",
                  pl: 1,
                  "& .MuiTypography-root": {
                    textTransform: "capitalize",
                    color: "text.primary",
                    fontWeight: "400",
                  },
                },
              }}
            >
              {props.tutor.tutorGroups.map((el, index) => {
                return (
                  <div key={index} style={{ width: "100%" }}>
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate(`/tutors/group/${el.id}`)}
                    >
                      <Typography>{index + 1}.</Typography>
                      <Stack>
                        <Typography>{el.name.replaceAll("_", " ")}</Typography>
                      </Stack>
                      <Typography color="text.primary" sx={{ mr: 2 }}>
                        {el.educationLang.name}
                      </Typography>
                    </Box>
                    <Divider sx={{ width: "100%", mt: -0.5, mb: 0.5 }} />
                  </div>
                );
              })}
            </Stack>
          </Paper>
        </Grid2>
        <Grid2 xs={12} md={6} sx={{ px: 1, mt: { xs: 2, md: 0 } }}>
          <TutorPersonalInfo width={width} info={props.tutor} />
        </Grid2>
      </Grid2>
      <Box sx={{ width: "100%", mt: 2 }}>
        <TutorFiles id={props.tutor.employee_id_number} />
      </Box>
    </Box>
  );
}
