import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import TutorSuccessDataTable from "../components/TutorSuccessDataTable";
import TutorWaitingDataTable from "../components/TutorWaitingDataTable";

export default function TyutorTopshiriqlar() {
  const [openSecondScreen, setOpenSecondScreen] = useState(false);

  const handleOpenSecondScreen = () => {
    setOpenSecondScreen(true);
  };
  const handleOpenFirstScreen = () => {
    setOpenSecondScreen(false);
  };

  return (
    <Box>
      <Typography
        color="text.primary"
        sx={{
          fontFamily: "Ubuntu, sans-serif",
          fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
          fontWeight: "700",
        }}
      >
        Topshiriqlar
      </Typography>
      <Grid2 container>
        <Grid2 xs={12} md={8} lg={10}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "500" }}
            color="text.secondary"
          >
            Sizga biriktirilgan talabalarning ahvolini va yashash sharoitlarini
            o'rganish, manzillarini platformaga joylashtirish maqsadida Yoshlar
            masalalari va ma'naviy-ma'rifiy ishlar bo'yicha birinchi prorektor
            tomonidan sizga yuborilgan topshiriqlar ro'yxati.
          </Typography>
        </Grid2>
        <Grid2
          xs={12}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        ></Grid2>
      </Grid2>
      <Box
        sx={{
          mt: { xs: 1, md: 4 },
          mb: { xs: 2, md: 3 },
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              border: (theme) =>
                theme.palette.mode === "light"
                  ? "1px solid #0000002f"
                  : "1px solid #ffffff2f",
              borderRadius: 3,
              p: 0.5,
              "& button": {
                borderRadius: 3,
                textTransform: "capitalize",
                fontSize: 16,
                fontFamily: "Ubuntu, sans-serif",
                color: "text.primary",
              },
            }}
          >
            <Button
              variant="container"
              disableElevation
              color="light"
              onClick={handleOpenFirstScreen}
              sx={{
                background: (theme) =>
                  theme.palette.mode === "light"
                    ? !openSecondScreen && "#096bde3f !important"
                    : !openSecondScreen && "#09090d !important",
              }}
            >
              Bajarilganlar
            </Button>
            <Button
              variant="container"
              disableElevation
              color="light"
              onClick={handleOpenSecondScreen}
              sx={{
                background: (theme) =>
                  theme.palette.mode === "light"
                    ? openSecondScreen && "#096bde3f !important"
                    : openSecondScreen && "#09090d !important",
              }}
            >
              Bajarilmaganlar
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          overflowX: "hidden",
        }}
      >
        <TutorSuccessDataTable screen={openSecondScreen} />
        <TutorWaitingDataTable screen={openSecondScreen} />
      </Box>
    </Box>
  );
}
