import React, { useState } from "react";
import { Box } from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";

export default function StudentAvatar(props) {
  const [showAvatar, setShowAvatar] = useState(false);
  return (
    <Box
      sx={{
        width: {xs: 200, sm: 120, md: 200, lg: 150},
        minWidth: {xs: 200, sm: 120, md: 200, lg: 150},
        height: {xs: 200, sm: 120, md: 200, lg: 150},
        borderRadius: "50%",
        position: !showAvatar ? "relative" : "static",
      }}
    >
      <Box
        onClick={() => setShowAvatar(!showAvatar)}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s opacity",
          position: "absolute",
          zIndex: !showAvatar ? 4 : 2,
          background: showAvatar ? "rgba(0,0,0,0.2)" : "none",
          borderRadius: showAvatar ? "0" : "50%",
          left: 0,
          top: 0,
          "& svg": {
            opacity: 0,
          },
          "&:hover": {
            background: !showAvatar ? "rgba(0,0,0,0.2)" : "none",
            "& svg": {
              opacity: 1,
              transform: "scale(1.2)",
            },
          },
        }}
      >
        <CropFreeIcon sx={{ width: 40, height: 40 }} />
      </Box>
      <Box
        onClick={() => setShowAvatar(!showAvatar)}
        sx={{
          width: !showAvatar ? "100%" : window.innerWidth,
          maxWidth: !showAvatar ? "100%" : window.innerWidth,
          height: !showAvatar ? "100%" : window.innerHeight,
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 3,
        }}
      >
        <img
          style={{
            borderRadius: !showAvatar ? "50%" : 0,
            objectFit: "cover",
            width: !showAvatar ? "100%" : "auto",
            height: !showAvatar ? "100%" : "53%",
            transition: "0.3s height",
          }}
          src={props.img.length > 1 ? props.img : 'http://locker.com.au/wp-content/uploads/2017/01/user-icon-png-person-user-profile-icon-20.png'}
          alt=""
        />
      </Box>
    </Box>
  );
}
