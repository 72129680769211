import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../pages/PageNotFound";
import Home from "../pages/Home";
import Documents from "../pages/Documents";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import UploadFile from "../pages/UploadFile";
import ManaviyatHeader from "../components/ManaviyatHeader";
import AddCatalog from "../pages/AddCatalog";
import Tutors from "../pages/Tutors";
import { useDispatch, useSelector } from "react-redux";
import { getAllDocument, getAllTutorsID, getCatalog, getTutors, getTutorsId } from "../api";
import { ALL_HEMIS_ID, CATALOGS, FILES, HEMIS_ID, TOTAL_USER_DATA, TUTORS } from "../redux/action";
import AllDocuments from "../pages/AllDocuments";
import Tutor from "../pages/Tutor";
import ManaviyatGroup from "../pages/ManaviyatGroup";
import ManaviyatStudent from "../pages/ManaviyatStudent";
import NewMissia from "../pages/NewMissia";
import YangiTopshiriq from "../pages/YangiTopshiriq";
import Topshiriqlar from "../pages/Topshiriqlar";

export default function ManaviyatRoutes() {
  const userData = useSelector((state) => state.userData);
  const hemisId = useSelector((state) => state.hemisId);
  const manaviyatGroups = useSelector((state) => state.manaviyatGroups);
  const dispatch = useDispatch();
  useEffect(() => {
    const request = async () => {
      const data = await getCatalog();
      dispatch({ type: CATALOGS, value: data });
    };
    request();
  }, [userData]);

  useEffect(() => {
    const request = async () => {
      const data = await getAllDocument("1");
      dispatch({
        type: FILES,
        value: { params: "page_1", data: data.results },
      });
    };
    request();
  }, []);

  useEffect(() => {
    const request = async () => {
      const data = await getTutorsId("1");
      dispatch({
        type: HEMIS_ID,
        value: { page: "page_1", data: data.results },
      });
    };
    request();
  }, []);

  useEffect(() => {
    const ids = !!hemisId.page_1 && hemisId.page_1.map((el) => el.hemis_id);
    if (!!hemisId.page_1) {
      const request = async () => {
        const data = await getTutors(ids);
        dispatch({
          type: TUTORS,
          value: { page: "page_1", data: data },
        });
      };
      request();
    }
  }, [hemisId]);

  
  return (
    <ManaviyatHeader>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tutors/*" element={<Tutors />} />
        <Route path="/tutors/:page" element={<Tutors />} />
        <Route path="/tutors/:page/:tutor" element={<Tutor />} />
        <Route path="/tutors/group/:group" element={<ManaviyatGroup />} />
        <Route path="/tutors/:page/:tutor/:filePage" element={<Tutor />} />
        <Route path="/student" element={<PageNotFound />} />
        <Route path="/students/:id" element={<ManaviyatStudent />} />
        <Route path="/documents/*" element={<AllDocuments />} />
        <Route path="/documents/:katalogName" element={<AllDocuments />} />
        <Route
          path="/documents/:katalogName/:page"
          element={<AllDocuments />}
        />
        <Route path="/documents/add_catalog" element={<AddCatalog />} />
        <Route path="/chat" element={<NewMissia />} />
        <Route path="create_assignment" element={<YangiTopshiriq />} />
        <Route path="assignments" element={<Topshiriqlar />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="404" element={<PageNotFound />} />
      </Routes>
    </ManaviyatHeader>
  );
}
