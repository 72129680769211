import React, { useEffect, useState } from "react";
import Header from "./Header";
import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Typography,
  createSvgIcon,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StudentItem from "./StudentsItem";
import TutorsItem from "./TutorsItem";

const GridIcon = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <rect width="10" height="10" rx="4" fill="currentColor" />
    <rect x="12" y="12" width="10" height="10" rx="4" fill="currentColor" />
    <rect y="12" width="10" height="10" rx="4" fill="currentColor" />
    <rect x="12" width="10" height="10" rx="4" fill="currentColor" />
  </svg>
);

const ListIcon = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <rect width="22" height="6" rx="4" fill="currentColor" />
    <rect y="8" width="22" height="6" rx="4" fill="currentColor" />
    <rect y="16" width="22" height="6" rx="4" fill="currentColor" />
  </svg>
);

export default function TutorsView(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const [sort, setSort] = useState("firstName");
  const [sortType, setSortType] = useState(true);
  const [view, setView] = useState(window.innerWidth < 360 ? "list" : 'grid');

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
    window.innerWidth < 360 && setView("list");
  });
  
  const handleSortType = () => {
    setSortType(!sortType);
  };

  const handleSort = (event) => {
    setSort(event.target.value);
  };

  props.tutors.sort((a,b)=>{
    if(sort == 'firstName'){
      if(sortType == true){
        if (a.first_name > b.first_name){
          return -1
        }
      }
      else{
        if (a.first_name < b.first_name){
          return -1
        }
      }
    }
    else if(sort == 'secondName') {
      if(sortType == true){
        if (a.second_name > b.second_name){
          return -1
        }
      }
      else{
        if (a.second_name < b.second_name){
          return -1
        }
      }
    }
  })

  useEffect(()=>{
    width < 360 && setView("list");
  },[width])
  return (
    <Box>
      <Typography
        color="text.primary"
        sx={{
          fontFamily: "Ubuntu, sans-serif",
          fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
          fontWeight: "700",
        }}
      >
        Tyutorlar
      </Typography>
      <Grid2 container>
        <Grid2 xs={12} md={8} lg={10}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "500" }}
            color="text.secondary"
          >
            O'zbekiston - Finlandiya institutida ish faoliyatini olib borayotgan tyutorlarning ro'yxati.
          </Typography>
        </Grid2>
        <Grid2
          xs={12}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <FormControl
            fullWidth
            size="small"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mt: { xs: 4, md: 0 },
              width: { xs: "100%", md: 200 },
            }}
          >
            <Box
              onClick={handleSortType}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 18, fontWeight: "600" }}
                color="text.secondary"
              >
                Saralash
              </Typography>
              <KeyboardArrowUpIcon
                sx={{
                  transition: "0.3s all linear",
                  color: "text.secondary",
                  transform:
                    sortType === false ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </Box>
            <Select
              value={sort}
              onChange={handleSort}
              sx={{
                width: "90px",
                color: "text.secondary",
                fontSize: 18,
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: "600",
                "& fieldset": {
                  border: "none !important",
                },
              }}
            >
              <MenuItem value={"firstName"} sx={{ color: "text.secondary" }}>
                Ismi
              </MenuItem>
              <MenuItem value={"secondName"} sx={{ color: "text.secondary" }}>
                familiyasi
              </MenuItem>
            </Select>
          </FormControl>
        </Grid2>
      </Grid2>
      <Box
        sx={{
          mt: { xs: 1, md: 4 },
          mb: { xs: 2, md: 3 },
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          color="text.primary"
          sx={{
            fontSize: 22,
            fontWeight: "600",
            fontFamily: "Ubuntu, sans-serif",
            opacity: 0.75,
          }}
        >
          Tyutorlar
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <GridIcon
            onClick={() => (width > 360 ? setView("grid") : setView("list"))}
            sx={{
              color: "text.secondary",
              background:
                view === "grid"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#121212"
                  : "none",
              p: 1,
              borderRadius: 3,
              mr: 1,
              display: width < 360 && "none",
              transition: '0.3s all linear',
              '&:hover': {
                background:
                view === "list"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#ffffff3f" : "#1212123f"
                  : (theme) =>
                  theme.palette.mode === "light" ? "#ffffff" : "#121212",
              }
            }}
          />
          <ListIcon
            onClick={() => setView("list")}
            sx={{
              color: "text.secondary",
              background:
                view === "list"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#121212"
                  : "none",
              p: 1,
              borderRadius: 3,
              transition: '0.3s all linear',
              '&:hover': {
                background:
                view === "grid"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#ffffff3f" : "#1212123f"
                  : (theme) =>
                  theme.palette.mode === "light" ? "#ffffff" : "#121212",
              }
            }}
          />
        </Box>
      </Box>
      <Grid2 container>
        {props.tutors.map((el) => {
          return (
            <TutorsItem
              key={el.id}
              id={el.employee_id_number}
              view={view}
              firstName={el.first_name}
              lastName={el.second_name}
              img={el.image}
              academicDegree={el.academicDegree.name}
              academicRank={el.academicRank.name}
              sort={sort}
              sortType={sortType}
              count={props.count}
            />
          );
        })}
      </Grid2>
      
    </Box>
  );
}
