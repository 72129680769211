import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
export default function StudentsPhoneInfo({ data }) {

  if (
    data.student_phone_number_1 == "" &&
    data.student_phone_number_2 == "" &&
    data.student_telegram == ""
  ) {
    return;
  } else {
    return (
      <Paper
        elevation={2}
        sx={{ mt: 2, borderRadius: 4, py: 3, px: 2, border: "none" }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            color="text.secondary"
            sx={{
              fontSize: 20,
              fontWeight: "700",
              fontFamily: "Ubuntu, sans-serif",
            }}
          >
            Talaba kontaktlari
          </Typography>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "flex-start",
            "& .MuiTypography-root": {
              fontSize: 16,
              fontFamily: "Ubuntu, sans-serif",
            },
            "& .MuiBox-root": {
              display: "flex",
              flexDirection: "row",
              width: "100%",
              mr: 1,
              my: 0.5,
              "& .MuiTypography-root": {
                color: "text.secondary",
                fontWeight: "400",
                minWidth: 130,
              },
            },
            "& .MuiStack-root": {
              width: "100%",
              pl: 1,
              "& .MuiTypography-root": {
                textTransform: "capitalize",
                color: "text.primary",
                fontWeight: "500",
              },
            },
          }}
        >
          {data.student_phone_number_1 !== "" && (
            <Box>
              <Typography>Telefon raqami:</Typography>
              <Stack>
                <Typography>{data.student_phone_number_1}</Typography>
              </Stack>
            </Box>
          )}
          {data.student_phone_number_2 !== "" && (
            <Box>
              <Typography>Telefon raqami:</Typography>
              <Stack>
                <Typography>{data.student_phone_number_2}</Typography>
              </Stack>
            </Box>
          )}
          {data.student_telegram !== "" && (
            <Box>
              <Typography>Telefon raqami:</Typography>
              <Stack>
                <Typography>{data.student_telegram}</Typography>
              </Stack>
            </Box>
          )}
        </Stack>
      </Paper>
    );
  }
}
