import axios from "axios";

const sentOtp = async (phoneNumber, hemisId, password, setSendNumberRes, setLoading, setMessage) => {
  try {
    const res = await axios({
      method: "POST",
      timeout: 60000,
      url: "https://api.tutor.uzfi.uz/send_otp/",
      data: {phone_number: phoneNumber.split(" ").join(""), password: password, hemis_id: hemisId},
    });
    setSendNumberRes({...(res.data), mission: 'signUp'})
    setLoading(false)
    setMessage({status: res.data.status, message: res.data.message})
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
const verifyOtp = async (sendNumberRes, sms, setLoading, setWaiting) => {
  try {
    const res = await axios({
      method: "POST",
      timeout: 60000,
      url: "https://api.tutor.uzfi.uz/verify_otp/",
      data: {hemis_id: sendNumberRes.hemisId, phone_number: sendNumberRes.phoneNumber, password: sendNumberRes.password, otp: sms},
    });
    setLoading(false)
    setWaiting(res.data)
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const sendLogin = async (tel, password, setLoading, setMessage, setSendNumberRes) => {
  try {
    const res = await axios({
      method: "POST",
      timeout: 30000,
      url: "https://api.tutor.uzfi.uz/send_sms/",
      data: {phone_number: tel.split(" ").join(""), password},
    });
    setLoading(false)
    setMessage({status: res.data.status, message: res.data.message})
    setSendNumberRes({...(res.data), mission: 'login'})
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const verifySms = async (phoneNumber, password, sms, setLoading) => {
  try {
    const res = await axios({
      method: "POST",
      timeout: 30000,
      url: "https://api.tutor.uzfi.uz/verify_sms/",
      data: {phone_number: phoneNumber, password: password, sms_code: sms, },
    });
    setLoading(false)
    if(res.data.status == 200 ) {
      window.localStorage.setItem('accessToken', res.data.Token);
      window.localStorage.setItem('Token', res.data.hemis_token)
    }
    return res.data;
  } catch (error) {
    console.log(error);
  }
};


export { sentOtp, verifyOtp, sendLogin, verifySms };