import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import GroupItem from "../components/GroupItem";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";

export default function GroupsData(props) {
  const [sort, setSort] = useState("nomi");
  const [sortType, setSortType] = useState(false);
  const allStudents = useSelector((state) => state.allStudents);
  
  const handleSortType = () => {
    setSortType(!sortType);
  };

  props.groups.sort((a,b)=>{
    if(sort == 'nomi'){
      if(sortType == true){
        if (a.name > b.name){
          return -1
        }
      }
      else{
        if (a.name < b.name){
          return -1
        }
      }
    }
    else if(sort == 'soni') {
      if(sortType == true){
        if (a.id.length > b.id.length){
          return -1
        }
      }
      else{
        if (a.id.length < b.id.length){
          return -1
        }
      }
    }
  })


  const handleSort = (event) => {
    setSort(event.target.value);
  };

  allStudents.sort((a, b)=>{ if(a.first_name < b.first_name) return -1})
  
  return (
    <Box>
      <Typography
        color="text.primary"
        sx={{
          fontFamily: "Ubuntu, sans-serif",
          fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
          fontWeight: "700",
        }}
      >
        Platform
      </Typography>
      <Grid2 container>
        <Grid2 xs={12} md={8} lg={10}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "500" }}
            color="text.secondary"
          >
            Sizga biriktirilgan guruhlar ro'yxati.
          </Typography>
        </Grid2>
        <Grid2
          xs={12}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <FormControl
            fullWidth
            size="small"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mt: { xs: 4, md: 0 },
              width: { xs: "100%", md: 200 },
            }}
          >
            <Box
              onClick={handleSortType}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 18, fontWeight: "600" }}
                color="text.secondary"
              >
                Saralash
              </Typography>
              <KeyboardArrowUpIcon
                sx={{
                  transition: "0.3s all linear",
                  color: "text.secondary",
                  transform:
                    sortType === false ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </Box>
            <Select
              value={sort}
              onChange={handleSort}
              sx={{
                width: "100px",
                color: "text.secondary",
                fontSize: 18,
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: "600",
                "& fieldset": {
                  border: "none !important",
                },
              }}
            >
              <MenuItem value={"nomi"} sx={{ color: "text.secondary" }}>
                Nomi
              </MenuItem>
              <MenuItem value={"soni"} sx={{ color: "text.secondary" }}>
                Soni
              </MenuItem>
            </Select>
          </FormControl>
        </Grid2>
      </Grid2>
      <Box sx={{ mt: { xs: 1, md: 4 }, mb: { xs: 2, md: 3 } }}>
        <Typography
          color="text.primary"
          sx={{
            fontSize: 22,
            fontWeight: "600",
            fontFamily: "Ubuntu, sans-serif",
            opacity: 0.75,
          }}
        >
          Groups
        </Typography>
      </Box>
      <Grid2 container>
        <GroupItem
          name="Barchasi"
          students={allStudents.sort((a, b)=>a.name > b.name)}
          allLength={allStudents.length}
        />
        {!!props.groups &&
          props.groups.map((el, index) => {
            return (
              <GroupItem
                key={index}
                name={el.name}
                allLength={allStudents.length}
                number={el.id.length}
                groupStudent={el.id}
              />
            );
          })}
      </Grid2>
    </Box>
  );
}
