import { Avatar, Box, Divider, Paper, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import Context from "../Context";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function Dashboard() {
  const userData = useSelector((state) => state.userData);
  const { role } = useContext(Context);

  const data = [
    {
      name: "Sentyabr",
      hujjatlar: 13,
      talabalar: 66,
    },
    {
      name: "Oktyabr",
      hujjatlar: 34,
      talabalar: 123,
    },
    {
      name: "Noyabr",
      hujjatlar: 43,
      talabalar: 102,
    },
    {
      name: "Dekabr",
      hujjatlar: 54,
      talabalar: 10,
    },
    {
      name: "Yanvar",
      hujjatlar: 10,
      talabalar: 0,
    },
  ];

  const data01 = [
  {
    "name": "Group A",
    "value": 400
  },
  {
    "name": "Group A",
    "value": 400
  }]

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Grid2 container sx={{ width: "100%" }}>
        <Grid2 xs={12} sm={6} md={3} sx={{ p: 1 }}>
          <Paper
            sx={{
              borderRadius: 3,
              px: 2,
              py: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            elevation={2}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: 16,
                  fontFamily: "Ubuntu, sans-serif",
                  color: "text.secondary",
                }}
              >
                Talabalar soni
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "Ubuntu, sans-serif",
                  color: "text.primary",
                  fontFamily: 600
                }}
              >
                6759
              </Typography>
            </Box>
            <Box>
              {/* <PieChart width={100} height={100}>
  <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
  <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label />
</PieChart> */}
            </Box>
          </Paper>
        </Grid2>
        <Grid2 xs={12} sm={6} md={3} sx={{ p: 1 }}>
          <Paper
            sx={{
              borderRadius: 3,
              px: 2,
              py: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            elevation={2}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: 16,
                  fontFamily: "Ubuntu, sans-serif",
                  color: "text.secondary",
                }}
              >
                Xodimlar soni
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "Ubuntu, sans-serif",
                  color: "text.primary",
                  fontFamily: 600
                }}
              >
                213
              </Typography>
            </Box>
            <Box>
              {/* <PieChart width={100} height={100}>
  <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
  <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label />
</PieChart> */}
            </Box>
          </Paper>
        </Grid2>
        <Grid2 xs={12} sm={6} md={3} sx={{ p: 1 }}>
          <Paper
            sx={{
              borderRadius: 3,
              px: 2,
              py: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            elevation={2}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: 16,
                  fontFamily: "Ubuntu, sans-serif",
                  color: "text.secondary",
                }}
              >
                Yuklangan fayllar soni
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "Ubuntu, sans-serif",
                  color: "text.primary",
                  fontFamily: 600
                }}
              >
                103
              </Typography>
            </Box>
            <Box>
              {/* <PieChart width={100} height={100}>
  <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
  <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label />
</PieChart> */}
            </Box>
          </Paper>
        </Grid2>
        <Grid2 xs={12} sm={6} md={3} sx={{ p: 1 }}>
          <Paper
            sx={{
              borderRadius: 3,
              px: 2,
              py: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            elevation={2}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: 16,
                  fontFamily: "Ubuntu, sans-serif",
                  color: "text.secondary",
                }}
              >
                Manzili joylashtirilgan talabalar
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "Ubuntu, sans-serif",
                  color: "text.primary",
                  fontFamily: 600
                }}
              >
                2123
              </Typography>
            </Box>
            <Box>
              {/* <PieChart width={100} height={100}>
  <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
  <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label />
</PieChart> */}
            </Box>
          </Paper>
        </Grid2>
      </Grid2>

      <Grid2 container sx={{ width: "100%", mt: 8 }}>
        <Grid2 xs={12} md={6} sx={{p: 1}}>
          <Paper
            elevation={2}
            sx={{ borderRadius: 4, py: 3, px: 2, border: "none" }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                color="text.secondary"
                sx={{
                  fontSize: 20,
                  fontWeight: "700",
                  fontFamily: "Ubuntu, sans-serif",
                }}
              >
                Fayllar yuklanishi jadvallarda
              </Typography>
            </Stack>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "flex-start",
              }}
            >
              <ResponsiveContainer height={200}>
                <AreaChart
                  data={data}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  
                  <Area
                    type="monotone"
                    dataKey="hujjatlar"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#colorPv)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Stack>
          </Paper>
        </Grid2>
        <Grid2 xs={12} md={6} sx={{p:1}}>
          <Paper
            elevation={2}
            sx={{ borderRadius: 4, py: 3, px: 2, border: "none" }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                color="text.secondary"
                sx={{
                  fontSize: 20,
                  fontWeight: "700",
                  fontFamily: "Ubuntu, sans-serif",
                }}
              >
                Talabalar manzillari jadvallarda
              </Typography>
            </Stack>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "flex-start",
              }}
            >
              <ResponsiveContainer height={200}>
                <AreaChart
                  data={data}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="talabalar"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                  
                </AreaChart>
              </ResponsiveContainer>
            </Stack>
          </Paper>
        </Grid2>
      </Grid2>
    </Box>
  );
}
