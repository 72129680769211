import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import MapContainer from "./MapContainer";

export default function StudentsLocationInfo({ data }) {
  return (
    <Paper
      elevation={2}
      sx={{ mt: 2, borderRadius: 4, py: 3, px: 2, border: "none" }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          color="text.secondary"
          sx={{
            fontSize: 20,
            fontWeight: "700",
            fontFamily: "Ubuntu, sans-serif",
          }}
        >
          Talaba lokatsiyasi
        </Typography>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "flex-start",
          '& div': {
            width: '100% !important',
            '& ymaps':{
              width: '100% important'
            }
          }
        }}
      >
        <MapContainer location={data.location_url} /> 
      </Stack>
    </Paper>
  );
}
