import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import StudentsProfile from '../components/StudentsProfile';
import { useDispatch, useSelector } from 'react-redux';
import { getStudent, getTutorsStudents } from '../api';
import { ALL_STUDENTS, MANAVIYAT_GROUPS } from '../redux/action';
import fetching from '../lottie/fetching-loading.json';

export default function ManaviyatStudent(props) {
	const params = useParams();
	const dispatch = useDispatch();
	const allStudents = useSelector((state) => state.allStudents);
	const [loading, setLoading] = useState({ status: true, data: false });
	const [student, setStudent] = useState(null);

	const id = params.id.replace(/\D/gi, '');

	useEffect(() => {
		if (
			allStudents.filter((el) => +el.student_id_number == +id).length == 1
		) {
			setLoading({ status: false, data: true });
		} else {
			const request = async () => {
				const data = await getStudent(id);
				if (data.success) {
					setLoading({ status: false, data: true });
					dispatch({
						type: ALL_STUDENTS,
						value: data.data.items,
					});
				} else {
					setLoading({ status: false, data: false });
				}
			};
			request();
		}
	}, [id]);

	if (loading.status == true) {
		return <PageNotFound data={fetching} />;
	} else if (loading.status == false && loading.data == false) {
		return <PageNotFound />;
	} else if (loading.status == false && loading.data == true) {
		return (
			<StudentsProfile
				id={id}
				student={allStudents.find((el) => +el.student_id_number == +id)}
			/>
		);
	}
}
