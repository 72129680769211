import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import MessageReadUser from "./MessageReadUser";
import { getTutors } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { READ_USERS } from "../redux/action";

export default function MessageItem({ message }) {
  const dispatch = useDispatch();
  const [openMessage, setOpenMessage] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const filteredReadUsers = useSelector(state => state.filteredReadUsers)

  const handleOpenMessage = () => setOpenMessage(!openMessage);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteClose = () => {
    console.log("delete");
  };
  let readUsers = Object.values(JSON.parse(message.tutorlar));

  useEffect(() => {
    const res = async () => {
      const data = await getTutors(readUsers.map((el) => el.hemis_id))
      
      data.map((el) =>
        dispatch({
          type: READ_USERS,
          value: {
            id: readUsers.find((e) => e.hemis_id == el.employee_id_number)
              .hemis_id,
            value: {name: `${el.first_name.toLowerCase()} ${el.second_name.toLowerCase()}`, img: el.image},
          },
        })
      );
    };
    res();
  }, [])
  
  console.log(filteredReadUsers);

  return (
    <Box
      sx={{
        borderRadius: 3,
        px: 1,
        py: 2,
        background: (theme) =>
          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
        mb: 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Avatar
            alt={message.fullname}
            src={message.img}
            sx={{ width: 24, height: 24, mr: 1 }}
          />
          <Typography
            color="text.primary"
            sx={{
              fontSize: 16,
              fontWeigth: 500,
              fontFamily: "Ubuntu, sans-serif",
            }}
          >
            {message.fullname}
          </Typography>
        </Box>

        <Typography
          color="text.secondary"
          sx={{
            fontSize: 16,
            fontWeigth: 500,
            fontFamily: "Ubuntu, sans-serif",
          }}
        >
          {message.created_at.split("T")[0].replaceAll("-", "/")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          position: "relative",
          transition: "all 0.3s linear !important",
        }}
      >
        <Typography
          color="text.secondary"
          sx={{
            fontSize: 14,
            fontFamily: "Ubuntu, sans-serif",
            fontWeight: 400,
            textAlign: "justify",
            mt: 1,
            textIndent: 20,
          }}
        >
          {message.xabar}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Box
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, position: "relative", display: "inline-block" }}
          >
            {readUsers.length > 0 && (
              <AvatarGroup
                sx={{ "& .MuiAvatar-root": { width: 24, height: 24 } }}
              >
                {!!filteredReadUsers.length && filteredReadUsers.map((el, index) => {
                  console.log(el)
                  return(
                    (
                      <Avatar
                        key={index}
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                      />
                    )
                  )
                })}
              </AvatarGroup>
            )}
          </Box>
          <IconButton
            variant="contained"
            onClick={handleDialogOpen}
            size="small"
            color="danger"
            sx={{
              ml: 1,
              width: '28px !important', 
              height: '28px !important',
              background: "#ff0e0e3f",
              transition: "all 0.3s linear",
            }}
          >
            <DeleteIcon sx={{ color: "#ff0e0e" }} />
          </IconButton>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            overflowY: "auto",
            maxHeight: 200,
            borderRadius: 3,
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& li": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            },
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {readUsers.length > 0 ? readUsers.map((el, index) => (
          <MessageReadUser
            name={el.hemis_id}
            read={el.is_readed}
            key={index}
            onClick={handleClose}
          />
        )) : <CircularProgress />}
      </Menu>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Xabarni o'chirishni tasdiqlaysizmi?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Siz tomoningizda yuborilgan xabarni o'chirish uchun <b>O'CHIRISH</b>{" "}
            tugmasini bosing!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontFamily: "Ubuntu, sans-serif" }}
            onClick={handleDialogClose}
          >
            Bekor qilish
          </Button>
          <Button
            sx={{
              fontFamily: "Ubuntu, sans-serif",
              color: "#ff0e0e !important",
            }}
            onClick={handleDeleteClose}
            autoFocus
          >
            O'chirish
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
