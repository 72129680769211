import { Avatar, Box, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from '@mui/icons-material/Done';
import { getTutors } from "../api";
import { useSelector } from "react-redux";
export default function MessageReadUser({ name, read }) {
  const filteredReadUsers = useSelector(state => state.filteredReadUsers)
  console.log(read)

  return (
    <MenuItem>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mr: 1,
          fontSize: 14,
          fontFamily: "Ubuntu, sans-serif",
          fontWeight: 400,
          textTransform:  'capitalize'
        }}
      >
        <Avatar src={filteredReadUsers[name].img} alt={filteredReadUsers[name].name} /> {filteredReadUsers[name].name}
      </Box>{
        read ? <DoneAllIcon sx={{ width: 22, height: 22, fontSize: 14 }} /> : <DoneIcon />
      }
      
    </MenuItem>
  );
}
