import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Pagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getTutorsFiles } from '../api';
import PageNotFound from '../pages/PageNotFound';
import TutorProfileDocumentsData from '../pages/TutorProfileDocumentsData.js';
import { TUTOR_FILES } from '../redux/action';
import fetching from '../lottie/fetching-loading.json';
export default function TutorFiles(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const tutorFiles = useSelector((state) => state.tutorFiles);
	const [page, setPage] = useState(!!params.filePage ? params.filePage : '1');
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState('load');

	useEffect(() => {
		const res = async () => {
			const data = await getTutorsFiles('1', props.id);
			setCount(data.count);
			if (!!data.results && !!data.results.length) {
				setLoading('isset');
			} else if (!!data.results && !data.results.length) {
				setLoading('nul');
			} else {
				setLoading('load');
			}
		};
		res();
	}, [params.filePage, props.id]);

	useEffect(() => {
		const res = async () => {
			if (loading == 'isset') {
				const data = await getTutorsFiles(page, props.id);
				dispatch({
					type: TUTOR_FILES,
					value: {
						tutor: params.tutor,
						page: page,
						data: data.results,
					},
				});
			}
		};
		res();
	}, [props.id, page, loading]);

	useEffect(() => {
		setPage(!!params.filePage ? params.filePage : '1');
	}, [params.filePage]);

	const handlePage = (e, value) => {
		setPage(value);
		navigate(`/tutors/${params.page}/${params.tutor}/${value}`);
	};

	if (loading == 'isset') {
		if (
			(+params.filePage > 0 &&
				+params.filePage <= Math.ceil(count / 24)) ||
			Object.keys(params).length == 2
		) {
			if (
				!!Object.keys(tutorFiles).length &&
				!!tutorFiles[params.tutor] &&
				!!tutorFiles[params.tutor][page]
			) {
				return (
					<>
						<TutorProfileDocumentsData
							files={tutorFiles[params.tutor][page]}
						/>
						{count / 24 > 1 && (
							<Box
								sx={{
									mt: 5,
									width: '100%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<Pagination
									count={Math.ceil(count / 24)}
									size='large'
									color='primary'
									page={+page}
									siblingCount={1}
									onChange={handlePage}
								/>
							</Box>
						)}
					</>
				);
			} else {
				return <PageNotFound data={fetching} />;
			}
		} else {
			return <PageNotFound />;
		}
	} else if (loading == 'nul') {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}>
				<WarningAmberIcon color='warning' sx={{ mr: 1 }} />
				<Typography
					sx={{ fontSize: 18, fontWeight: '500' }}
					color='warning.main'>
					Fayllar mavjud emas!
				</Typography>
			</Box>
		);
	} else {
		return <PageNotFound data={fetching} />;
	}
}
