import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { getMessages } from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { TUTOR_MESSAGES } from '../redux/action';
import GetAllMessage from '../components/GetAllMessage';
import Lottie from 'lottie-react';
import PageLoading from './PageLoading';
import lottieUploading from '../lottie/uploading.json';

export default function Messages() {
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.userData);
	const tutorMessages = useSelector((state) => state.tutorMessages);
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		if (tutorMessages.length > 0) {
			setUploading(false);
		} else {
			setUploading(true);
		}
		const request = async () => {
			const data = await getMessages();
			if (data.status == 200) {
				dispatch({ type: TUTOR_MESSAGES, value: data.data });
			}
		};
		request();
		setUploading(false);
	}, []);

	return (
		<Box>
			<Typography
				color='text.primary'
				sx={{
					fontFamily: 'Ubuntu, sans-serif',
					fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
					fontWeight: '700',
				}}>
				Xabarlar
			</Typography>
			<Grid2 container>
				<Grid2 xs={12} md={8} lg={8}>
					<Typography
						sx={{ fontSize: 18, fontWeight: '500' }}
						color='text.secondary'>
						Sizning nomingizga kelib tushgan xabarlarni. Diqqat
						ushbu xabarlarda sizda topshiriqlar, raxbatlar yoki
						tabriklar o'z ifodasini topgan bo'lishi mumkin.
					</Typography>
				</Grid2>
			</Grid2>
			{uploading && tutorMessages.length > 0 ? (
				<Box
					sx={{
						width: '100%',
						height: '150px',
						p: 1,
						width: 'calc(100% - 16px)',
						outline: '1px solid transparent',
						borderRadius: 3,
						resize: 'vertical',
						fontSize: 16,
						fontFamily: 'Ubuntu, sans-serif',
						transition: '0.3s all',
						color: 'text.primary',
						'&:hover': {
							border: '1px solid transparent',
						},
					}}>
					<PageLoading />
				</Box>
			) : (
				<Box sx={{ mt: { xs: 1, md: 4 }, mb: { xs: 2, md: 3 } }}>
					<Box sx={{ width: '100%', maxWidth: 800 }}>
						<GetAllMessage messages={tutorMessages} />
					</Box>
				</Box>
			)}
		</Box>
	);
}
