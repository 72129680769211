import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

export default function SelectGroupItem({
  id,
  name,
  language,
  selectGroup,
  selectGroupId,
}) {
  let active = Boolean(selectGroupId == id);
  return (
    <Box
      onClick={() =>
        selectGroup(id)
      }
      sx={{
        borderRadius: 3,
        background: (theme) =>
          theme.palette.mode === "light"
            ? active
              ? "#096bde3f"
              : "#fff"
            : active
            ? "#09090d"
            : "#09090d3f",
        border: (theme) =>
          theme.palette.mode === "light"
            ? "1px solid #0000002f"
            : "1px solid #ffffff2f",
        my: 0.5,
        display: "flex",
        flexDirection: "row",
        px: 1,
        py: 1,
        cursor: "pointer",
      }}
    >
      <Typography
        color="text.primary"
        sx={{
          fontSize: 16,
          fontWeight: 400,
          fontFamily: "Ubuntu, sans-serif",
          textTransform: "capitalize !important",
          width: "100%",
        }}
      >
        {name}
      </Typography>
      <Typography
        color="text.primary"
        sx={{
          fontSize: 16,
          fontWeight: 400,
          fontFamily: "Ubuntu, sans-serif",
          textTransform: "capitalize !important",
        }}
      >
        {language}
      </Typography>
    </Box>
  );
}
