import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
export default function TutorPersonalInfo(props) {
  const month = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "Iyul",
    "Avgust",
    "Sentyabr",
    "Oktyabr",
    "Noyabr",
    "Dekabr",
  ];

  console.log();
  return (
    <Paper elevation={2} sx={{ borderRadius: 4, py: 3, px: 2, border: "none" }}>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          color="text.secondary"
          sx={{
            fontSize: 20,
            fontWeight: "700",
            fontFamily: "Ubuntu, sans-serif",
          }}
        >
          Tyutor ma'lumotlari
        </Typography>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "flex-start",
          "& .MuiTypography-root": {
            fontSize: 16,
            fontFamily: "Ubuntu, sans-serif",
          },
          "& .MuiBox-root": {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            mr: 1,
            my: 0.5,
            "& .MuiTypography-root": {
              color: "text.secondary",
              fontWeight: "400",
              minWidth: 150,
            },
          },
          "& .MuiStack-root": {
            width: "100%",
            pl: 1,
            overflow: 'hidden',
            "& .MuiTypography-root": {
              textTransform: "capitalize",
              color: "text.primary",
              fontWeight: "500",
            },
          },
        }}
      >
        <Box>
          <Typography>Ismi:</Typography>
          <Stack>
            <Typography>{props.info.first_name.toLowerCase()} </Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Familiyasi:</Typography>
          <Stack>
            <Typography>{props.info.second_name.toLowerCase()}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Otasining ismi:</Typography>
          <Stack>
            {props.info.third_name.split(" ").length === 2 ? (
              <Typography>
                <span style={{ textTransform: "capitalize" }}>
                  {props.info.third_name.split(" ")[0].toLowerCase()} &nbsp;
                </span>
                <span style={{ textTransform: "lowercase" }}>
                  {props.info.third_name.split(" ")[1].toLowerCase()}
                </span>
              </Typography>
            ) : (
              <Typography>{props.info.third_name.toLowerCase()}</Typography>
            )}
          </Stack>
        </Box>
        <Box>
          <Typography>Jinsi:</Typography>
          <Stack>
            <Typography>{props.info.gender.name}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Tug'ilgan sana:</Typography>
          <Stack>
            <Typography>{`${new Date(props.info.birth_date * 1000).getDate()} ${
              month[new Date(props.info.birth_date * 1000).getMonth()]
            } ${new Date(
              props.info.birth_date * 1000
            ).getFullYear()}`}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Statusi:</Typography>
          <Stack>
            {props.info.employeeStatus.name === "Ishlamoqda" ? (
              <Typography
                sx={{
                  background: "#a1e23d",
                  color: "#fff !important",
                  borderRadius: 100,
                  display: "inline",
                  whiteSpace: "wrap",
                  px: 1,
                }}
              >
                {props.info.employeeStatus.name}
              </Typography>
            ) : (
              <Typography>{props.info.employeeStatus.name}</Typography>
            )}
          </Stack>
        </Box>
        <Box>
          <Typography>Akademik darajasi:</Typography>
          <Stack>
            <Typography>{props.info.academicDegree.name}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Akademik unvoni:</Typography>
          <Stack>
            <Typography>{props.info.academicRank.name}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Ish bo'limi:</Typography>
          <Stack>
            <Typography>{props.info.department.name}</Typography>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
}
