import React, { useState } from "react";
import Header from "./Header";
import { Avatar, AvatarGroup, Box, Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import FolderIcon from '@mui/icons-material/Folder';
import { useNavigate } from "react-router-dom";
const color = [
  "#bd5844",
  "#ff5722",
  "#673ab7",
  "#fde79d",
  "#1f6cfa",
  "#085c25",
  "#4caf50",
  "#e91e63",
];


export default function DocumentsKatalog(props) {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => setWidth(window.innerWidth));
  return (
    <Grid2
      xs={6}
      sm={6}
      md={4}
      lg={3}
      sx={{ p: { xs: 0.5, ms: 2 }, position: "relative" }}
    >
      <Paper
        onClick={() => navigate(`/documents/${props.name}`)}
        sx={{ borderRadius: 4, px: 2, py: 3 }}
      >
        <Box>
          <Grid2 container sx={{ width: "100%" }}>
            <Grid2 xs={12} md={12}>
              <FolderIcon
                sx={{
                  width: { xs: width < 500 ? 40 : 50, sm: 50 },
                  fontSize: { xs: width < 500 ? 40 : 50, sm: 50 },
                  background: 'linear-gradient(90deg, rgba(65,200,255,1) 0%, rgba(229,31,255,1) 100%)',
                  backgroundClip: 'text',
                }}
              />
            </Grid2>
            <Grid2
              xs={12}
              md={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: 'end',
                pl: { lg: 1 },
              }}
            >
              <Typography
                color="text.primary"
                sx={{
                  fontSize: { xs: width < 500 ? 16 : 18, sm: 18 },
                  fontWeight: 600,
                  fontFamily: "Ubuntu, sans-serif",
                }}
              >
                {props.name}
              </Typography>
            </Grid2>
            <Grid2 xs={4} md={3} lg={width < 1300 ? 1 : 3}></Grid2>
            <Grid2
              xs={12}
              sm={12}
              md={12}
              lg={width < 1300 ? 11 : 9}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: 'end',
                pl: { lg: 1 },
              }}
            >
              <Typography
                color="text.secondary"
                whiteSpace={"wrap"}
                sx={{
                  fontFamily: 'Ubuntu, sans-serif',
                  fontSize: { xs: width < 500 ? 15 : 18, sm: 18 },
                  fontWeight: 400,
                  mt: { sm: 0 },
                }}
              >
                {props.length} files / {Math.round(props.size / 1024)} mb
              </Typography>
            </Grid2>
          </Grid2>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: { xs: "end" },
              mt: 2,
            }}
          >
          </Box>
        </Box>
      </Paper>
    </Grid2>
  );
}
