import {
  Box,
  Button,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useContext, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getTutors } from "../api";
import CloseIcon from "@mui/icons-material/Close";
import Context from "../Context";
import { useSelector } from "react-redux";

export default function FileItem(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showData, setShowData] = useState(false);
  const userData = useSelector((state) => state.userData);
  const [tyutor, setTyutor] = useState("");
  const { role } = useContext(Context);
  useEffect(() => {
    if (role == "Tutor") {
      setTyutor(
        !!userData.full_name
          ? userData.full_name.split(" ")[0].toLowerCase() +
              " " +
              userData.full_name.split(" ")[1].toLowerCase()
          : ""
      );
    }
  }, [role]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  useEffect(() => {
    if (role == "Manaviyat") {
      const request = async () => {
        const data = await getTutors([props.tutor]);
        setTyutor(
          data[0].full_name.split(" ")[0].toLowerCase() +
            " " +
            data[0].full_name.split(" ")[1].toLowerCase()
        );
      };
      request();
    }
  }, [props.tutor]);
  return (
    <Grid2
      xs={12}
      sm={6}
      md={4}
      lg={width > 1400 ? 3 : 4}
      sx={{ p: { xs: 0.5, ms: 2 } }}
    >
      <Paper
        sx={{
          borderRadius: 4,
          px: 2,
          py: 3,
          position: "relative",
          overflow: "hidden",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "flex",
          flexDirection: { xs: "row", sm: "column" },
          alignItems: { xs: "center", sm: "start" },
        }}
      >
        <Box
          onClick={() => setShowData(true)}
          sx={{
            mr: 1.5,
            display: "flex",
            width: { xs: "auto", sm: "100%" },
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            "& img": {
              height: {
                xs: "60px",
                sm: "70px",
                md: "80px",
                lg: "70px",
              },
            },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 13, sm: 14 },
              fontWeight: "500",
              fontFamily: "Ubuntu, sans-serif",
              background: "#1976d2",
              color: "#fff",
              backgroundSize: "2px 100%",
              borderRadius: 4,
              px: 0.8,
              position: "absolute",
              zIndex: 1,
              top: { xs: 10 },
              left: { xs: "auto", sm: 10 },
              right: { xs: 10, sm: "auto" },
            }}
          >
            {props.folder}
          </Typography>
          <img
            src={`/img/${
              props.file.endsWith("pdf")
                ? "pdf"
                : props.file.endsWith("docx") ||
                  props.file.endsWith("doc") ||
                  props.file.endsWith("docm") ||
                  props.file.endsWith("dot")
                ? "word"
                : props.file.endsWith("xlsx") ||
                  props.file.endsWith("xls") ||
                  props.file.endsWith("xlsm") ||
                  props.file.endsWith("xlsb")
                ? "excel"
                : ""
            }.png`}
            alt=""
          />
        </Box>
        <Box
          sx={{
            mt: { xs: 0, sm: 2 },
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            position: { xs: "absolute", sm: "static" },
            left: 0,
          }}
        >
          <Box
            onClick={() => setShowData(true)}
            sx={{
              minWidth: "calc(100% - 40px)",
              maxWidth: "calc(100% - 120px)",
            }}
          >
            <Typography
              color="text.primary"
              sx={{
                fontFamily: "Ubuntu, sans-serif",
                fontSize: 16,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                ml: { xs: 11, sm: 0 },
              }}
            >
              {props.name}
            </Typography>

            <Box
              sx={{
                mt: 0.5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                ml: { xs: 11, sm: 0 },
              }}
            >
              {tyutor == "" ? (
                <CircularProgress sx={{maxWidth: 20, maxHeight: 20}} />
              ) : (
                <Typography
                  color="text.secondary"
                  sx={{
                    fontFamily: "Ubuntu, sans-serif",
                    textTransform: "capitalize",
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {tyutor}
                </Typography>
              )}

              <Typography
                color="text.secondary"
                sx={{ fontFamily: "Ubuntu, sans-serif", fontSize: 14 }}
              >
                {props.date.slice(0, 10).replaceAll("-", "/")}
              </Typography>
            </Box>
          </Box>
          <Box>
            <IconButton
              sx={{ width: 40, height: 40 }}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              elevation={2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{ "& .MuiPaper-root": { borderRadius: 3 } }}
            >
              <MenuItem
                component="a"
                href={props.file}
                download={true}
                target="_blank"
                onClick={handleClose}
                sx={{ minWidth: 120 }}
              >
                Yuklash
              </MenuItem>
              <MenuItem onClick={handleClose} sx={{ minWidth: 120 }}>
                O'chirish
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Paper>
      {showData ? (
        <Box
          sx={{
            position: "fixed",
            zIndex: 99999912,
            top: 0,
            left: 0,
            width: "calc(100% - 1px)",
            height: "calc(100vh - 1px)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            onClick={() => setShowData(false)}
            sx={{
              background: "#0000003f",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
            }}
          ></Box>
          <Paper
            elevation={2}
            sx={{
              position: "relative",
              width: "90%",
              maxWidth: "550px",
              height: "auto",
              zIndex: 1,
              borderRadius: 4,
              px: 3,
              py: 4,
              background: (theme) =>
                theme.palette.mode === "light" ? "#fff" : "#121212",
            }}
          >
            <IconButton
              onClick={() => setShowData(false)}
              sx={{ position: "absolute", top: -5, right: -5 }}
            >
              <CloseIcon />
            </IconButton>
            <Grid2 container>
              <Grid2 xs={12} sm={3}>
                <Box
                  onClick={() => setShowData(true)}
                  sx={{
                    mr: 1.5,
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    border: (theme) =>
                      theme.palette.mode === "light"
                        ? "1px solid rgba(0, 0, 0, 0.38)"
                        : "1px solid rgba(255, 255, 255, 0.5)",
                    background: (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                    borderRadius: 3,
                    py: 3,
                    "& img": {
                      height: {
                        xs: "60px",
                        sm: "70px",
                        md: "80px",
                        lg: "70px",
                      },
                    },
                  }}
                >
                  <img
                    src={`/img/${
                      props.file.endsWith("pdf")
                        ? "pdf"
                        : props.file.endsWith("docx") ||
                          props.file.endsWith("doc") ||
                          props.file.endsWith("docm") ||
                          props.file.endsWith("dot")
                        ? "word"
                        : props.file.endsWith("xlsx") ||
                          props.file.endsWith("xls") ||
                          props.file.endsWith("xlsm") ||
                          props.file.endsWith("xlsb")
                        ? "excel"
                        : ""
                    }.png`}
                    alt=""
                  />
                </Box>
              </Grid2>
              <Grid2 xs={12} sm={9}>
                <Box
                  sx={{
                    pl: { sm: 1 },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    disabled={true}
                    placeholder={props.name}
                    variant="outlined"
                    value={props.name}
                    type="text"
                    sx={{
                      mt: { xs: 1, sm: 0 },
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: 3,
                        background: (theme) =>
                          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: (theme) =>
                          theme.palette.mode === "light" ? "#000000de" : "#fff",
                      },
                    }}
                  />
                  <TextField
                    disabled={true}
                    placeholder={props.folder}
                    variant="outlined"
                    value={props.folder}
                    type="text"
                    sx={{
                      mt: { xs: 1, sm: 0 },
                      width: "100%",
                      "& .MuiInputBase-root": {
                        color: "text.primary",
                        borderRadius: 3,
                        background: (theme) =>
                          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: (theme) =>
                          theme.palette.mode === "light" ? "#000000de" : "#fff",
                      },
                    }}
                  />
                </Box>
              </Grid2>
              <Grid2
                xs={4}
                sm={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    fontFamily: "Ubuntu, sans-serif",
                    fontSize: 16,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    ml: 0,
                  }}
                >
                  Tyutorning ismi:
                </Typography>
              </Grid2>
              <Grid2 xs={8} sm={9}>
                <Box
                  sx={{
                    pl: { sm: 1 },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {tyutor == "" ? (
                    <CircularProgress sx={{maxWidth: 20, maxHeight: 20}} />
                  ) : (
                    <TextField
                      disabled={true}
                      placeholder={tyutor}
                      variant="outlined"
                      value={tyutor}
                      type="text"
                      sx={{
                        my: 1,
                        width: "100%",
                        "& .MuiInputBase-root": {
                          borderRadius: 3,
                          background: (theme) =>
                            theme.palette.mode === "light"
                              ? "#fff"
                              : "#09090d3f",
                        },
                        "& .Mui-disabled": {
                          textTransform: "capitalize",
                          WebkitTextFillColor: (theme) =>
                            theme.palette.mode === "light"
                              ? "#000000de"
                              : "#fff",
                        },
                      }}
                    />
                  )}
                </Box>
              </Grid2>
              <Grid2
                xs={4}
                sm={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    fontFamily: "Ubuntu, sans-serif",
                    fontSize: 16,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    ml: 0,
                  }}
                >
                  Yuklangan vaqti
                </Typography>
              </Grid2>
              <Grid2 xs={8} sm={9}>
                <Box
                  sx={{
                    pl: { sm: 1 },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    disabled={true}
                    placeholder={props.date}
                    variant="outlined"
                    value={`${props.date
                      .split("T")[0]
                      .replaceAll("-", "/")} ${props.date
                      .split("T")[1]
                      .slice(0, 8)} `}
                    type="text"
                    sx={{
                      mb: 1,
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: 3,
                        background: (theme) =>
                          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                      },
                      "& .Mui-disabled": {
                        textTransform: "capitalize",
                        WebkitTextFillColor: (theme) =>
                          theme.palette.mode === "light" ? "#000000de" : "#fff",
                      },
                    }}
                  />
                </Box>
              </Grid2>
              <Grid2 xs={12}>
                <Box
                  sx={{
                    p: { xs: 0.5 },
                    position: "relative",
                    "& textarea": {
                      p: 1,
                      width: "calc(100% - 16px)",
                      border: "1px solid #0000003f",
                      outline: "1px solid transparent",
                      borderRadius: 3,
                      resize: "vertical",
                      fontSize: 16,
                      fontFamily: "Ubuntu, sans-serif",
                      transition: "0.3s all",
                      background: (theme) =>
                        theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                      color: "text.primary",
                      "&:focus": {
                        outline: "2px solid #096bde",
                        border: "none",
                      },
                      "&:focus-visible": {
                        outline: "2px solid #096bde",
                        border: "none",
                      },

                      "&::-webkit-resizer": {
                        display: "none",
                      },
                    },
                  }}
                >
                  <textarea
                    disabled
                    placeholder="Izoh qoldirish..."
                    value={props.comment}
                    rows={4}
                  />
                </Box>
              </Grid2>
              <Grid2 xs={12}>
                <Button
                  component="a"
                  target="_blank"
                  href={props.file}
                  disableElevation
                  color="primary"
                  variant="contained"
                  sx={{ borderRadius: 10, float: "right", my: 2 }}
                >
                  Yuklab olish
                </Button>
              </Grid2>
            </Grid2>
          </Paper>
        </Box>
      ) : (
        ""
      )}
    </Grid2>
  );
}
