import { Box, Pagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageNotFound from './PageNotFound';
import TutorsView from '../components/TutorsView';
import { getTutors, getTutorsId } from '../api';
import { ALL_HEMIS_ID, ALL_TUTORS, HEMIS_ID, TUTORS } from '../redux/action';
import fetching from '../lottie/fetching-loading.json';

export default function Tutors() {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const hemisId = useSelector((state) => state.hemisId);
	const tutors = useSelector((state) => state.tutors);
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(!!params.page ? +params.page : 1);
	useEffect(() => {
		const request = async () => {
			const data = await getTutorsId(page);
			setCount(data.count);
			dispatch({
				type: HEMIS_ID,
				value: { page: `page_${page}`, data: data.results },
			});
			dispatch({
				type: ALL_HEMIS_ID,
				value: data.results,
			});
		};
		request();
	}, [params.page]);

	useEffect(() => {
		const ids = !!hemisId[`page_${page}`]
			? hemisId[`page_${page}`].map((el) => el.hemis_id)
			: [];
		if (!!hemisId.page_1) {
			const request = async () => {
				const data = await getTutors(ids);
				dispatch({
					type: TUTORS,
					value: { page: `page_${page}`, data: data },
				});
				dispatch({
					type: ALL_TUTORS,
					value: data,
				});
			};
			request();
		}
	}, [hemisId, params.page]);

	const handlePage = (e, value) => {
		navigate(`/tutors/${value}`);
		setPage(value);
	};
	return (
		<Box>
			{Object.keys(params)[0] == '*' && params['*'] == '' ? (
				!!tutors.page_1 && tutors.page_1.length > 0 ? (
					<TutorsView
						tutors={tutors.page_1}
						count={Math.ceil(count / 24)}
					/>
				) : (
					<Box
						sx={{
							minHeight: '100vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<PageNotFound data={fetching} />
					</Box>
				)
			) : !!params.page && +params.page <= Math.ceil(count / 24) ? (
				!!tutors[`page_${page}`] &&
				tutors[`page_${page}`].length > 0 ? (
					<TutorsView
						tutors={tutors[`page_${page}`]}
						count={Math.ceil(count / 24)}
					/>
				) : (
					<Box
						sx={{
							minHeight: '100vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<PageNotFound data={fetching} />{' '}
					</Box>
				)
			) : (
				<Box
					sx={{
						minHeight: '100vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<PageNotFound data={fetching} />{' '}
				</Box>
			)}

			{count > 24 && (
				<Box
					sx={{
						pt: 5,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Pagination
						color='primary'
						count={Math.ceil(count / 24)}
						page={page}
						size='large'
						onChange={handlePage}
					/>
				</Box>
			)}
		</Box>
	);
}
