import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TutorMessageItem from "./TutorMessageItem";
import Context from "../Context";
import MessageItem from "./MessageItem";

export default function GetAllMessage({messages}) {
  const {role} = useContext(Context)
  console.log('filteredReadUsers');
  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        color="text.primary"
        sx={{
          fontSize: 20,
          fontWeight: 500,
          fontFamily: "Ubuntu, sans-serif",
        }}
      >
        Xabarlar
      </Typography>
      <Box
        sx={{
          p: { xs: 0.5 },
          position: "relative",
          maxHeight: 500, overflowY: 'auto'
        }}
      >
        {
          role == 'Manaviyat' ? messages.map((el, index)=><MessageItem key={index} message={el} /> ) :
          messages.map((el, index) => <TutorMessageItem item={el.xabar_id} key={index} /> )
        }
        
      </Box>
    </Box>
  );
}
