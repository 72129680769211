import {
  ALL_HEMIS_ID,
  ALL_MESSAGES,
  ALL_STUDENTS,
  ALL_TUTORS,
  CATALOGS,
  FILES,
  FILTERED_FILES,
  GROUPS,
  GROUP_STUDENTS,
  HEMIS_ID,
  MANAVIYAT_GROUPS,
  READ_USERS,
  STUDENT_PHONE,
  SUCCESS_MISSIA,
  SUCCESS_STUDENT_WITH_ID,
  TOTAL_TUTORS,
  TOTAL_USER_DATA,
  TUTORS,
  TUTOR_FILES,
  TUTOR_MESSAGES,
  UPDATE_WAITING_MISSIA,
  USER_DATA,
  WAITING_MISSIA,
  WAITING_MISSIA_IN_STUDENT_PROFILE,
  WAITING_STUDENT_WITH_ID,
} from "./action";

const initialState = {
  userData: {},
  groups: [],
  allStudents: [],
  catalogs: [],
  files: {},
  filteredFiles: {},
  hemisId: {},
  allHemisId: [],
  tutors: {},
  allTutors: [],
  tutorFiles: {},
  manaviyatGroups: {},
  totalUserData: [],
  totalTutors: [],
  tutorMessages: [],
  allMessages: [],
  filteredReadUsers: {},
  groupStudents: {},
  waitingMissia: {},
  successMissia: {},
  waitingStudentWithId: {},
  successStudentWithId: {},
  waitingMissiaInStudentProfile: {},
  studentPhone: {},
};

const reducer = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case USER_DATA: {
      return {
        ...state,
        userData: { ...action.value },
      };
    }
    case GROUPS: {
      return {
        ...state,
        groups: action.value,
      };
    }
    case ALL_STUDENTS: {
      return {
        ...state,
        allStudents: action.value,
      };
    }
    case CATALOGS: {
      return {
        ...state,
        catalogs: action.value,
      };
    }
    case FILES: {
      const data = { ...state.files };
      data[action.value.params] = action.value.data;
      return {
        ...state,
        files: data,
      };
    }
    case FILTERED_FILES: {
      const data = { ...state.filteredFiles };
      if (!data[action.value.params.katalogName]) {
        data[action.value.params.katalogName] = {};
      }
      data[action.value.params.katalogName] = {
        ...data[action.value.params.katalogName],
        [action.value.params.page]: action.value.data,
      };
      return {
        ...state,
        filteredFiles: data,
      };
    }
    case HEMIS_ID: {
      const data = { ...state.hemisId };
      data[action.value.page] = action.value.data;
      return {
        ...state,
        hemisId: data,
      };
    }
    case ALL_HEMIS_ID: {
      const updatedHemisID = action.value.filter(
        (newHemisId) =>
          !state.allHemisId.some(
            (existingHemisId) =>
              existingHemisId.hemis_id === newHemisId.hemis_id
          )
      );
      return {
        ...state,
        allHemisId: [...state.allHemisId, ...updatedHemisID],
      };
    }
    case TUTORS: {
      const data = { ...state.tutors };
      data[action.value.page] = action.value.data;
      return {
        ...state,
        tutors: data,
      };
    }
    case ALL_TUTORS: {
      const updatedTutors = action.value.filter(
        (newTutor) =>
          !state.allTutors.some(
            (existingTutor) =>
              existingTutor.employee_id_number === newTutor.employee_id_number
          )
      );
      return {
        ...state,
        allTutors: [...state.allTutors, ...updatedTutors],
      };
    }
    case TUTOR_FILES: {
      const data = { ...state.tutorFiles };
      data[action.value.tutor] = {
        ...data[action.value.tutor],
        [action.value.page]: action.value.data,
      };
      return {
        ...state,
        tutorFiles: data,
      };
    }
    case MANAVIYAT_GROUPS: {
      const data = { ...state.manaviyatGroups };
      data[action.value.group] = action.value.data
      return {
        ...state,
        manaviyatGroups: data,
      };
    }
    case TOTAL_USER_DATA: {
      return{
        ...state,
        totalUserData: action.value
      }
    }
    case TOTAL_TUTORS: {
      return{
        ...state,
        totalTutors: action.value
      }
    }
    case ALL_MESSAGES: {
      return{
        ...state,
        allMessages: action.value
      }
    }
    case TUTOR_MESSAGES: {
      return{
        ...state,
        tutorMessages: action.value
      }
    }
    case READ_USERS : {
      const data = {...state.filteredReadUsers}
      data[action.value.id] = action.value.value
      return{
        ...state,
        filteredReadUsers: data,
      }
    }
    case GROUP_STUDENTS : {
      const data = {...state.groupStudents}
      data[action.value.id] = action.value.data
      return{
        ...state,
        groupStudents: data,
      }
    }
    case WAITING_MISSIA : {
      const data = {...state.waitingMissia}
      data[action.value.page] = action.value.data
      return{
        ...state,
        waitingMissia: data,
      }
    }
    case SUCCESS_MISSIA : {
      const data = {...state.successMissia}
      data[action.value.page] = action.value.data
      return{
        ...state,
        successMissia: data,
      }
    }
    case WAITING_STUDENT_WITH_ID : {
      const data = {...state.waitingStudentWithId}
      data[action.value.id] = action.value.student
      return{
        ...state,
        waitingStudentWithId: data,
      }
    }
    case SUCCESS_STUDENT_WITH_ID : {
      const data = {...state.successStudentWithId}
      data[action.value.id] = action.value.student
      return{
        ...state,
        successStudentWithId: data,
      }
    }
    case WAITING_MISSIA_IN_STUDENT_PROFILE : {
      const data = {...state.waitingMissiaInStudentProfile}
      data[action.value.id] = action.value.data
      return{
        ...state,
        waitingMissiaInStudentProfile: data,
      }
    }
    case UPDATE_WAITING_MISSIA : {
      let filteredObj = Object.fromEntries(
        Object.entries(state.waitingMissiaInStudentProfile).filter(([key, value]) => key !== action.value)
      );
      return{
        ...state,
        waitingMissiaInStudentProfile: filteredObj,
      }
    }
    case STUDENT_PHONE : {
      const data = {...state.studentPhone}
      data[action.value.id] = action.value.data
      return{
        ...state,
        studentPhone: data,
      }
    }
    default:
      return state;
  }
};

export default reducer;
