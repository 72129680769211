import { BrowserRouter } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import { useContext, useEffect, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import store from "../redux/store";
import Context from "../Context";
import { getUserData } from "../api";
import TutorRoutes from "./TutorRoutes";
import PageNotFound from "../pages/PageNotFound";
import { ALL_STUDENTS, GROUPS, USER_DATA } from "../redux/action";
import { Box } from "@mui/material";
import ManaviyatRoutes from "./ManaviyatRoutes";
import fetching from "../lottie/fetching-loading.json";
import serverError from "../lottie/server-error.json";

function RoleRoutes(props) {
  const [error, setError] = useState(false);
  const { role, setRole } = useContext(Context);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.auth) {
      const res = async () => {
        const data = await getUserData(props.setAuth);
        if (data.status == 200) {
          setRole(data.data[0].role);
          if (data.data[0].role == "Tutor") {
            dispatch({
              type: USER_DATA,
              value: JSON.parse(data.data[0].hemis_id),
            });
            dispatch({
              type: GROUPS,
              value: JSON.parse(data.data[0].hemis_id).tutorGroups,
            });
            dispatch({
              type: ALL_STUDENTS,
              value: [
                JSON.parse(data.data[0].hemis_id).tutorGroups.map(
                  (el) => el.id
                ),
              ]
                .flat()
                .flat(),
            });
          }
          if (data.data[0].role == "Manaviyat") {
            window.localStorage.setItem(
              "Token",
              JSON.parse(data.data[0].hemis_id).hemis_token
            );
            dispatch({
              type: USER_DATA,
              value: JSON.parse(data.data[0].hemis_id),
            });
          }
        } else {
          setError(true);
        }
      };
      res();
    }
  }, []);
  return role == "Tutor" ? (
    <TutorRoutes />
  ) : role == "Manaviyat" ? (
    <ManaviyatRoutes />
  ) : (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PageNotFound data={error ? serverError : fetching} />
    </Box>
  );
}

export default RoleRoutes;
