export const USER_DATA = "USER_DATA";
export const GROUPS = "GROUPS";
export const ALL_STUDENTS = "ALL_STUDENTS";
export const CATALOGS = "CATALOGS";
export const FILES = "FILES";
export const FILTERED_FILES = "FILTERED_FILES";
export const HEMIS_ID = "HEMIS_ID";
export const TUTORS = "TUTORS";
export const ALL_TUTORS = "ALL_TUTORS";
export const ALL_HEMIS_ID = "ALL_HEMIS_ID";
export const TUTOR_FILES = "TUTOR_FILES";
export const MANAVIYAT_GROUPS = "MANAVIYAT_GROUPS";
export const TOTAL_USER_DATA = "TOTAL_USER_DATA";
export const TOTAL_TUTORS = "TOTAL_TUTORS";
export const TUTOR_MESSAGES = "TUTOR_MESSAGES";
export const ALL_MESSAGES = "ALL_MESSAGES";
export const READ_USERS = "READ_USERS";
export const GROUP_STUDENTS = "GROUP_STUDENTS"
export const WAITING_MISSIA = "WAITING_MISSIA"
export const SUCCESS_MISSIA = "SUCCESS_MISSIA"
export const WAITING_STUDENT_WITH_ID= "WAITING_STUDENT_WITH_ID"
export const SUCCESS_STUDENT_WITH_ID= "SUCCESS_STUDENT_WITH_ID"
export const WAITING_MISSIA_IN_STUDENT_PROFILE = "WAITING_MISSIA_IN_STUDENT_PROFILE"
export const UPDATE_WAITING_MISSIA = "UPDATE_WAITING_MISSIA"
export const STUDENT_PHONE = "STUDENT_PHONE"
