import axios from "axios";


const getUserData = async (setAuth) => {
  try {
    if (!!window.localStorage.getItem("accessToken")) {
      const res = await axios({
        url: "https://api.tutor.uzfi.uz/tutors/",
        method: "GET",
        timeout: 60000,
        headers: {
          Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
        },
      });
      return res;
    }
  } catch (error) {
    console.log(error.response.status === 401);
    if (error.response.status === 401) {
      window.localStorage.removeItem("accessToken");
      setAuth(false);
    }
  }
};

const getCatalog = async () => {
  try {
    const res = await axios({
      url: "https://api.tutor.uzfi.uz/folders/",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
      method: "GET",
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getDocument = async (page) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/uplodedFiles/?page=${page}`,
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
      method: "GET",
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getFilteredDocument = async (catalog) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/uplodedFiles?search=${catalog}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getFilteredAndPagenationDocument = async (catalog, page) => {
  console.log(catalog, page);
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/uplodedFiles?page=${page}&search=${catalog}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const setDocument = async (dataName, catalog, file, comment) => {
  try {
    const res = await axios({
      url: "https://api.tutor.uzfi.uz/uplodedFiles/",
      timeout: 30000,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
      method: "POST",
      data: {
        name: dataName,
        folder: catalog,
        files: file,
        description: comment,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getAllDocument = async (page) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/allfiles/?page=${page}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getFilteredAllDocument = async (catalog) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/allfiles?folder__name=${catalog}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getFilteredAndPagenationAllDocument = async (catalog, page) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/allfiles?page=${page}&folder__name=${catalog}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getTutorsId = async (page) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/users?page=${page}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getAllTutorsID = async (page) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/allTutors`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getTutors = async (tutorId) => {
  const promises = tutorId.map((url) =>
    axios({
      url: `https://student.uzfi.uz/rest/v1/data/employee-list?type=employee&search=${url}`,
      method: "GET",
      timeout: 300000,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("Token")}`,
      },
    })
  );
  const responses = await Promise.all(promises);
  return responses.map((response) => response.data.data.items[0]);
};

const getStudentsAll = async (ids) => {
  const promises = ids.map((id) =>
    axios({
      url: `https://student.uzfi.uz/rest/v1/data/student-list?search=${id}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("Token")}`,
      },
    })
  );
  const responses = await Promise.all(promises);
  return responses.map((response) => response.data.data.items[0]);
};

const getTutorsStudents = async (id) => {
  try {
    const res = await axios({
      url: `https://student.uzfi.uz/rest/v1/data/student-list?limit=50&_group=${id}`,
      method: "GET",
      timeout: 300000,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("Token")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getTutorsFiles = async (page, id) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/allfiles?page=${page}&user__hemis_id=${id}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const getStudent = async (id) => {
  try {
    const res = await axios({
      url: `https://student.uzfi.uz/rest/v1/data/student-list?search=${id}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("Token")}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const postSMS = async (sms) => {
  try {
    const res = await axios({
      url: "https://api.tutor.uzfi.uz/messages/",
      method: "POST",
      data: sms,
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getMessages = async () => {
  try {
    const res = await axios({
      url: "https://api.tutor.uzfi.uz/receiverfortutor/",
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getAllMessage = async () => {
  try {
    const res = await axios({
      url: "https://api.tutor.uzfi.uz/messages/",
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const postXabar = async (sms) => {
  try {
    const res = await axios({
      url: "https://api.tutor.uzfi.uz/xabar/",
      method: "POST",
      timeout: 30000,
      data: { ...sms },
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getWaitingMissia = async (page) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/studentisnotlocation/?page=${page}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getSuccessMissia = async (page) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/studentislocation/?page=${page}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const deleteWaitingMissia = async (id) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/studentisnotlocation/?id=${id}`,
      method: "DELETE",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const updateWaitingMissia = async (id, sms) => {
  console.log(id);
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/studentisnotlocation/${id}/`,
      method: "PATCH",
      timeout: 30000,
      data: sms,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getWaitingMissiaInStudent = async (id) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/studentisnotlocation/?student_hemis_id=${id}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getWaitingMissiaAllStudent = async (ids) => {
  const promises = ids.map((id) =>
    axios({
      url: `https://api.tutor.uzfi.uz/studentisnotlocation/?student_hemis_id=${id}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    })
  );
  const responses = await Promise.all(promises);
  return responses;
};

const getSuccessMissiaAllStudent = async (ids) => {
  const promises = ids.map((id) =>
    axios({
      url: `https://api.tutor.uzfi.uz/studentislocation/?student_hemis_id=${id}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    })
  );
  const responses = await Promise.all(promises);
  console.log(responses)
  return responses;
};

const getStudentPhone = async (id) => {
  try {
    const res = await axios({
      url: `https://api.tutor.uzfi.uz/studentislocation/?student_hemis_id=${id}`,
      method: "GET",
      timeout: 30000,
      headers: {
        Authorization: `Token ${window.localStorage.getItem("accessToken")}`,
      },
    });
    console.log(res)
    return res;
  } catch (error) {
    console.log(error);
  }
};

export {
  getUserData,
  getCatalog,
  getDocument,
  getFilteredDocument,
  getFilteredAndPagenationDocument,
  setDocument,
  getAllDocument,
  getFilteredAllDocument,
  getFilteredAndPagenationAllDocument,
  getTutors,
  getTutorsId,
  getTutorsFiles,
  getTutorsStudents,
  getStudent,
  getStudentsAll,
  getAllTutorsID,
  postSMS,
  getAllMessage,
  getMessages,
  postXabar,
  getSuccessMissia,
  getWaitingMissia,
  getWaitingMissiaAllStudent,
  getSuccessMissiaAllStudent,
  deleteWaitingMissia,
  getWaitingMissiaInStudent,
  updateWaitingMissia,
  getStudentPhone,
};
