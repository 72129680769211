import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect, useState } from "react";
import { Badge, Divider, Paper, Stack, createSvgIcon } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { useSelector } from "react-redux";
import Loading from "./Loading";
import DescriptionIcon from "@mui/icons-material/Description";
import Context from "../Context";
import SmsIcon from '@mui/icons-material/Sms';
import NotificationsIcon from "@mui/icons-material/Notifications";
import AssignmentIcon from '@mui/icons-material/Assignment';

const DashboardIcon = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <rect
      x="1"
      y="1"
      width="8"
      height="8"
      rx="3"
      stroke="currentColor"
      strokeWidth="2"
    />
    <rect
      x="13"
      y="13"
      width="8"
      height="8"
      rx="3"
      stroke="currentColor"
      strokeWidth="2"
    />
    <rect
      x="1"
      y="13"
      width="8"
      height="8"
      rx="3"
      stroke="currentColor"
      strokeWidth="2"
    />
    <rect
      x="13"
      y="1"
      width="8"
      height="8"
      rx="3"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>,
  "DashboardIcon"
);

function Header(props) {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData);
  const [width, setWidth] = useState(window.innerWidth);
  const [openSearch, setOpenSearch] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAuth, setDarkMode } = useContext(Context);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
    setOpenMenu(false);
  });
  const openSearchHandler = () => {
    setOpenSearch(!openSearch);
  };
  const openMenuHandler = () => {
    setOpenMenu(!openMenu);
  };

  const darkModeHandler = () => {
    setDarkMode("dark");
    window.localStorage.setItem("darkMode", "dark");
  };
  const lightModeHandler = () => {
    setDarkMode("light");
    window.localStorage.setItem("darkMode", "light");
  };

  const logOut = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("Token");
    setAuth(false);
  };

  return (
    <Box
      onClick={() => 0}
      sx={{
        background: (theme) => theme.palette.bg.main,
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <AppBar position="fixed" sx={{ p: 0, mx: 0 }}>
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              dispkay: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={openMenuHandler}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Box>
              <Typography
                onClick={() => navigate("/")}
                variant="h5"
                noWrap
                href=""
                sx={{
                  mr: 2,
                  display: {
                    xs: openSearch ? "none" : "flex",
                    sm: "inline-flex",
                  },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <Box component='img' src='/logo/logo.png' sx={{height: 70}} />
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}></Box>

            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  transition: "0.3s all linear",
                  background: {
                    xs: openSearch ? "rgba(255, 255, 255, 0.25)" : "none",
                    sm: "rgba(255, 255, 255, 0.25)",
                  },
                  borderRadius: 2,
                  mr: 1,
                  pl: { xs: openSearch ? 1 : 0, sm: 1 },

                  "& input": {
                    background: "none",
                    border: "none",
                    outline: "none",
                    p: 1,
                    pl: 0,
                    color: "currentColor",
                    fontSize: "16px",
                    transition: "0.3s all linear",
                    width: { xs: openSearch ? "180px" : "0px", sm: "180px" },
                    pl: { xs: openSearch ? 0.5 : 0, sm: 0.5 },

                    "&:focus": {
                      background: "none",
                      border: "none",
                      outline: "none",
                      width: { sm: "250px" },
                    },
                    "&::placeholder": {
                      color: "currentColor",
                      opacity: "0.5",
                    },
                  },
                }}
              >
                <IconButton
                  onClick={openSearchHandler}
                  sx={{
                    color: "currentColor",
                    background: {
                      xs: !openSearch && "rgba(255, 255, 255, 0.25) !important",
                      sm: "none !important",
                    },
                    "&:hover": {
                      background: {
                        xs:
                          !openSearch && "rgba(255, 255, 255, 0.4) !important",
                        sm: "none !important",
                      },
                    },
                  }}
                >
                  <SearchIcon />
                </IconButton>
                <input type="text" name="" id="" placeholder="Search..." />
              </Box> */}
              <Tooltip title="Notification">
                <IconButton sx={{ p: 1 }}>
                  <Badge badgeContent={4} color="success">
                    <NotificationsIcon sx={{color: '#fff'}} />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container
        onClick={() => setOpenSearch(false)}
        maxWidth={"xl"}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <Box
          sx={{
            minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
            maxHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "end",
            width: { xs: 0, md: 64, lg: 250 },
            minWidth: { lg: 250 },
            position: "fixed",
            top: { xs: 56, sm: 64 },
            zIndex: 4,
          }}
        >
          <Paper
            color="menuBg"
            sx={{
              width: { xs: 200, md: 18, lg: 200 },
              minHeight: {
                xs: "calc(100vh - 56px)",
                sm: "calc(100vh - 64px)",
                md: "calc(100vh - 200px)",
              },
              borderRadius: { xs: 0, md: 4 },
              overflow: "hidden",
              transform: openMenu && "translateX(236px)",
              py: 3,
              px: 2,
              left: { xs: -260, md: 0 },
              position: "absolute",
              zIndex: 2,
              transition: "0.3s all linear",
              background: (theme) =>
                theme.palette.mode === "light"
                  ? "rgba(255,255,255, 0.5)"
                  : "rgba(0,0,0, 0.5)",
              backdropFilter: "blur(12px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              "&:hover": {
                width: 200,
                "& .MuiAvatar-root": {
                  width: 100,
                  height: 100,
                },
                "& .MuiTypography-root": {
                  opacity: { md: 1 },
                },
                "& .divid": {
                  mt: { md: 2 },
                },
                "& .MuiStack-root": {
                  ml: { md: 0 },
                },
              },
              "& a": {
                color: "text.secondary",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textDecoration: "none",
                transition: "0.3s all",
                fontSize: 16,
                borderRadius: 5,
                my: 0.5,
                cursor: "pointer",
                py: 1,
                "&:hover": {
                  color: "text.primary",
                },
                "& svg": {
                  width: 22,
                  height: 22,
                  mr: 1.5,
                },
              },
            }}
          >
            <Box>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "start",
                    pt: { xs: 2, md: 0 },
                  }}
                >
                  <Avatar
                    label={!!userData && userData.first_name}
                    src={!!userData && userData.image}
                    sx={{
                      width: { xs: 100, md: 24, lg: 100 },
                      height: { xs: 100, md: 24, lg: 100 },
                      transition: "0.3s all linear",
                      mr: { md: 2, lg: 0 },
                    }}
                  />
                  <IconButton
                    sx={{
                      width: 40,
                      height: 40,
                      display: (theme) =>
                        theme.palette.mode === "dark" ? "block" : "none",
                    }}
                    onClick={lightModeHandler}
                  >
                    <LightModeIcon />
                  </IconButton>
                  <IconButton
                    sx={{
                      width: 40,
                      height: 40,
                      display: (theme) =>
                        theme.palette.mode === "light" ? "block" : "none",
                    }}
                    onClick={darkModeHandler}
                  >
                    <DarkModeIcon />
                  </IconButton>
                </Box>
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Ubuntu, sans-serif",
                    opacity: { md: 0, lg: 1 },
                    whiteSpace: "nowrap",
                    textTransform: "capitalize",
                  }}
                >
                  {!!userData.first_name && userData.first_name.toLowerCase()}{" "}
                  &nbsp;{" "}
                  {!!userData.second_name && userData.second_name.toLowerCase()}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "Ubuntu, sans-serif",
                    opacity: { md: 0, lg: 1 },
                    whiteSpace: "nowrap",
                  }}
                >
                  + {userData.phone_number}
                </Typography>
                <Divider
                  sx={{ my: 2, mt: { md: -5, lg: 2 } }}
                  className="divid"
                />
              </Box>
              <Stack sx={{ ml: { md: "-5px", lg: 0 } }}>
                <Typography
                  color="text.secondary"
                  sx={{ fontSize: 13, my: -1 }}
                >
                  Menu
                </Typography>
              </Stack>
              <NavLink to="/">
                <DashboardIcon /> Dashboard
              </NavLink>
              <NavLink to="/groups">
                <FolderOutlinedIcon /> Guruhlar
              </NavLink>
              <NavLink to="/all_students">
                <PeopleAltOutlinedIcon /> Studentlar
              </NavLink>
              <NavLink to="/documents">
                <DescriptionIcon /> Hujjatlar
              </NavLink>
              <Divider sx={{ mb: 1 }} />
              <NavLink to="messages" >
                <SmsIcon /> Xabarlar
              </NavLink>
              <NavLink to="assigments" >
                <AssignmentIcon /> Topshiriqlar
              </NavLink>
            </Box>
            <Box>
              <Stack sx={{ ml: { md: "-10px", lg: 0 } }}>
                <Typography
                  color="text.secondary"
                  sx={{ fontSize: 13, my: -1 }}
                >
                  Control
                </Typography>
              </Stack>
              <NavLink style={{ whiteSpace: "nowrap" }} onClick={logOut}>
                <PowerSettingsNewOutlinedIcon /> Log out
              </NavLink>
            </Box>
          </Paper>
        </Box>
        <Box
          sx={{ width: "100%", position: "relative" }}
          onClick={() => setOpenMenu(false)}
        >
          {loading == true ? (
            <Container
              maxWidth="xl"
              sx={{
                py: 6,
                px: { xs: 0, sm: 0 },
                pl: { md: 10, lg: "250px" },
                mt: { xs: "56px", sm: "64px" },
                minHeight: {
                  xs: "calc(100vh - 56px)",
                  sm: "calc(100vh - 64px)",
                },
                position: "relative",
              }}
            >
              <Loading />
            </Container>
          ) : (
            <Container
              maxWidth="xl"
              sx={{
                py: 6,
                px: { xs: 0, sm: 0 },
                pl: { md: 10, lg: "250px" },
                mt: { xs: "56px", sm: "64px" },
                minHeight: {
                  xs: "calc(100vh - 56px)",
                  sm: "calc(100vh - 64px)",
                },
              }}
            >
              {props.children}
            </Container>
          )}
        </Box>
      </Container>
    </Box>
  );
}
export default Header;
