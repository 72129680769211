import { Fab, IconButton, Paper, Stack } from "@mui/material";
import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import SmsIcon from "@mui/icons-material/Sms";
import TelegramIcon from "@mui/icons-material/Telegram";

export default function CallNow({ data }) {
  if (
    data.student_phone_number_1 == "" &&
    data.student_phone_number_2 == "" &&
    data.student_telegram == ""
  ) {
    return;
  } else {
    return (
      <Paper elevation={2} sx={{ borderRadius: 4, py: 3, px: 6, mt: 2 }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: 300,
            mx: "auto",
          }}
        >
          {data.student_phone_number_1 !== "" && (
            <Fab
              component="a"
              href={`tel:+${data.student_phone_number_1}`}
              sx={{
                background: "#a1e23d",
                boxShadow: "none",
                zIndex: 1,
                "& svg": { color: "#fff" },
                "&:hover": {
                  background: "#a1e23d",
                },
              }}
            >
              <PhoneIcon />
            </Fab>
          )}
          {data.student_phone_number_1 !== "" && (
            <Fab
              component="a"
              href={`sms:+${data.student_phone_number_1}`}
              sx={{
                background: "#ffa33b",
                boxShadow: "none",
                zIndex: 1,
                "& svg": { color: "#fff" },
                "&:hover": {
                  background: "#ffa33b",
                },
              }}
            >
              <SmsIcon />
            </Fab>
          )}
          {data.student_telegram == "" && (
            <Fab
              component="a"
              target="_blank"
              href={`https://t.me/${data.student_telegram
                .slice(1, 100)
                .trim()}`}
              sx={{
                background: "#0088cc",
                boxShadow: "none",
                zIndex: 1,
                "& svg": { color: "#fff" },
                "&:hover": {
                  background: "#0088cc",
                },
              }}
            >
              <TelegramIcon />
            </Fab>
          )}
        </Stack>
      </Paper>
    );
  }
}
