import React, { useEffect, useState } from 'react';
import ManaviyatGroupData from '../components/ManaviyatGroupData';
import { useParams } from 'react-router-dom';
import { getTutorsStudents } from '../api';
import PageNotFound from './PageNotFound';
import { useDispatch, useSelector } from 'react-redux';
import { ALL_STUDENTS, MANAVIYAT_GROUPS } from '../redux/action';
import fetching from '../lottie/fetching-loading.json';

export default function ManaviyatGroup() {
	const params = useParams();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState({ status: true, data: false });
	const manaviyatGroups = useSelector((state) => state.manaviyatGroups);
	useEffect(() => {
		if (!!manaviyatGroups[params.group]) {
			setLoading({ status: false, data: true });
		}
		const request = async () => {
			const data = await getTutorsStudents(params.group);
			if (data.data.items.length !== 0) {
				setLoading({ status: false, data: true });
				dispatch({
					type: MANAVIYAT_GROUPS,
					value: { group: params.group, data: data.data.items },
				});
				dispatch({
					type: ALL_STUDENTS,
					value: data.data.items,
				});
			} else {
				setLoading({ status: false, data: false });
			}
		};
		request();
	}, [params.group]);

	if (loading.status == true) {
		return <PageNotFound data={fetching} />;
	} else if (loading.status == false && loading.data == false) {
		return <PageNotFound />;
	} else if (loading.status == false && loading.data == true) {
		return (
			<ManaviyatGroupData allStudents={manaviyatGroups[params.group]} />
		);
	}
}
