import { Box } from '@mui/material';
import Lottie from 'lottie-react';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import error from '../lottie/progress.json';

export default function PageLoading(props) {
	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<Box sx={{ width: 100 }}>
				<Lottie animationData={!!props.data ? props.data : error} />
			</Box>
		</Box>
	);
}
