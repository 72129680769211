import { Avatar, Box, InputLabel, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MuiFileInput } from "mui-file-input";
import { LoadingButton } from "@mui/lab";
import {
  getAllMessage,
  getAllTutorsID,
  getTutors,
  getTutorsId,
  postSMS,
  setDocument,
} from "../api";
import { useDispatch, useSelector } from "react-redux";
import { ALL_MESSAGES, TOTAL_TUTORS, TOTAL_USER_DATA } from "../redux/action";
import PageLoading from "./PageLoading";
import SelectTutorItem from "../components/SelectTutorItem";
import GetAllMessage from "../components/GetAllMessage";
import Lottie from "lottie-react";
import lottieUploading from '../lottie/uploading.json'

export default function NewMissia(props) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const userData = useSelector((state) => state.userData);
  const totalUserData = useSelector((state) => state.totalUserData);
  const totalTutors = useSelector((state) => state.totalTutors);
  const textareaRef = useRef(null);
  const [tutors, setTutors] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(!totalTutors.length ? true : false);
  const [error, setError] = useState({ status: false, message: "" });
  const [resMessage, setRessMessage] = useState({ status: 200, message: "" });
  const allMessages = useSelector((state) => state.allMessages);
  const [allMessagesLoading, setAllMessagesLoading] = useState(true);
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    if (error.status) {
      if (!tutors.length) {
        setError({
          status: true,
          message: "Xabar yubormoqchi bo'gan tyutorlarni tanlang!!!",
        });
      } else {
        setError({ status: true, message: "" });
      }
    }
  }, [tutors]);

  const selectTutor = (objects) => {
    if (objects.length == 1) {
      if (tutors.filter((el) => el.id == objects[0].id).length == 0) {
        setTutors([...tutors, objects[0]]);
      } else if (tutors.filter((el) => el.id == objects[0].id).length == 1) {
        setTutors(tutors.filter((el) => el.id !== objects[0].id));
      }
    } else if (objects.length == tutors.length) {
      setTutors([]);
    } else if (objects.length == totalTutors.length) {
      setTutors(objects);
    }
  };

  useEffect(() => {
    if (totalTutors.length == 0) {
      if (totalUserData.map((el) => el.hemis_id).length > 0) {
        const request = async () => {
          const data = await getTutors(totalUserData.map((el) => el.hemis_id));
          if (data.length > 0) {
            dispatch({ type: TOTAL_TUTORS, value: data });
            setLoading(false);
          }
        };
        request();
      }
    }
  }, [totalUserData]);

  useEffect(() => {
    const res = async () => {
      const data = await getAllTutorsID();
      dispatch({ type: TOTAL_USER_DATA, value: data.data });
    };
    res();
  }, []);

  const onSubmit = async (data) => {
    if (!!tutors.length) {
      setUploading(true);
      let sms = {
        xabar: data.comment,
        tutorlar: tutors.map((el) => +el.id),
        fullname: userData.first_name + userData.second_name,
        img: userData.image,
      };
      const request = async () => {
        data = await postSMS(sms);
        setRessMessage({ status: data.status, message: data.data });
        if (data.status == 200) {
          reset();
          setTutors([]);
          setDate(new Date())
        }
      };
      request();
      setUploading(false);
    } else {
      setError({
        status: true,
        message: "Xabar yubormoqchi bo'lgan tyutorlarni belgilang!!!",
      });
    }
  };

  useEffect(() => {
    if(resMessage.message !== "")
    {
      setTimeout(() => {
        setRessMessage({ status: 200, message: "" });
      }, 10000);
    }
  }, [resMessage.message]);

  useEffect(() => {
    const request = async () => {
      const data = await getAllMessage();
      if (data.status == 200) {
        dispatch({ type: ALL_MESSAGES, value: data.data });
      }
      setAllMessagesLoading(false);
    };
    request();
  }, [date]);


  return (
    <Box>
      <Typography
        color="text.primary"
        sx={{
          fontFamily: "Ubuntu, sans-serif",
          fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
          fontWeight: "700",
        }}
      >
        Xabar yuborish
      </Typography>
      {resMessage.message.length > 0 && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            top: 0,
            left: 0,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 100,
              right: 10,
              background: resMessage.status == 200 ? "#00b533" : "#e74c3c",
              px: 3,
              py: 2,
              borderRadius: 40,
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: "Ubuntu, sans-serif",
                color: "#fff",
              }}
            >
              {resMessage.message}
            </Typography>
          </Box>
        </Box>
      )}
      <Grid2 container>
        <Grid2 xs={12} md={8} lg={8}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "500" }}
            color="text.secondary"
          >
            Tyutorlarga matn ko'rinishida sms xabar yuborishingiz va aynan mana
            shu sms xabarnomada tyutorlarga beriladigan topshiriqlarni bayon
            erishingiz mumkin!
          </Typography>
        </Grid2>
      </Grid2>
      <Box sx={{ mt: { xs: 1, md: 4 }, mb: { xs: 2, md: 3 } }}>
        <Box>
          <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Grid2 container>
              <Grid2 xs={12} md={6}>
                <Typography
                  color="text.primary"
                  sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    fontFamily: "Ubuntu, sans-serif",
                  }}
                >
                  Yangi xabar qoldirish
                </Typography>
                <Box
                  sx={{
                    p: { xs: 0.5 },
                    position: "relative",
                    "& textarea": {
                      p: 1,
                      width: "calc(100% - 16px)",
                      border: "1px solid #0000003f !important",
                      borderRadius: 3,
                      fontSize: 16,
                      fontFamily: "Ubuntu, sans-serif",
                      transition: "0.3s all",
                      background: (theme) =>
                        theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                      color: "text.primary",
                      "&:hover": {
                        border: "1px solid transparent",
                      },
                      "&:focus": {
                        outline: "2px solid #096bde",
                        border: "none",
                      },
                      "&:focus-visible": {
                        outline: "2px solid #096bde",
                        border: "none",
                      },

                      "&::-webkit-resizer": {
                        display: "none",
                      },
                    },
                  }}
                >
                  <Typography
                    color="text.primary"
                    sx={{
                      m: 0.5,
                      mt: 2,
                      fontSize: 16,
                      fontFamily: "Ubuntu, sans-serif",
                    }}
                  >
                    Xabaringiz mazmunini bayon qiling!
                  </Typography>
                  <textarea
                    ref={textareaRef}
                    placeholder="Xabar qoldirish..."
                    rows={4}
                    {...register("comment", { required: true })}
                  />
                  {!!errors.comment && (
                    <Typography
                      color="error.main"
                      sx={{
                        ml: 1,
                        fontSize: 14,
                        fontFamily: "Ubuntu, sans-serif",
                      }}
                    >
                      Xabaringizni yozing!!!
                    </Typography>
                  )}
                </Box>
                <Box sx={{ p: 0.5 }}>
                  <Typography
                    color="text.primary"
                    sx={{
                      m: 0.5,
                      fontSize: 16,
                      fontFamily: "Ubuntu, sans-serif",
                    }}
                  >
                    Xabar yubormoqchi bo'lgan tyutorlarni belgilang!
                  </Typography>
                  {loading ? (
                    <Box
                      sx={{
                        width: "100%",
                        height: "150px",
                        p: 1,
                        width: "calc(100% - 16px)",
                        border: "1px solid #0000003f",
                        outline: "1px solid transparent",
                        borderRadius: 3,
                        resize: "vertical",
                        fontSize: 16,
                        fontFamily: "Ubuntu, sans-serif",
                        transition: "0.3s all",
                        background: (theme) =>
                          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                        color: "text.primary",
                        "&:hover": {
                          border: "1px solid transparent",
                        },
                      }}
                    >
                      <PageLoading />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        maxHeight: "400px",
                        p: 1,
                        width: "calc(100% - 16px)",
                        overflowY: "auto",
                        border: !tutors.length
                          ? "1px solid #0000003f !important"
                          : "none",
                        outline: !tutors.length ? "none" : "2px solid #096bde",
                        borderRadius: 3,
                        fontSize: 16,
                        fontFamily: "Ubuntu, sans-serif",
                        transition: "0.3s all",
                        background: (theme) =>
                          theme.palette.mode === "light" ? "none" : "#09090d3f",
                        color: "text.primary",
                        "&:hover": {
                          // border: "2px solid transparent",
                        },
                        "&:focus": {
                          border: "none",
                        },
                        "&:focus-visible": {
                          outline: "2px solid #096bde",
                          border: "none",
                        },
                      }}
                    >
                      <Box
                        onClick={() =>
                          selectTutor(
                            totalTutors.map((el) => {
                              return {
                                sendTo: "Farid Tursunboyev",
                                title: "sssssssssssss",
                                id: el.employee_id_number,
                              };
                            })
                          )
                        }
                        sx={{
                          borderRadius: 3,
                          background: (theme) =>
                            theme.palette.mode === "light"
                              ? tutors.length == totalTutors.length
                                ? "#096bde3f"
                                : "#fff"
                              : tutors.length == totalTutors.length
                              ? "#09090d"
                              : "#09090d3f",
                          border: (theme) =>
                            theme.palette.mode === "light"
                              ? "1px solid #0000002f"
                              : "1px solid #ffffff2f",
                          my: 0.5,
                          display: "flex",
                          flexDirection: "row",
                          px: 1,
                          py: 1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="text.primary"
                          sx={{
                            ml: 3.75,
                            fontSize: 16,
                            fontWeight: 400,
                            fontFamily: "Ubuntu, sans-serif",
                          }}
                        >
                          Barcha tyutorlar
                        </Typography>
                      </Box>
                      {totalTutors.map((el) => {
                        return (
                          <SelectTutorItem
                            tutors={tutors}
                            key={el.employee_id_number}
                            data={el}
                            selectTutor={selectTutor}
                          />
                        );
                      })}
                    </Box>
                  )}
                  {error.message !== "" && (
                    <Typography
                      color="error.main"
                      sx={{
                        ml: 1,
                        fontSize: 14,
                        fontFamily: "Ubuntu, sans-serif",
                      }}
                    >
                      {error.message}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={uploading}
                    sx={{ mt: 2, borderRadius: 3, textTransform: "capitalize" }}
                  >
                    Yuborish
                  </LoadingButton>
                </Box>
              </Grid2>
              <Grid2 xs={12} md={6} >
                {allMessagesLoading ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      p: 1,
                      width: "calc(100% - 16px)",
                      resize: "vertical",
                      fontSize: 16,
                      fontFamily: "Ubuntu, sans-serif",
                      transition: "0.3s all",
                      overflowY: 'auto'
                    }}
                  >
                    <PageLoading />
                  </Box>
                ) : <GetAllMessage messages={allMessages} />}
              </Grid2>
            </Grid2>
          </Box>
        </Box>
      </Box>
      {uploading ? (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            top: 0,
            left: 0,
            zIndex: 99999932,
            display: "fixed",
            alignItems: "center",
            justifyContent: "center",
            background: "#0000003f",
          }}
        >
          <Box sx={{ width: 300 }}>
            <Lottie animationData={lottieUploading} />
          </Box>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}
