import {
	Avatar,
	Box,
	Divider,
	FormControl,
	Paper,
	Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { LoadingButton } from '@mui/lab';
import { InputMask } from '@react-input/mask';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Error from '../components/Error';
import { sentOtp } from '../api/authApi';
import Lottie from 'lottie-react';
import lottieSignup from '../lottie/signup.json';

export default function SignUp(props) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState({ status: null, message: '' });

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const signUpRes = async (data) => {
		setLoading(true);
		sentOtp(
			data.tel.slice(1),
			data.hemisId,
			data.password,
			props.setSendNumberRes,
			setLoading,
			setMessage
		);
	};

	useEffect(() => {
		setTimeout(() => {
			setMessage({ status: null, message: '' });
		}, 7000);
	}, [setMessage, message]);

	useEffect(() => {
		if (message.status === 200) {
			setLoading(false);
			navigate('/smsconfirmation');
		}
	}, [props.sendNumberRes]);

	return (
		<Box
			sx={{
				minHeight: '100vh',
				height: '100%',
				width: '100%',
				position: 'relative',
			}}>
			{message.status === null ? (
				''
			) : (
				<Box
					sx={{
						position: 'fixed',
						width: '100%',
						top: 0,
						left: 0,
						zIndex: 1,
					}}>
					<Box
						sx={{
							position: 'absolute',
							top: 10,
							right: 10,
							background:
								message.status == 200 ? '#00b533' : '#e74c3c',
							px: 3,
							py: 2,
							borderRadius: 40,
						}}>
						<Typography
							sx={{
								fontSize: 18,
								fontFamily: 'Ubuntu, sans-serif',
								color: '#fff',
							}}>
							{message.message}
						</Typography>
					</Box>
				</Box>
			)}

			<Box
				sx={{
					width: '100%',
					minHeight: '100vh',
					height: '100%',
					position: 'absolute',
					zIndex: -1,
					left: 0,
					right: 0,
					background: '#F5F5F5',
				}}>
				<Grid2 container>
					<Grid2
						xs={12}
						md={6}
						sx={{
							display: 'flex',
							minHeight: '100vh',
							height: '100%',
							justifyContent: 'center',
							alignItems: { xs: 'start', md: 'center' },
						}}>
						<Box sx={{ width: 600 }}>
							<Lottie animationData={lottieSignup} />
						</Box>
					</Grid2>
				</Grid2>
			</Box>
			<Grid2 container>
				<Grid2 xs={12} md={6}></Grid2>
				<Grid2
					xs={12}
					md={6}
					sx={{
						display: 'flex',
						height: '100vh',
						justifyContent: 'center',
						alignItems: 'center',
						mt: 10,
						p: { xs: 1, sm: 3 },
					}}>
					<Paper
						sx={{
							background: '#ffffff3f',
							backdropFilter: 'blur(10px)',
							width: '100%',
							borderRadius: 4,
							px: { xs: 2, sm: 3 },
							py: { xs: 3, sm: 4 },
							mt: { xs: -10, sm: -10 },
							maxWidth: '500px',
						}}>
						<Divider>
							<Avatar
								src='img/logo/logo.png'
								sx={{ width: 100, height: 100 }}
							/>
						</Divider>
						<Typography
							color='text.primary'
							sx={{
								fontSize: 22,
								fontWeight: '600',
								mb: 4,
								textAlign: 'center',
							}}>
							O'zbekiston-Finlandiya pedagogika instituti
						</Typography>
						<Divider>
							<Typography>
								Shaxsiy kabinetingizni yarating!
							</Typography>
						</Divider>
						<FormControl
							onSubmit={handleSubmit(signUpRes)}
							component='form'
							sx={{
								mt: 4,
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								'& div': {
									width: '100%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								},
								'& input': {
									width: '100%',
									maxWidth: '400px',
									padding: '15px 20px',
									borderRadius: 50,
									background: 'none',
									color: 'text.primary',
									fontSize: 17,
									border: (theme) =>
										theme.palette.mode === 'light'
											? '1px solid #0000003f'
											: '1px solid #ffffff3f',
									my: 1,
									'&:-internal-autofill-selected': {
										background: 'none !important',
										backgroundColor: 'none !important',
									},
								},
							}}>
							<Box sx={{ width: '100%' }}>
								<input
									type='text'
									name=''
									id=''
									placeholder='Hemis login...'
									{...register('hemisId', {
										required: {
											value: true,
											message:
												'Siz hemis loginingizni kiritmadingiz.',
										},
										minLength: {
											value: 4,
											message:
												"Siz xato ma'lumot kiritdingiz.",
										},
									})}
								/>
							</Box>
							<Typography
								sx={{
									fontSize: 14,
									textAlign: 'left',
									width: '90%',
									ml: { xs: 0, sm: 2, md: 0, lg: 2 },
								}}
								color='error'>
								{!!errors.hemisId && errors.hemisId.message}
							</Typography>
							<Box sx={{ width: '100%' }}>
								<InputMask
									mask='+998 __ ___ __ __'
									replacement={{ _: /\d/ }}
									label='Label for custom component'
									placeholder='+998'
									defaultValue='+998 '
									{...register('tel', {
										required: {
											value: true,
											message: 'Siz raqam kiritmadingiz.',
										},
										minLength: {
											value: 17,
											message:
												'Siz xato raqam kiritdingiz.',
										},
									})}
								/>
							</Box>
							<Typography
								sx={{
									fontSize: 14,
									textAlign: 'left',
									width: '90%',
									ml: { xs: 0, sm: 2, md: 0, lg: 2 },
								}}
								color='error'>
								{!!errors.tel && errors.tel.message}
							</Typography>
							<Box sx={{ width: '100%' }}>
								<input
									type='password'
									placeholder='Password...'
									{...register('password', {
										required: {
											value: true,
											message:
												'Siz password kiritmadingiz.',
										},
										minLength: {
											value: 8,
											message:
												"Siz xato ma'lumot kiritdingiz.",
										},
									})}
								/>
							</Box>
							<Typography
								sx={{
									fontSize: 14,
									textAlign: 'left',
									width: '90%',
									ml: { xs: 0, sm: 2, md: 0, lg: 2 },
								}}
								color='error'>
								{!!errors.password && errors.password.message}
							</Typography>
							<LoadingButton
								type='submit'
								variant='contained'
								color='primary'
								loading={loading}
								disableElevation
								sx={{ my: 1, width: 150, borderRadius: 100 }}>
								Yaratish
							</LoadingButton>
						</FormControl>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
								mt: 2,
							}}>
							<Typography
								color='text.secondary'
								sx={{
									fontSize: 16,
									fontFamily: 'Ubuntu, sans-serif',
								}}>
								Profilingiz yo'qmi? &nbsp;&mdash;&nbsp;
							</Typography>
							<Typography
								onClick={() => navigate('/login')}
								color='text.primary'
								sx={{
									fontSize: { xs: 14, sm: 16 },
									fontFamily: 'Ubuntu, sans-serif',
									fontWeight: '500',
									cursor: 'pointer',
									border: '1px solid transparent',
									transition: '0.3s all',
									px: 1,
									borderRadius: 40,
									'&:hover': {
										background: '#ffffff3f',
										border: '1px solid #0000001f',
									},
								}}>
								Profile
							</Typography>
						</Box>
					</Paper>
				</Grid2>
			</Grid2>
		</Box>
	);
}
