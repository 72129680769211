import { Avatar, Box, Divider, FormControl, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { InputMask } from '@react-input/mask';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { verifyOtp, verifySms } from '../api/authApi';
import { useNavigate } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Context from '../Context';
import Lottie from 'lottie-react';
import verifyCode from '../lottie/send-massage.json';

export default function SmsConfirmation(props) {
	const [waiting, setWaiting] = useState({ status: null, message: '' });
	const [loading, setLoading] = useState(false);
	const { setAuth } = useContext(Context);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const verify = (data) => {
		const code = data.sms.split(' ').join('');
		setLoading(false);
		if (props.sendNumberRes.mission === 'login') {
			const res = async () => {
				const verify = await verifySms(
					props.sendNumberRes.phoneNumber,
					props.sendNumberRes.password,
					code,
					setLoading
				);
				if (verify.status == 200) {
					setAuth(true);
					navigate('/');
				}
			};
			res();
		} else if (props.sendNumberRes.mission === 'signUp') {
			const res = async () => {
				const varify = await verifyOtp(
					props.sendNumberRes,
					code,
					setLoading,
					setWaiting
				);
				if (varify.status == 200) {
					setAuth(false);
					navigate('/login');
				}
			};
			res();
		}
	};

	useEffect(() => {
		if (
			!!props.sendNumberRes.status &&
			props.sendNumberRes.status !== 200
		) {
			navigate('/login');
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (waiting.status == 200) setWaiting({ ...waiting, message: '' });
			else setWaiting({ status: null, message: '' });
		}, 7000);
	}, [waiting]);

	return (
		<Box sx={{ minHeight: '100vh', width: '100%', position: 'relative' }}>
			{waiting.status === null ? (
				''
			) : (
				<Box
					sx={{
						position: 'fixed',
						width: '100%',
						top: 0,
						left: 0,
						zIndex: 1,
					}}>
					<Box
						sx={{
							position: 'absolute',
							top: 10,
							right: 10,
							background:
								waiting.status == 200 ? '#00b533' : '#e74c3c',
							px: 3,
							py: 2,
							borderRadius: 40,
						}}>
						<Typography
							sx={{
								fontSize: 18,
								fontFamily: 'Ubuntu, sans-serif',
								color: '#fff',
							}}>
							{waiting.message}
						</Typography>
					</Box>
				</Box>
			)}
			<Box
				sx={{
					width: '100%',
					minHeight: '100vh',
					height: '100%',
					position: 'absolute',
					zIndex: -1,
					left: 0,
					right: 0,
					background: '#F5F5F5',
				}}>
				<Grid2 container>
					<Grid2
						xs={12}
						md={6}
						sx={{
							display: 'flex',
							height: '100vh',
							justifyContent: 'center',
							alignItems: { xs: 'start', md: 'center' },
						}}>
						<Box sx={{ width: 600 }}>
							<Lottie animationData={verifyCode} />
						</Box>
					</Grid2>
				</Grid2>
			</Box>
			<Grid2 container>
				<Grid2 xs={12} md={6}></Grid2>
				<Grid2
					xs={12}
					md={6}
					sx={{
						display: 'flex',
						height: '100vh',
						justifyContent: 'center',
						alignItems: 'center',
						mt: 10,
						p: { xs: 1, sm: 3 },
					}}>
					<Paper
						sx={{
							background: '#ffffff3f',
							backdropFilter: 'blur(10px)',
							width: '100%',
							borderRadius: 4,
							px: { xs: 2, sm: 3 },
							py: { xs: 3, sm: 4 },
							mt: { xs: -10, sm: -10 },
							maxWidth: '500px',
						}}>
						<Divider>
							<Avatar
								src='img/logo/logo.png'
								sx={{ width: 100, height: 100 }}
							/>
						</Divider>
						<Typography
							color='text.primary'
							sx={{
								fontSize: 22,
								fontWeight: '600',
								mb: 4,
								textAlign: 'center',
							}}>
							O'zbekiston-Finlandiya pedagogika instituti
						</Typography>
						<Divider>
							<Typography>
								Shaxsingizni tasdiqlang!
							</Typography>
						</Divider>
						{waiting.status === 200 ? (
							<>
								<Typography>{waiting.message}</Typography>
							</>
						) : (
							<FormControl
								onSubmit={handleSubmit(verify)}
								component='form'
								sx={{
									mt: 4,
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									'& input': {
										width: '90%',
										maxWidth: '400px',
										padding: '15px 20px',
										borderRadius: 50,
										background: 'none',
										border: '1px solid #0000003f',
										my: 1,
									},
								}}>
								<InputMask
									mask='_ _ _ _ _'
									replacement={{ _: /\d/ }}
									label='Label for custom component'
									placeholder='_ _ _ _'
									{...register('sms', {
										required: {
											value: true,
											message: 'Siz raqam kiritmadingiz.',
										},
										minLength: {
											value: 7,
											message:
												'Siz xato raqam kiritdingiz.',
										},
									})}
								/>
								<Typography
									sx={{
										fontSize: 12,
										textAlign: 'left',
										width: '90%',
									}}
									color='error'>
									{!!errors.tel && errors.tel.message}
								</Typography>

								<LoadingButton
									type='submit'
									variant='contained'
									color='primary'
									loading={loading}
									sx={{
										my: 1,
										width: 150,
										borderRadius: 100,
									}}>
									Login
								</LoadingButton>
							</FormControl>
						)}
					</Paper>
				</Grid2>
			</Grid2>
		</Box>
	);
}
