import { Box, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Students from '../components/Students';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageNotFound from './PageNotFound';
import AllStudents from '../components/AllStudents';
import fetching from '../lottie/fetching-loading.json';

export default function StudentsData() {
	const params = useParams();
	const navigate = useNavigate();
	const allStudents = useSelector((state) => state.allStudents);
	const [homeIconButton, setHomeIconButton] = useState(true);
	const [chairIconButton, setChairIconButton] = useState(true);
	const [locationIconButton, setLocationIconButton] = useState(true);
	const [page, setPage] = useState(
		Object.keys(params).includes('*') && params['*'] == ''
			? 1
			: +params.page
	);
	const [students, setStudents] = useState([]);

	const handleHomeIcon = () => {
		if (!chairIconButton && !locationIconButton && homeIconButton) {
			setHomeIconButton(false);
			setChairIconButton(true);
			setLocationIconButton(true);
		} else {
			setHomeIconButton(!homeIconButton);
		}
	};
	const handleChairIcon = () => {
		if (chairIconButton && !locationIconButton && !homeIconButton) {
			setHomeIconButton(true);
			setChairIconButton(false);
			setLocationIconButton(true);
		} else {
			setChairIconButton(!chairIconButton);
		}
	};
	const handleLocationIcon = () => {
		if (
			chairIconButton == false &&
			locationIconButton == true &&
			homeIconButton == false
		) {
			setHomeIconButton(true);
			setChairIconButton(true);
			setLocationIconButton(false);
		} else {
			setLocationIconButton(!locationIconButton);
		}
	};

	useEffect(() => {
		if (homeIconButton && !chairIconButton && !locationIconButton) {
			setStudents(
				allStudents.filter((el) => el.accommodation.name == 'O‘z uyida')
			);
		} else if (!homeIconButton && !chairIconButton && locationIconButton) {
			setStudents(
				allStudents.filter(
					(el) => el.accommodation.name == 'Ijaradagi uyda'
				)
			);
		} else if (!homeIconButton && chairIconButton && !locationIconButton) {
			setStudents(
				allStudents.filter(
					(el) => el.accommodation.name == 'Talabalar turar joyida'
				)
			);
		} else if (homeIconButton && chairIconButton && !locationIconButton) {
			setStudents(
				allStudents.filter(
					(el) =>
						el.accommodation.name == 'O‘z uyida' ||
						el.accommodation.name == 'Talabalar turar joyida'
				)
			);
		} else if (homeIconButton && !chairIconButton && locationIconButton) {
			setStudents(
				allStudents.filter(
					(el) =>
						el.accommodation.name == 'O‘z uyida' ||
						el.accommodation.name == 'Ijaradagi uyda'
				)
			);
		} else if (!homeIconButton && chairIconButton && locationIconButton) {
			setStudents(
				allStudents.filter(
					(el) =>
						el.accommodation.name == 'Talabalar turar joyida' ||
						el.accommodation.name == 'Ijaradagi uyda'
				)
			);
		} else if (homeIconButton && chairIconButton && locationIconButton) {
			setStudents(
				allStudents.filter(
					(el) =>
						el.accommodation.name == 'Talabalar turar joyida' ||
						el.accommodation.name == 'Ijaradagi uyda' ||
						el.accommodation.name == 'O‘z uyida'
				)
			);
		} else {
			setStudents(
				allStudents.filter(
					(el) =>
						el.accommodation.name == 'Talabalar turar joyida' ||
						el.accommodation.name == 'Ijaradagi uyda' ||
						el.accommodation.name == 'O‘z uyida'
				)
			);
		}
	}, [homeIconButton, chairIconButton, locationIconButton, page]);

	const handlePage = (e, value) => {
		setPage(value);
		navigate(`/all_students/${value}`);
	};

	useEffect(() => {
		if (Object.keys(params).includes('*') && params['*'] == '') {
			setPage(1);
		} else if (
			typeof +Object.keys(params)[0] == 'number' &&
			+params.page > 0 &&
			+params.page <= Math.ceil(allStudents.length / 24)
		) {
			setPage(+params.page);
		}
	}, [params.page]);

	if (page > 0 && page <= Math.ceil(allStudents.length / 24)) {
		return (
			<Box>
				{!allStudents.length ? (
					<PageNotFound data={fetching} />
				) : (
					<AllStudents
						students={students}
						handleHomeIcon={handleHomeIcon}
						handleChairIcon={handleChairIcon}
						handleLocationIcon={handleLocationIcon}
						homeIconButton={homeIconButton}
						chairIconButton={chairIconButton}
						locationIconButton={locationIconButton}
					/>
				)}
				{allStudents.length > 24 && (
					<Box
						sx={{
							pt: 5,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<Pagination
							count={Math.ceil(students.length / 24)}
							color='primary'
							siblingCount={1}
							page={page}
							onChange={handlePage}
						/>
					</Box>
				)}
			</Box>
		);
	} else {
		return <PageNotFound />;
	}
}
