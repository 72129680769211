import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
export default function StudentsObjectiveInfo(props) {
  const month = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "Iyul",
    "Avgust",
    "Sentyabr",
    "Oktyabr",
    "Noyabr",
    "Dekabr",
  ];
  return (
    <Paper elevation={2} sx={{ borderRadius: 4, py: 3, px: 2, mt: 2, border: 'none' }}>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          color="text.secondary"
          sx={{
            fontSize: 20,
            fontWeight: "700",
            fontFamily: "Ubuntu, sans-serif",
          }}
        >
          Qo'shimcha ma'lumotlari
        </Typography>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "flex-start",
          "& .MuiTypography-root": {
            fontSize: 16,
            fontFamily: "Ubuntu, sans-serif",
          },
          "& .MuiBox-root": {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            mr: 1,
            my: 0.5,
            "& .MuiTypography-root": {
              color: "text.secondary",
              fontWeight: "400",
              minWidth: 130,
            },
          },
          "& .MuiStack-root": {
            width: "100%",
            pl: 1,
            "& .MuiTypography-root": {
              color: "text.primary",
              fontWeight: "500",
              display: 'inline-flex !important'
            },
          },
        }}
      >
        <Box>
          <Typography>Ta'lim shakli:</Typography>
          <Stack>
            <Typography>
              {props.info.educationForm.name}
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Ta'lim bosqichi:</Typography>
          <Stack>
            <Typography>
              {props.info.educationType.name}
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Fakulteti:</Typography>
          <Stack>
            <Typography>
              {props.info.department.name}
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Yo'nalishi:</Typography>
          <Stack>
            <Typography>{props.info.specialty.name}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Guruhi:</Typography>
          <Stack>
            <Typography>{props.info.group.name}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Kursi:</Typography>
          <Stack>
            <Typography>{props.info.level.name}</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Ta'lim holati:</Typography>
          <Stack>
            {props.info.studentStatus.name === "O‘qimoqda" ? (
              <Typography>
                {props.info.studentStatus.name}
              </Typography>
            ) : (
              <Typography
                sx={{
                  background: "#f18f01",
                  color: "#fff !important",
                  borderRadius: 100,
                  px: 1,
                }}
              >
                {props.info.studentStatus.name}
              </Typography>
            )}
          </Stack>
        </Box>

        <Box>
          <Typography>To'lov shakli:</Typography>
          <Stack>
            {props.info.paymentForm.name === "To‘lov-shartnoma" ? (
              <Typography
                sx={{
                  background: "#f18f01",
                  color: "#fff !important",
                  borderRadius: 100,
                  px: 1,
                }}
              >
                {props.info.paymentForm.name}
              </Typography>
            ) : (
              <Typography
                sx={{
                  background: "#4bb543",
                  color: "#fff !important",
                  borderRadius: 100,
                  px: 1,
                }}
              >
                {props.info.paymentForm.name}
              </Typography>
            )}
          </Stack>
        </Box>
        <Box>
          <Typography>Ijtimoiy toifasi:</Typography>
          <Stack>
            <Typography>
              {props.info.socialCategory.name}
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Typography>Talaba toifasi:</Typography>
          <Stack>
            {props.info.studentType.name === "Oddiy" ? (
              <Typography>
                {props.info.studentType.name}
              </Typography>
            ) : (
              <Typography
                sx={{
                  background: "#4bb543",
                  color: "#fff !important",
                  borderRadius: 100,
                  px: 1,
                }}
              >
                {props.info.studentType.name}
              </Typography>
            )}
          </Stack>
        </Box>
        <Box>
          <Typography>Hozirgi manzili:</Typography>
          <Stack>
            <Typography>
              {props.info.accommodation.name}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
}
