import React, { useState } from "react";
import Header from "../components/Header";
import { Avatar, AvatarGroup, Box, Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import GroupsIcon from "@mui/icons-material/Groups";
import { useNavigate } from "react-router-dom";
const color = [
  "#bd5844",
  "#ff5722",
  "#673ab7",
  "#fde79d",
  "#1f6cfa",
  "#085c25",
  "#4caf50",
  "#e91e63",
];

export default function GroupItem(props) {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => setWidth(window.innerWidth));

  const routeNavigate = () => {
    if (!!props.name && props.name === "Barchasi") {
      navigate('/all_students')
    }
    else if(!!props.name && props.name !== "Barchasi")
    navigate(`/groups/${props.name}`)
  }
  return (
    <Grid2 xs={width < 400 ? 12 : 6} md={4} lg={4} sx={{ p: { xs: 1, ms: 2 } }}>
      <Paper
        onClick={routeNavigate}
        sx={{ borderRadius: 4, px: 2, py: 3 }}
      >
        <Box>
          <Grid2 container sx={{ width: "100%" }}>
            <Grid2 xs={12} md={12}>
              <GroupsIcon
                sx={{
                  width: { xs: width < 500 ? 40 : 50, sm: 50 },
                  fontSize: { xs: width < 500 ? 40 : 50, sm: 50 },
                }}
              />
            </Grid2>
            <Grid2
              xs={12}
              md={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: { xs: "start", md: "end" },
                pl: { lg: 1 },
              }}
            >
              <Typography
                color="text.primary"
                sx={{
                  fontSize: { xs: width < 500 ? 16 : 18, sm: 18 },
                  fontWeight: 600,
                  fontFamily: "Ubuntu, sans-serif",
                }}
              >
                {props.name.split("_").join(" ")}
              </Typography>
            </Grid2>
            <Grid2 xs={4} md={3} lg={width < 1300 ? 1 : 3}></Grid2>
            <Grid2
              xs={12}
              sm={12}
              md={12}
              lg={width < 1300 ? 11 : 9}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: { xs: "start", md: "end" },
                pl: { lg: 1 },
              }}
            >
              <Typography
                color="text.secondary"
                whiteSpace={"wrap"}
                sx={{
                  fontSize: { xs: width < 500 ? 15 : 18, sm: 18 },
                  fontWeight: 400,
                  mt: { sm: 0 },
                }}
              >
                {!!props.number
                  ? props.number + ` talabalar / ${props.allLength}`
                  : `${props.allLength} talabalar`}
              </Typography>
            </Grid2>
          </Grid2>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: { xs: "end" },
              mt: 2,
            }}
          >
            <AvatarGroup
              sx={{
                "& .MuiAvatar-root": {
                  width: { xs: 28, sm: 36 },
                  height: { xs: 28, sm: 36 },
                  fontSize: { xs: 14 },
                },
              }}
            >
              {!!props.students &&
                props.students.map((el) => {
                  return (
                    <Avatar
                      key={el.id}
                      sx={{
                        background: color[Math.round(Math.random() * 6)],
                      }}
                      alt={el.full_name}
                      src={el.image}
                    />
                  );
                })}
              {!!props.groupStudent &&
                props.groupStudent.map((el) => {
                  return (
                    <Avatar
                      key={el.id}
                      sx={{
                        background: color[Math.round(Math.random() * 6)],
                      }}
                      alt={el.full_name}
                      src={el.image}
                    />
                  );
                })}
            </AvatarGroup>
          </Box>
        </Box>
      </Paper>
    </Grid2>
  );
}
