import { BrowserRouter } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "../redux/store";
import Context from "../Context";
import { getUserData } from "../api";
import RoleRoutes from "./RoleRoutes";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = (mode) =>
  createTheme({
    palette: {
      mode,
      menuBg: {
        main: mode == "light" ? "rgba(255, 255, 255, 0.4)" : "rgba(0,0,0, 0.2)",
      },
      bg: {
        main: mode === "dark" ? "#303030" : "#fafafa",
      },
    },
  });

function AllRoutes() {
  const [auth, setAuth] = useState(
    !!window.localStorage.getItem("accessToken") &&
      window.localStorage.getItem("accessToken").length > 10
      ? true
      : false
  );
  const [role, setRole] = useState("");
  const [darkMode, setDarkMode] = useState(
    !!window.localStorage.getItem("darkMode")
      ? window.localStorage.getItem("darkMode")
      : "light"
  );

  return (
    <Context.Provider value={{ setAuth, role, setRole, setDarkMode }}>
      <div>
        <BrowserRouter>
          {auth ? (
            <Provider store={store}>
              <ThemeProvider theme={theme(darkMode)}>
                <RoleRoutes setAuth={setAuth} auth={auth} />
              </ThemeProvider>
            </Provider>
          ) : (
            <AuthRoutes />
          )}
        </BrowserRouter>
      </div>
    </Context.Provider>
  );
}

export default AllRoutes;
