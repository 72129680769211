import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useState, useEffect, useContext } from "react";
import StudentAvatar from "./StudentAvatar";
import EditFirstNumber from "./EditFirstNumber";
import EditSecondNumber from "./EditSecondNumber";
import EditEmail from "./EditEmail";
import StudentsPersonalInfo from "./StudentsPersonalInfo";
import StudentsObjectiveInfo from "./StudentsObjectiveInfo";
import CallNow from "./CallNow";
import {
  getStudentPhone,
  getWaitingMissiaInStudent,
  updateWaitingMissia,
} from "../api";
import { useDispatch, useSelector } from "react-redux";
import {
  STUDENT_PHONE,
  UPDATE_WAITING_MISSIA,
  WAITING_MISSIA_IN_STUDENT_PROFILE,
} from "../redux/action";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import StudentsPhoneInfo from "./StudentsPhoneInfo";
import StudentsLocationInfo from "./StudentsLocationInfo";
import Context from "../Context"

export default function StudentsProfile(props) {
  const dispatch = useDispatch();
  const { role } = useContext(Context)
  const waitingMissiaInStudentProfile = useSelector(
    (state) => state.waitingMissiaInStudentProfile
  );
  const studentPhone = useSelector((state) => state.studentPhone);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [edit, setEdit] = useState(true);
  const [location, setLocation] = useState("");
  const [resMessage, setRessMessage] = useState({ message: "", status: false });
  const [studentsPhoneLoading, setStudentPhoneLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => setWidth(window.innerWidth));

  useEffect(() => {
    const res = async () => {
      const data = await getWaitingMissiaInStudent(
        props.student.student_id_number
      );
      if (data.status == 200 && data.data.count > 0) {
        dispatch({
          type: WAITING_MISSIA_IN_STUDENT_PROFILE,
          value: {
            id: props.student.student_id_number,
            data: data.data.results[0],
          },
        });
      }
    };
    res();
  }, []);

  useEffect(() => {
    const res = async () => {
      const data = await getStudentPhone(props.student.student_id_number);
      if (data.status == 200 && data.data.count > 0) {
        dispatch({
          type: STUDENT_PHONE,
          value: {
            id: data.data.results[0].student_hemis_id,
            data: data.data.results[0],
          },
        });
      }
      setStudentPhoneLoading(false);
    };
    res();
  }, [waitingMissiaInStudentProfile[props.student.student_id_number]]);

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  };

  const onSubmit = async (data) => {
    if (Object.keys(location).length == 2) {
      const sms = {
        student_phone_number_1: data.tel1 || "",
        student_phone_number_2: data.tel2 || "",
        student_telegram: data.telegram || "",
        location_url: `${location.lat},${location.lng}`,
        status: true,
      };
      const res = await updateWaitingMissia(
        waitingMissiaInStudentProfile[props.student.student_id_number].id,
        sms
      );
      if (res.status == 200) {
        setRessMessage({ status: true, message: "Ma'lumotlar yuklandi." });
        dispatch({
          type: UPDATE_WAITING_MISSIA,
          value: props.student.student_id_number,
        });
      }
    } else {
      setRessMessage({ status: false, message: "Lokatsiya olinmadi." });
    }
  };

  useEffect(() => {
    if (resMessage.message.length > 0) {
      setTimeout(() => {
        setRessMessage({ status: false, message: "" });
      }, 7000);
    }
  }, [resMessage.message]);

  return (
    <Box>
      {resMessage.message.length > 0 && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            top: 0,
            left: 0,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 100,
              right: 10,
              background: resMessage.status == true ? "#00b533" : "#e74c3c",
              px: 3,
              py: 2,
              borderRadius: 40,
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: "Ubuntu, sans-serif",
                color: "#fff",
              }}
            >
              {resMessage.message}
            </Typography>
          </Box>
        </Box>
      )}
      <Grid2 container>
        <Grid2 xs={12} md={6} sx={{ px: 1 }}>
          <Paper elevation={2} sx={{ borderRadius: 4, border: "none" }}>
            <Stack
              sx={{
                py: 3,
                px: 2,
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "column",
                  lg: "row",
                  overflow: "hidden",
                },
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <StudentAvatar img={props.student.image} />
              <Box
                sx={{
                  pl: { sm: 2 },
                  mt: { xs: 2, sm: 0, md: 2 },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: {
                    xs: "center",
                    sm: "flex-start",
                    md: "center",
                    lg: "flex-start",
                  },
                }}
              >
                <Tooltip title={props.student.full_name} placement="top" arrow>
                  <Typography
                    color="text.primary"
                    sx={{
                      fontSize: 20,
                      fontWeight: "600",
                      fontFamily: "Ubuntu, sans-serif",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                      pr: 1,
                    }}
                  >
                    {props.student.first_name.toLowerCase()}{" "}
                    {props.student.second_name.toLowerCase()}
                  </Typography>
                </Tooltip>
                <Typography
                  color="text.secondary"
                  sx={{
                    fontSize: {
                      xs: 15,
                      sm: 16,
                      md: 17,
                    },
                    fontWeight: "400",
                    fontFamily: "Ubuntu, sans-serif",
                    my: 1,
                    textAlign: { xs: "center", lg: "left" },
                  }}
                >
                  {props.student.group.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  {(role == "Tutor" && waitingMissiaInStudentProfile[
                    props.student.student_id_number
                  ] !== undefined) && (
                    <Button
                      onClick={() => setEdit(true)}
                      variant="contained"
                      disableElevation
                      sx={{
                        fontSize: 16,
                        fontFamily: "Ubuntu, sans-serif",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        borderRadius: 10,
                      }}
                    >
                      Tahrirlash
                    </Button>
                  )}
                </Box>
              </Box>
            </Stack>
          </Paper>

          {studentPhone[props.student.student_id_number] !== undefined ? (
            <>
              {navigator.userAgent.indexOf("Android") != -1 ? (
                <CallNow data={studentPhone[props.student.student_id_number]}/>
              ) : navigator.userAgent.indexOf("like Mac") != -1 ? (
                <CallNow data={studentPhone[props.student.student_id_number]} />
              ) : (
                ""
              )}
              <StudentsPhoneInfo
                width={width}
                data={studentPhone[props.student.student_id_number]}
              />
              <StudentsLocationInfo data={studentPhone[props.student.student_id_number]} />
            </>
          ) : studentsPhoneLoading ? (
            <Box
              sx={{
                mt: 2,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ maxWidth: 20, maxHeight: 20 }} />
            </Box>
          ) : (
            ""
          )}

        </Grid2>
        <Grid2 xs={12} md={6} sx={{ px: 1, mt: { xs: 2, md: 0 } }}>
          <StudentsPersonalInfo width={width} info={props.student} />
          <StudentsObjectiveInfo width={width} info={props.student} />
        </Grid2>
      </Grid2>

      {(role == "Tutor" && waitingMissiaInStudentProfile[props.student.student_id_number] !==
        undefined && edit) ? (
        <Box
          sx={{
            position: "fixed",
            zIndex: 99999912,
            top: 0,
            left: 0,
            width: "calc(100% - 1px)",
            height: "calc(100vh - 1px)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            onClick={() => setEdit(false)}
            sx={{
              background: "#0000003f",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
            }}
          ></Box>
          <Paper
            elevation={2}
            sx={{
              position: "relative",
              width: "90%",
              maxWidth: "550px",
              height: "auto",
              zIndex: 1,
              borderRadius: 4,
              px: 3,
              py: 4,
              background: (theme) =>
                theme.palette.mode === "light" ? "#fff" : "#121212",
            }}
          >
            <IconButton
              onClick={() => setEdit(false)}
              sx={{ position: "absolute", top: -5, right: -5 }}
            >
              <CloseIcon />
            </IconButton>
            <Grid2 container component="form" onSubmit={handleSubmit(onSubmit)}>
              <Grid2 xs={12} sm={3}>
                <Box
                  onClick={() => setEdit(true)}
                  sx={{
                    mr: 1.5,
                    display: "flex",
                    width: "100%",
                    height: "120px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    border: (theme) =>
                      theme.palette.mode === "light"
                        ? "1px solid rgba(0, 0, 0, 0.38)"
                        : "1px solid rgba(255, 255, 255, 0.5)",
                    background: (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                    borderRadius: 3,
                    overflow: "hidden",
                    "& img": {
                      height: "100%",
                      width: "100%",
                      objectFit: { xs: "contain", sm: "cover" },
                    },
                  }}
                >
                  <img src={props.student.image} alt="" />
                </Box>
              </Grid2>
              <Grid2 xs={12} sm={9}>
                <Box
                  sx={{
                    pl: { sm: 1 },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    disabled={true}
                    placeholder={`${props.student.first_name.toLowerCase()} ${props.student.second_name.toLowerCase()}`}
                    variant="outlined"
                    value={`${props.student.first_name.toLowerCase()} ${props.student.second_name.toLowerCase()}`}
                    type="text"
                    sx={{
                      mt: { xs: 1, sm: 0 },
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: 3,
                        background: (theme) =>
                          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                        textTransform: "capitalize !important",
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: (theme) =>
                          theme.palette.mode === "light" ? "#000000de" : "#fff",
                        textTransform: "capitalize !important",
                      },
                    }}
                  />
                  {location == "" ? (
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={getLocation}
                      sx={{
                        width: "100%",
                        textTransform: "capitalize",
                        borderRadius: 3,
                        py: 2,
                      }}
                    >
                      Lokatsiya
                    </Button>
                  ) : (
                    <TextField
                      disabled={true}
                      placeholder={"Location"}
                      variant="outlined"
                      value={location.lat + "," + location.lng}
                      type="text"
                      sx={{
                        mt: { xs: 1, sm: 0 },
                        width: "100%",
                        "& .MuiInputBase-root": {
                          color: "text.primary",
                          borderRadius: 3,
                          background: (theme) =>
                            theme.palette.mode === "light"
                              ? "#fff"
                              : "#09090d3f",
                        },
                        "& .Mui-disabled": {
                          WebkitTextFillColor: (theme) =>
                            theme.palette.mode === "light"
                              ? "#000000de"
                              : "#fff",
                        },
                      }}
                    />
                  )}
                </Box>
              </Grid2>
              <Grid2
                xs={4}
                sm={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    fontFamily: "Ubuntu, sans-serif",
                    fontSize: 16,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    ml: 0,
                  }}
                >
                  Telefon:
                </Typography>
              </Grid2>
              <Grid2 xs={8} sm={9}>
                <Box
                  sx={{
                    pl: { sm: 1 },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    disabled={false}
                    placeholder={"Telefon raqam"}
                    variant="outlined"
                    {...register("tel1")}
                    type="text"
                    sx={{
                      my: 1,
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: 3,
                        background: (theme) =>
                          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                      },
                      "& .Mui-disabled": {
                        textTransform: "capitalize",
                        WebkitTextFillColor: (theme) =>
                          theme.palette.mode === "light" ? "#000000de" : "#fff",
                      },
                    }}
                  />
                </Box>
              </Grid2>
              <Grid2
                xs={4}
                sm={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    fontFamily: "Ubuntu, sans-serif",
                    fontSize: 16,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    ml: 0,
                  }}
                >
                  Telefon
                </Typography>
              </Grid2>
              <Grid2 xs={8} sm={9}>
                <Box
                  sx={{
                    pl: { sm: 1 },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    disabled={false}
                    placeholder={"Telefon raqam"}
                    variant="outlined"
                    {...register("tel2")}
                    type="text"
                    sx={{
                      mb: 1,
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: 3,
                        background: (theme) =>
                          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                      },
                      "& .Mui-disabled": {
                        textTransform: "capitalize",
                        WebkitTextFillColor: (theme) =>
                          theme.palette.mode === "light" ? "#000000de" : "#fff",
                      },
                    }}
                  />
                </Box>
              </Grid2>
              <Grid2
                xs={4}
                sm={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    fontFamily: "Ubuntu, sans-serif",
                    fontSize: 16,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    ml: 0,
                  }}
                >
                  Telegram:
                </Typography>
              </Grid2>
              <Grid2 xs={8} sm={9}>
                <Box
                  sx={{
                    pl: { sm: 1 },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    disabled={false}
                    placeholder={"@example"}
                    variant="outlined"
                    {...register("telegram")}
                    type="text"
                    sx={{
                      my: 1,
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: 3,
                        background: (theme) =>
                          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                      },
                      "& .Mui-disabled": {
                        textTransform: "capitalize",
                        WebkitTextFillColor: (theme) =>
                          theme.palette.mode === "light" ? "#000000de" : "#fff",
                      },
                    }}
                  />
                </Box>
              </Grid2>
              <Grid2 xs={12}>
                <LoadingButton
                  disableElevation
                  loading={false}
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{
                    borderRadius: 10,
                    float: "right",
                    my: 2,
                    textTransform: "capitalize",
                  }}
                >
                  Tasdiqlash
                </LoadingButton>
              </Grid2>
            </Grid2>
          </Paper>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}
