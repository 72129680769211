import { Box, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DocumentsData from './DocumentsData';
import {
	getAllDocument,
	getFilteredAllDocument,
	getFilteredAndPagenationAllDocument,
} from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import { FILES, FILTERED_FILES } from '../redux/action';

import fetching from '../lottie/fetching-loading.json';

export default function AllDocuments() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const catalogs = useSelector((state) => state.catalogs);
	const filteredFiles = useSelector((state) => state.filteredFiles);
	const files = useSelector((state) => state.files);
	const params = useParams();
	const [page, setPage] = React.useState(1);
	const [pageLength, setPageLength] = useState(0);

	useEffect(() => {
		if (params['*'] == '') {
			const request = async () => {
				const data = await getAllDocument('1');
				setPageLength(data.count / 24);
				dispatch({
					type: FILES,
					value: { params: 'page_1', data: data.results },
				});
			};
			request();
			setPage(1);
		} else if (
			Object.keys(params).length == 1 &&
			params.katalogName.includes('page_')
		) {
			let katalogName = params.katalogName;
			const request = async () => {
				const data = await getAllDocument(
					katalogName.replace(/\D/gi, '')
				);
				setPageLength(data.count / 24);
				dispatch({
					type: FILES,
					value: { params: katalogName, data: data.results },
				});
			};
			request();
			setPage(+katalogName.replace(/\D/gi, ''));
		} else if (
			Object.keys(params).length == 1 &&
			catalogs.filter((el) => el.name == params.katalogName).length == 1
		) {
			let katalogName = params.katalogName;
			const request = async () => {
				const data = await getFilteredAllDocument(params.katalogName);
				setPageLength(data.count / 24);
				dispatch({
					type: FILTERED_FILES,
					value: {
						params: { katalogName: katalogName, page: 'page_1' },
						data: data.results,
					},
				});
			};
			request();
			setPage(1);
		} else if (
			Object.keys(params).length == 2 &&
			catalogs.filter((el) => el.name == params.katalogName).length ==
				1 &&
			params.page.includes('page_')
		) {
			let katalogName = params.katalogName;
			let page = params.page;
			const request = async () => {
				const data = await getFilteredAndPagenationAllDocument(
					katalogName,
					page.replace(/\D/gi, '')
				);
				setPageLength(data.count / 24);
				dispatch({
					type: FILTERED_FILES,
					value: {
						params: { katalogName: katalogName, page: page },
						data: data.results,
					},
				});
			};
			request();
			setPage(page.replace(/\D/gi, ''));
		}
	}, [params, catalogs]);

	const handlePage = (e, value) => {
		if (Object.keys(params).length == 1 && params['*'] == '') {
			navigate(`/documents/page_${value}`);
		} else if (
			Object.keys(params).length == 1 &&
			catalogs.filter((el) => el.name == params.katalogName).length == 1
		) {
			navigate(`/documents/${params.katalogName}/page_${value}`);
		} else if (Object.keys(params).length == 2) {
			navigate(`/documents/${params.katalogName}/page_${value}`);
		} else if (Object.keys(params).length == 1) {
			navigate(`/documents/page_${value}`);
		}
	};

	return (
		<Box>
			{params['*'] == '' ||
			Object.keys(files).length > 0 ||
			(Object.keys(params).length == 1 &&
				params.katalogName.includes('page_')) ||
			(Object.keys(params).length == 1 &&
				catalogs.filter((el) => el.name == params.katalogName).length ==
					1) ||
			(Object.keys(params).length == 2 &&
				catalogs.filter((el) => el.name == params.katalogName).length ==
					1 &&
				params.page.includes('page_')) ? (
				<>
					{params['*'] == '' ? (
						!!files.page_1 ? (
							<DocumentsData files={files.page_1} />
						) : (
							<PageNotFound data={fetching} />
						)
					) : Object.keys(params).length == 1 &&
					  params.katalogName.includes('page_') ? (
						<DocumentsData
							files={
								files[`${params.katalogName}`] || files.page_1
							}
						/>
					) : Object.keys(params).length == 1 &&
					  catalogs.filter((el) => el.name == params.katalogName)
							.length == 1 ? (
						!!filteredFiles[params.katalogName] ? (
							<DocumentsData
								files={
									filteredFiles[params.katalogName]['page_1']
								}
							/>
						) : (
							<PageNotFound data={fetching} />
						)
					) : Object.keys(params).length == 2 &&
					  catalogs.filter((el) => el.name == params.katalogName)
							.length == 1 &&
					  params.page.includes('page_') ? (
						!!filteredFiles[params.katalogName] &&
						!!filteredFiles[params.katalogName][params.page] ? (
							<DocumentsData
								files={
									filteredFiles[params.katalogName][
										params.page
									]
								}
							/>
						) : (
							<PageNotFound data={fetching} />
						)
					) : (
						<PageNotFound />
					)}
					<Box
						sx={{
							pt: 5,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						{pageLength > 1 && (
							<Pagination
								count={Math.ceil(pageLength)}
								color='primary'
								siblingCount={1}
								page={+page}
								onChange={handlePage}
							/>
						)}
					</Box>
				</>
			) : (
				<PageNotFound data={fetching} />
			)}
		</Box>
	);
}
