import { Box, InputLabel, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MuiFileInput } from 'mui-file-input';
import { LoadingButton } from '@mui/lab';
import { setDocument } from '../api';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import lottie from '../lottie/uploading.json';

export default function UploadFile(props) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const [file, setFile] = useState([]);
	const [fileError, setFileError] = useState({ status: false, message: '' });
	const [selectCatalog, setSelectCatalog] = useState('');
	const [uploading, setUploading] = useState(false);
	const catalogs = useSelector((state) => state.catalogs);

	const handleFile = (newFile) => {
		setFile(newFile);
	};

	const onSubmit = async (data) => {
		if (!!file.name) {
			if (file.size < 20_000_000) {
				setUploading(true);
				setFileError({ status: false, message: '' });
				const request = await setDocument(
					data.name,
					data.catalog,
					file,
					data.comment
				);
				setFile([]);
				setUploading(false);
				reset();
			} else {
				setFileError({
					status: true,
					message: 'Fayl hajmi 20 MB dan oshmasligi kerak',
				});
			}
		} else {
			setFileError({
				status: true,
				message: 'Kerakli faylni tanlamadingiz!',
			});
		}
	};
	useEffect(() => {
		if (!!file.name) {
			if (file.size > 20000) {
				setFileError({
					status: true,
					message: 'Fayl hajmi 20 MB dan oshmasligi kerak',
				});
			}
		}
	}, [file]);

	const handleCatalog = (event) => {
		setSelectCatalog(event.target.value);
	};

	useEffect(() => {
		if (file !== null) {
			setFileError({ status: false, message: '' });
		}
	}, [file]);
	return (
		<Box>
			<Typography
				color='text.primary'
				sx={{
					fontFamily: 'Ubuntu, sans-serif',
					fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
					fontWeight: '700',
				}}>
				Fayl Yuklash
			</Typography>
			<Grid2 container>
				<Grid2 xs={12} md={8} lg={8}>
					<Typography
						sx={{ fontSize: 18, fontWeight: '500' }}
						color='text.secondary'>
						Sizga berilgan topshiriq(vazifa)larni pdf, word, excel
						ko'rinishida platformaga yuklang. Topshiriqlarni
						belgilangan vaqt ishida topshiring!
					</Typography>
				</Grid2>
			</Grid2>
			<Box sx={{ mt: { xs: 1, md: 4 }, mb: { xs: 2, md: 3 } }}>
				<Typography
					color='text.primary'
					sx={{
						fontSize: 22,
						fontWeight: '600',
						fontFamily: 'Ubuntu, sans-serif',
						opacity: 0.75,
					}}>
					Forma
				</Typography>
				<Box>
					<Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
						<Grid2 container>
							<Grid2 xs={12} md={6}>
								<Box sx={{ p: 0.5 }}>
									<Typography
										color='text.primary'
										sx={{
											m: 0.5,
											mt: 2,
											fontSize: 16,
											fontFamily: 'Ubuntu, sans-serif',
										}}>
										Faylni nomlang:
									</Typography>
									<TextField
										placeholder='Topshiriq nomi...'
										variant='outlined'
										type='text'
										{...register('name', {
											required: true,
											minLength: 4,
										})}
										sx={{
											width: '100%',
											'& .MuiInputBase-root': {
												borderRadius: 3,
												background: (theme) =>
													theme.palette.mode ===
													'light'
														? '#fff'
														: '#09090d3f',
											},
										}}
									/>
									{!!errors.name && (
										<Typography
											color='error.main'
											sx={{
												ml: 1,
												fontSize: 14,
												fontFamily:
													'Ubuntu, sans-serif',
											}}>
											Ma'lumot hajmi kamida 4 belgidan
											iborat bo'lishi kerak!
										</Typography>
									)}
								</Box>
								<Box sx={{ p: 0.5 }}>
									<Typography
										color='text.primary'
										sx={{
											m: 0.5,
											mt: 2,
											fontSize: 16,
											fontFamily: 'Ubuntu, sans-serif',
										}}>
										Katalogni tanlang:
									</Typography>
									<FormControl
										fullWidth
										sx={{
											'& .MuiInputBase-root': {
												borderRadius: 3,
												background: (theme) =>
													theme.palette.mode ===
													'light'
														? '#fff'
														: '#09090d3f',
											},
										}}>
										<InputLabel id='demo-simple-select-label'>
											Kataloglar
										</InputLabel>
										<Select
											{...register('catalog', {
												required: true,
											})}
											labelId='demo-simple-select-label'
											id='demo-simple-select'
											value={selectCatalog}
											label='Kataloglar'
											onChange={handleCatalog}
											sx={{
												fontFamily:
													'Ubuntu, sans-serif',
												fontSize: 16,
												color: 'text.primary',
											}}>
											{catalogs
												.filter(
													(el) =>
														el.is_deleted == false
												)
												.map((el) => {
													return (
														<MenuItem
															key={el.id}
															value={el.name}>
															{el.name}
														</MenuItem>
													);
												})}
										</Select>
									</FormControl>
									{!!errors.catalog && (
										<Typography
											color='error.main'
											sx={{
												ml: 1,
												fontSize: 14,
												fontFamily:
													'Ubuntu, sans-serif',
											}}>
											Kerakli katalogni tanlang!
										</Typography>
									)}
								</Box>

								<Box
									sx={{
										p: { xs: 0.5 },
										'& .MuiInputBase-root': {
											borderRadius: 3,
											fontFamily: 'Ubuntu, sans-serif',
											fontSize: 16,
											background: (theme) =>
												theme.palette.mode === 'light'
													? '#fff'
													: '#09090d3f',
											cursor: 'pointer',
										},
									}}>
									<Typography
										color='text.primary'
										sx={{
											m: 0.5,
											mt: 2,
											fontSize: 16,
											fontFamily: 'Ubuntu, sans-serif',
										}}>
										Kerakli faylni yuklang:
									</Typography>
									<FormControl fullWidth>
										<MuiFileInput
											placeholder='Bosing yoki surib tashlang'
											value={file || null}
											onChange={handleFile}
										/>
									</FormControl>
									{fileError.status && (
										<Typography
											color='error.main'
											sx={{
												ml: 1,
												fontSize: 14,
												fontFamily:
													'Ubuntu, sans-serif',
											}}>
											{fileError.message}
										</Typography>
									)}
								</Box>
							</Grid2>
							<Grid2 xs={12} md={6}>
								<Box
									sx={{
										p: { xs: 0.5 },
										position: 'relative',
										'& textarea': {
											p: 1,
											width: 'calc(100% - 16px)',
											border: '1px solid #0000003f',
											outline: '1px solid transparent',
											borderRadius: 3,
											resize: 'vertical',
											fontSize: 16,
											fontFamily: 'Ubuntu, sans-serif',
											transition: '0.3s all',
											background: (theme) =>
												theme.palette.mode === 'light'
													? '#fff'
													: '#09090d3f',
											color: 'text.primary',
											'&:hover': {
												outline: '1px solid #fff',
												border: 'none',
											},
											'&:focus': {
												outline: '2px solid #096bde',
												border: 'none',
											},
											'&:focus-visible': {
												outline: '2px solid #096bde',
												border: 'none',
											},

											'&::-webkit-resizer': {
												display: 'none',
											},
										},
									}}>
									<Typography
										color='text.primary'
										sx={{
											m: 0.5,
											mt: 2,
											fontSize: 16,
											fontFamily: 'Ubuntu, sans-serif',
										}}>
										Vazifaga izoh qoldiring:
									</Typography>
									<textarea
										placeholder='Izoh qoldirish...'
										rows={4}
										{...register('comment')}
									/>
								</Box>
								<Box
									sx={{
										width: '100%',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'end',
									}}>
									<LoadingButton
										variant='contained'
										type='submit'
										sx={{ mt: 2, borderRadius: 3 }}>
										Yuklash
									</LoadingButton>
								</Box>
							</Grid2>
						</Grid2>
					</Box>
				</Box>
			</Box>
			{uploading ? (
				<Box
					sx={{
						position: 'fixed',
						width: '100%',
						height: '100vh',
						top: 0,
						left: 0,
						zIndex: 99999932,
						display: 'fixed',
						alignItems: 'center',
						justifyContent: 'center',
						background: '#0000003f',
					}}>
					<Box sx={{ maxWidth: '400px' }}>
						<Lottie animationData={lottie} />
					</Box>
				</Box>
			) : (
				''
			)}
		</Box>
	);
}
