import {
  Box,
  Button,
  Fab,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useContext, useEffect, useState } from "react";
import GroupItem from "../components/GroupItem";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import DocumentsKatalog from "../components/DocumentKatalog";
import FileItem from "../components/FileItem";
import { useNavigate } from "react-router-dom";
import Context from "../Context";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export default function DocumentsData(props) {
  const { role } = useContext(Context);
  const navigate = useNavigate();
  const [sort, setSort] = useState("Sana");
  const [sortType, setSortType] = useState(false);
  const catalogs = useSelector((state) => state.catalogs);
  const files = useSelector((state) => state.files);

  const handleSortType = () => {
    setSortType(!sortType);
  };

  const handleSort = (event) => {
    setSort(event.target.value);
  };

  props.files != undefined &&
    props.files.sort((a, b) => {
      if (sort == "Sana") {
        if (sortType == true) {
          if (a.created_at < b.created_at) {
            return -1;
          }
        } else {
          if (a.created_at > b.created_at) {
            return -1;
          }
        }
      } else if (sort == "Nomi") {
        if (sortType == true) {
          if (a.name > b.name) {
            return -1;
          }
        } else {
          if (a.name < b.name) {
            return -1;
          }
        }
      }
    });

  return (
    <Box>
      <Typography
        color="text.primary"
        sx={{
          fontFamily: "Ubuntu, sans-serif",
          fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
          fontWeight: "700",
        }}
      >
        Hujjatlar
      </Typography>
      <Grid2 container>
        <Grid2 xs={12} md={8} lg={10}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "500" }}
            color="text.secondary"
          >
            Siz tomoningizdan platformaga joylashtirilgan hujjatlarning
            ro'yxati.
          </Typography>
        </Grid2>
        <Grid2
          xs={12}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <FormControl
            fullWidth
            size="small"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: { xs: "space-between", md: "end" },
              mt: { xs: 4, md: 0 },
            }}
          >
            <Box
              onClick={handleSortType}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 18, fontWeight: "600" }}
                color="text.secondary"
              >
                Saralash
              </Typography>
              <KeyboardArrowUpIcon
                sx={{
                  transition: "0.3s all linear",
                  color: "text.secondary",
                  transform:
                    sortType === false ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </Box>
            <Select
              value={sort}
              onChange={handleSort}
              sx={{
                width: "100px",
                color: "text.secondary",
                fontSize: 18,
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: "600",
                "& fieldset": {
                  border: "none !important",
                },
              }}
            >
              <MenuItem value={"Sana"} sx={{ color: "text.secondary" }}>
                Sana
              </MenuItem>
              <MenuItem value={"Nomi"} sx={{ color: "text.secondary" }}>
                Nomi
              </MenuItem>
            </Select>
          </FormControl>
        </Grid2>
      </Grid2>
      <Box
        sx={{
          mt: { xs: 1, md: 4 },
          mb: { xs: 2, md: 3 },
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          color="text.primary"
          sx={{
            fontSize: 22,
            fontWeight: "600",
            fontFamily: "Ubuntu, sans-serif",
            opacity: 0.75,
          }}
        >
          Kataloglar
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {role == "Manaviyat" && (
            <Fab
              onClick={() => navigate("/documents/add_catalog")}
              variant="extended"
              color="primary"
              sx={{
                borderRadius: 10,
                px: 3,
                height: 40,
                boxShadow: "none",
                textTransform: "capitalize",
                fontFamily: "Ubuntu, sans-serif",
                "&:active": {
                  boxShadow: "none",
                },
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Katalog yaratish
            </Fab>
          )}
        </Box>
      </Box>
      <Grid2 container>
        {!!catalogs.length ? (
          catalogs.map((el) => {
            return (
              <DocumentsKatalog
                key={el.id}
                name={el.name}
                length={el.files_number}
                size={el.files_size}
              />
            );
          })
        ) : (
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <WarningAmberIcon color="warning" sx={{ mr: 1 }} />
            <Typography
              sx={{ fontSize: 18, fontWeight: "500" }}
              color="warning.main"
            >
              Kataloglar mavjud emas!
            </Typography>
          </Box>
        )}
      </Grid2>
      <Grid2 container>
        <Grid2 xs={12}>
          <Box
            sx={{
              mt: { xs: 1, md: 4 },
              mb: { xs: 2, md: 3 },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              color="text.primary"
              sx={{
                fontSize: 22,
                fontWeight: "600",
                fontFamily: "Ubuntu, sans-serif",
                opacity: 0.75,
              }}
            >
              Fayllar
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {role == "Tutor" && (
                <Fab
                  onClick={() => navigate("/documents/upload_file")}
                  variant="extended"
                  color="primary"
                  sx={{
                    borderRadius: 10,
                    px: 3,
                    height: 40,
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontFamily: "Ubuntu, sans-serif",
                    "&:active": {
                      boxShadow: "none",
                    },
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  Topshiriq Yuklash
                </Fab>
              )}
            </Box>
          </Box>
        </Grid2>
        {!!props.files && !!props.files.length ? (
          props.files.map((el, index) => {
            return (
              <FileItem
                tutor={el.user.hemis_id}
                date={el.created_at}
                name={el.name}
                folder={el.folder.name}
                file={el.files}
                id={el.id}
                comment={el.description}
                key={index}
              />
            );
          })
        ) : (
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <WarningAmberIcon color="warning" sx={{ mr: 1 }} />
            <Typography
              sx={{ fontSize: 18, fontWeight: "500" }}
              color="warning.main"
            >
              Fayllar mavjud emas!
            </Typography>
          </Box>
        )}
      </Grid2>
    </Box>
  );
}
