import React, { useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { DataGrid } from "@mui/x-data-grid";
import SuccessDataTable from "../components/SuccessDataTable";
import WaitingDataTable from "../components/WaitingDataTable";

export default function Topshiriqlar() {
  const [openSecondScreen, setOpenSecondScreen] = useState(false);

  const handleOpenSecondScreen = () => {
    setOpenSecondScreen(true);
  };
  const handleOpenFirstScreen = () => {
    setOpenSecondScreen(false);
  };

  const rows = [
    { id: 1, studentName: "Snow", tutorName: "Jon", age: 35 },
    { id: 2, studentName: "Lannister", tutorName: "Cersei", age: 42 },
    { id: 3, studentName: "Lannister", tutorName: "Jaime", age: 45 },
    { id: 4, studentName: "Stark", tutorName: "Arya", age: 16 },
    { id: 5, studentName: "Targaryen", tutorName: "Daenerys", age: null },
    { id: 6, studentName: "Melisandre", tutorName: null, age: 150 },
    { id: 7, studentName: "Clifford", tutorName: "Ferrara", age: 44 },
    { id: 8, studentName: "Frances", tutorName: "Rossini", age: 36 },
    { id: 9, studentName: "Roxie", tutorName: "Harvey", age: 65 },
  ];
  return (
    <Box>
      <Typography
        color="text.primary"
        sx={{
          fontFamily: "Ubuntu, sans-serif",
          fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
          fontWeight: "700",
        }}
      >
        Topshiriqlar
      </Typography>
      <Grid2 container>
        <Grid2 xs={12} md={8} lg={10}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "500" }}
            color="text.secondary"
          >
            Tyutorga biriktirilgan ushbu talabalarning ahvolini va yashash
            sharoitlarini o'rganish, manzillarini platformaga joylashtirish
            maqsadida tyutorlarga yuborilgan topshiriqlar ro'yxati.
          </Typography>
        </Grid2>
        <Grid2
          xs={12}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        ></Grid2>
      </Grid2>
      <Box
        sx={{
          mt: { xs: 1, md: 4 },
          mb: { xs: 2, md: 3 },
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              border: (theme) =>
                theme.palette.mode === "light"
                  ? "1px solid #0000002f"
                  : "1px solid #ffffff2f",
              borderRadius: 3,
              p: 0.5,
              "& button": {
                borderRadius: 3,
                textTransform: "capitalize",
                fontSize: 16,
                fontFamily: "Ubuntu, sans-serif",
                color: "text.primary",
              },
            }}
          >
            <Button
              variant="container"
              disableElevation
              color="light"
              onClick={handleOpenFirstScreen}
              sx={{
                background: (theme) =>
                  theme.palette.mode === "light"
                    ? !openSecondScreen && "#096bde3f !important"
                    : !openSecondScreen && "#09090d !important",
              }}
            >
              Bajarilganlar
            </Button>
            <Button
              variant="container"
              disableElevation
              color="light"
              onClick={handleOpenSecondScreen}
              sx={{
                background: (theme) =>
                  theme.palette.mode === "light"
                    ? openSecondScreen && "#096bde3f !important"
                    : openSecondScreen && "#09090d !important",
              }}
            >
              Bajarilmaganlar
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          overflowX: "hidden",
        }}
      >
        <SuccessDataTable screen={openSecondScreen} />
        <WaitingDataTable screen={openSecondScreen} />
      </Box>
    </Box>
  );
}
