import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Typography,
  createSvgIcon,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StudentsItem from "../components/StudentsItem";

const GridIcon = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <rect width="10" height="10" rx="4" fill="currentColor" />
    <rect x="12" y="12" width="10" height="10" rx="4" fill="currentColor" />
    <rect y="12" width="10" height="10" rx="4" fill="currentColor" />
    <rect x="12" width="10" height="10" rx="4" fill="currentColor" />
  </svg>
);

const ListIcon = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <rect width="22" height="6" rx="4" fill="currentColor" />
    <rect y="8" width="22" height="6" rx="4" fill="currentColor" />
    <rect y="16" width="22" height="6" rx="4" fill="currentColor" />
  </svg>
);
const HomeIcon = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.57112 6.40501C6.55651e-08 7.44841 0 8.70704 0 11.2243V12.8975C0 17.1884 1.3113e-07 19.3339 1.28873 20.6669C2.57747 22 4.65164 22 8.8 22H13.2C17.3483 22 19.4226 22 20.7112 20.6669C22 19.3339 22 17.1884 22 12.8975V11.2243C22 8.70704 22 7.44841 21.4289 6.40501C20.8578 5.36163 19.8144 4.71406 17.7276 3.41893L15.5276 2.05356C13.3217 0.684519 12.2187 0 11 0C9.78131 0 8.67833 0.684519 6.47243 2.05356L4.27243 3.41894C2.18564 4.71406 1.14224 5.36163 0.57112 6.40501ZM7.7 16.775C7.24437 16.775 6.875 17.1444 6.875 17.6C6.875 18.0556 7.24437 18.425 7.7 18.425H14.3C14.7556 18.425 15.125 18.0556 15.125 17.6C15.125 17.1444 14.7556 16.775 14.3 16.775H7.7Z"
      fill="currentColor"
    />
  </svg>,
  "HomeIcon"
);

const ChairIcon = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      d="M15.9865 17.9302H5.37497C5.30682 17.9302 5.27275 17.9302 5.24185 17.9298C5.23624 17.9297 5.23062 17.9297 5.22501 17.9296V19.046V20.1628C5.22501 20.6253 4.85564 21 4.40001 21C3.94437 21 3.575 20.6253 3.575 20.1628V17.5681C2.66334 17.1642 1.93681 16.4469 1.58617 15.5407C1.57595 15.5143 1.56517 15.485 1.54363 15.4264L0.110311 11.5276C-0.346838 10.2842 0.673951 9 2.11958 9C3.01092 9 3.80687 9.50601 4.11069 10.2657L5.31863 13.2865C5.42856 13.5615 5.48353 13.6989 5.56319 13.8113C5.74922 14.0737 6.04139 14.2595 6.37688 14.3286C6.52055 14.3581 6.68181 14.3581 7.00433 14.3581H14.6814C15.3074 14.3581 15.6205 14.3581 15.8821 14.2486C16.0251 14.1889 16.1551 14.1063 16.2657 14.0048C16.4684 13.819 16.5751 13.5522 16.7885 13.0186L17.8893 10.2657C18.1931 9.50601 18.9891 9 19.8804 9C21.326 9 22.3468 10.2842 21.8897 11.5276L20.6583 14.8771C20.4437 15.4609 20.3364 15.7528 20.1947 16.001C19.8009 16.6903 19.1761 17.2355 18.425 17.568V20.1628C18.425 20.6253 18.0556 21 17.6 21C17.1444 21 16.775 20.6253 16.775 20.1628V17.9251C16.5696 17.9302 16.3187 17.9302 15.9865 17.9302Z"
      fill="currentColor"
    />
    <path
      d="M12.5209 1H9.47914C8.12396 0.999989 7.04721 0.999977 6.20375 1.10221C5.33643 1.20734 4.62308 1.42957 4.05615 1.94066C3.48498 2.45558 3.23276 3.10993 3.11419 3.90504C2.99998 4.67092 2.99999 5.64632 3 6.86336V7.88869L3.25905 8.06285C3.77062 8.40677 4.18394 8.87654 4.43228 9.45581L5.95157 12.9999L15.1191 13C15.2967 13 15.4407 13 15.5663 12.9984L15.5689 12.9983L16.0462 12.9902L16.2016 12.6402C16.2402 12.5531 16.2834 12.4524 16.3364 12.3289L17.568 9.45581C17.8164 8.87654 18.2295 8.40694 18.741 8.06303L19 7.88886V6.8634C19 5.64634 19 4.67093 18.8858 3.90504C18.7673 3.10993 18.515 2.45558 17.9439 1.94066C17.377 1.42957 16.6636 1.20734 15.7963 1.10221C14.9527 0.999977 13.8761 0.999989 12.5209 1Z"
      fill="currentColor"
    />
  </svg>,
  "ChairIcon"
);
const LocationIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 24C18.6274 24 24 21.3933 24 18.1776C24 16.5388 22.6044 15.058 20.3585 14C18.988 16.7117 16.898 19.049 14.2434 20.2723C12.8165 20.9298 11.1835 20.9298 9.7566 20.2723C7.10194 19.049 5.01199 16.7117 3.6415 14C1.39554 15.058 0 16.5388 0 18.1776C0 21.3933 5.37258 24 12 24Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8.1433C3 3.64588 7.02944 0 12 0C16.9706 0 21 3.64588 21 8.1433C21 12.6055 18.1275 17.8124 13.6458 19.6745C12.6011 20.1085 11.3989 20.1085 10.3542 19.6745C5.87249 17.8124 3 12.6055 3 8.1433ZM12 11.25C13.4202 11.25 14.5714 10.1308 14.5714 8.75C14.5714 7.36929 13.4202 6.25 12 6.25C10.5798 6.25 9.42857 7.36929 9.42857 8.75C9.42857 10.1308 10.5798 11.25 12 11.25Z"
      fill="currentColor"
    />
  </svg>,
  "LocationIcon"
);

export default function GroupData(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const [view, setView] = useState(window.innerWidth < 360 ? "list" : "grid");
  const [homeIconButton, setHomeIconButton] = useState(true);
  const [chairIconButton, setChairIconButton] = useState(true);
  const [locationIconButton, setLocationIconButton] = useState(true);
  const [students, setStudents] = useState([]);


  const handleHomeIcon = () => {
    if (!chairIconButton && !locationIconButton && homeIconButton) {
      setHomeIconButton(false);
      setChairIconButton(true);
      setLocationIconButton(true);
    } else {
      setHomeIconButton(!homeIconButton);
    }
  };
  const handleChairIcon = () => {
    if (chairIconButton && !locationIconButton && !homeIconButton) {
      setHomeIconButton(true);
      setChairIconButton(false);
      setLocationIconButton(true);
    } else {
      setChairIconButton(!chairIconButton);
    }
  };
  const handleLocationIcon = () => {
    if (
      chairIconButton == false &&
      locationIconButton == true &&
      homeIconButton == false
    ) {
      setHomeIconButton(true);
      setChairIconButton(true);
      setLocationIconButton(false);
    } else {
      setLocationIconButton(!locationIconButton);
    }
  };

  useEffect(() => {
    if (homeIconButton && !chairIconButton && !locationIconButton) {
      setStudents(
        props.student.filter((el) => el.accommodation.name == "O‘z uyida")
      );
    } else if (!homeIconButton && !chairIconButton && locationIconButton) {
      setStudents(
        props.student.filter((el) => el.accommodation.name == "Ijaradagi uyda")
      );
    } else if (!homeIconButton && chairIconButton && !locationIconButton) {
      setStudents(
        props.student.filter(
          (el) => el.accommodation.name == "Talabalar turar joyida"
        )
      );
    } else if (homeIconButton && chairIconButton && !locationIconButton) {
      setStudents(
        props.student.filter(
          (el) =>
            el.accommodation.name == "O‘z uyida" ||
            el.accommodation.name == "Talabalar turar joyida"
        )
      );
    } else if (homeIconButton && !chairIconButton && locationIconButton) {
      setStudents(
        props.student.filter(
          (el) =>
            el.accommodation.name == "O‘z uyida" ||
            el.accommodation.name == "Ijaradagi uyda"
        )
      );
    } else if (!homeIconButton && chairIconButton && locationIconButton) {
      setStudents(
        props.student.filter(
          (el) =>
            el.accommodation.name == "Talabalar turar joyida" ||
            el.accommodation.name == "Ijaradagi uyda"
        )
      );
    } else if (homeIconButton && chairIconButton && locationIconButton) {
      setStudents(
        props.student.filter(
          (el) =>
            el.accommodation.name == "Talabalar turar joyida" ||
            el.accommodation.name == "Ijaradagi uyda" ||
            el.accommodation.name == "O‘z uyida"
        )
      );
    } else {
      setStudents(
        props.student.filter(
          (el) =>
            el.accommodation.name == "Talabalar turar joyida" ||
            el.accommodation.name == "Ijaradagi uyda" ||
            el.accommodation.name == "O‘z uyida"
        )
      );
    }
  }, [homeIconButton, chairIconButton, locationIconButton]);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
    window.innerWidth < 360 && setView("list");
  });

  const handleSortType = () => {
    props.setSortType(!props.sortType);
  };

  const handleSort = (event) => {
    props.setSort(event.target.value);
  };

  useEffect(() => {
    width < 360 && setView("list");
  }, [width]);


  students.sort((a, b) => {
    if (props.sort == "firstName") {
      if (props.sortType) {
        if (a.first_name < b.first_name) {
          return -1;
        }
      } else {
        if (a.first_name > b.first_name) {
          return -1;
        }
      }
    } else if (props.sort == "secondName") {
      if (props.sortType) {
        if (a.second_name < b.second_name) {
          return -1;
        }
      } else {
        if (a.second_name > b.second_name) {
          return -1;
        }
      }
    }
  });
  console.log(students);


  return (
    <Box>
      <Typography
        color="text.primary"
        sx={{
          fontFamily: "Ubuntu, sans-serif",
          fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
          fontWeight: "700",
        }}
      >
        Students
      </Typography>
      <Grid2 container>
        <Grid2 xs={12} md={8} lg={10}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "500" }}
            color="text.secondary"
          >
            Tyutorga biriktirilgan ushbu guruhga tegishli bo'lgan talabalarning
            ro'yxati.
          </Typography>
        </Grid2>
        <Grid2
          xs={12}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <FormControl
            fullWidth
            size="small"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mt: { xs: 4, md: 0 },
              minWidth: { xs: "100%", md: 230 },
            }}
          >
            <Box
              onClick={handleSortType}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 18, fontWeight: "600" }}
                color="text.secondary"
              >
                Saralash
              </Typography>
              <KeyboardArrowUpIcon
                sx={{
                  transition: "0.3s all linear",
                  color: "text.secondary",
                  transform:
                    props.sortType ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </Box>
            <Select
              value={props.sort}
              onChange={handleSort}
              sx={{
                width: "140px",
                color: "text.secondary",
                fontSize: 18,
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: "600",
                "& fieldset": {
                  border: "none !important",
                },
              }}
            >
              <MenuItem value={"firstName"} sx={{ color: "text.secondary" }}>
                Ismi
              </MenuItem>
              <MenuItem value={"secondName"} sx={{ color: "text.secondary" }}>
                familiyasi
              </MenuItem>
            </Select>
          </FormControl>
        </Grid2>
      </Grid2>
      <Box
        sx={{
          mt: { xs: 1, md: 4 },
          mb: { xs: 2, md: 3 },
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Paper
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              py: 0.5,
              borderRadius: 3,
            }}
          >
            <HomeIcon
              onClick={handleHomeIcon}
              sx={{
                color: homeIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#fff"
                  : "text.secondary",
                background: homeIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#1976d2" : "#121212"
                  : "text.secondary",
                p: 1,
                borderRadius: 3,
                mr: 1,
                ml: 1,
                display: width < 360 && "none",
                transition: "0.3s all linear",
                "&:hover": {
                  color: !homeIconButton
                    ? (theme) =>
                        theme.palette.mode === "light" ? "#1976d2" : "#fff"
                    : (theme) =>
                        theme.palette.mode === "light" ? "#fff" : "#fff",
                },
              }}
            />
            <ChairIcon
              onClick={handleChairIcon}
              sx={{
                color: chairIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#fff"
                  : "text.secondary",
                background: chairIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#1976d2" : "#121212"
                  : "text.secondary",
                p: 1,
                borderRadius: 3,
                mr: 1,
                display: width < 360 && "none",
                transition: "0.3s all linear",
                "&:hover": {
                  color: !chairIconButton
                    ? (theme) =>
                        theme.palette.mode === "light" ? "#1976d2" : "#fff"
                    : (theme) =>
                        theme.palette.mode === "light" ? "#fff" : "#fff",
                },
              }}
            />
            <LocationIcon
              onClick={handleLocationIcon}
              sx={{
                color: locationIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#fff"
                  : "text.secondary",
                background: locationIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#1976d2" : "#121212"
                  : "text.secondary",
                p: 1,
                borderRadius: 3,
                mr: 1,
                display: width < 360 && "none",
                transition: "0.3s all linear",
                "&:hover": {
                  color: !locationIconButton
                    ? (theme) =>
                        theme.palette.mode === "light" ? "#1976d2" : "#fff"
                    : (theme) =>
                        theme.palette.mode === "light" ? "#fff" : "#fff",
                },
              }}
            />
          </Paper>
        </Box>

        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <GridIcon
            onClick={() => (width > 360 ? setView("grid") : setView("list"))}
            sx={{
              color:
                view === "grid"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#fff"
                  : "text.secondary",
              background:
                view === "grid"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#1976d2" : "#121212"
                  : "none",
              p: 1,
              borderRadius: 3,
              mr: 1,
              display: width < 360 && "none",
              transition: "0.3s all linear",
              "&:hover": {
                color:
                  view === "list"
                    ? (theme) =>
                        theme.palette.mode === "light" ? "#1976d2" : "#fff"
                    : "#fff",
              },
            }}
          />
          <ListIcon
            onClick={() => setView("list")}
            sx={{
              color:
                view === "list"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#fff"
                  : "text.secondary",
              background:
                view === "list"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#1976d2" : "#121212"
                  : "none",
              p: 1,
              borderRadius: 3,
              transition: "0.3s all linear",
              "&:hover": {
                color:
                  view === "grid"
                    ? (theme) =>
                        theme.palette.mode === "light" ? "#1976d2" : "#fff"
                    : "#fff",
              },
            }}
          />
        </Box>
      </Box>
      <Grid2 container>
        {!!students.length &&
          students.map((el) => {
            return (
              <StudentsItem
                key={el.id}
                id={el.id}
                view={view}
                firstName={el.first_name}
                lastName={el.second_name}
                img={el.image}
                educationForm={el.educationForm.name}
                groupName={el.paymentForm.name}
                groupLang={el.group.educationLang.name}
                sort={props.sort}
                sortType={props.sortType}
              />
            );
          })}
      </Grid2>
    </Box>
  );
}
