import { Avatar, Box, Typography, createSvgIcon } from "@mui/material";
import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useSelector } from "react-redux";
const LocationIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 24C18.6274 24 24 21.3933 24 18.1776C24 16.5388 22.6044 15.058 20.3585 14C18.988 16.7117 16.898 19.049 14.2434 20.2723C12.8165 20.9298 11.1835 20.9298 9.7566 20.2723C7.10194 19.049 5.01199 16.7117 3.6415 14C1.39554 15.058 0 16.5388 0 18.1776C0 21.3933 5.37258 24 12 24Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8.1433C3 3.64588 7.02944 0 12 0C16.9706 0 21 3.64588 21 8.1433C21 12.6055 18.1275 17.8124 13.6458 19.6745C12.6011 20.1085 11.3989 20.1085 10.3542 19.6745C5.87249 17.8124 3 12.6055 3 8.1433ZM12 11.25C13.4202 11.25 14.5714 10.1308 14.5714 8.75C14.5714 7.36929 13.4202 6.25 12 6.25C10.5798 6.25 9.42857 7.36929 9.42857 8.75C9.42857 10.1308 10.5798 11.25 12 11.25Z"
      fill="currentColor"
    />
  </svg>,
  "LocationIcon"
);

export default function SelectStudentsItem({ data, selectStudents, students }) {
  const successStudentWithId = useSelector(
    (state) => state.successStudentWithId
  );
  let active =
    students.filter((el) => el.id == data.student_id_number).length == 1;
  return (
    <Box
      onClick={() =>
        selectStudents({
          name: `${data.first_name.toLowerCase()} ${data.second_name.toLowerCase()}`,
          image: data.image,
          id: data.student_id_number,
        })
      }
      sx={{
        borderRadius: 3,
        background: (theme) =>
          theme.palette.mode === "light"
            ? active
              ? "#096bde3f"
              : "#fff"
            : active
            ? "#09090d"
            : "#09090d3f",
        border: (theme) =>
          theme.palette.mode === "light"
            ? "1px solid #0000002f"
            : "1px solid #ffffff2f",
        my: 0.5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        px: 1,
        py: 1,
        cursor: "pointer",
      }}
    >
      {Object.keys(successStudentWithId).includes(data.student_id_number) ? (
        <Box
          sx={{
            minWidth: 8,
            minHeight: 8,
            borderRadius: 3,
            background: "green",
            mr: 1,
          }}
        ></Box>
      ) : (
        ""
      )}
      <Avatar
        sx={{ width: 24, height: 24, mr: 1 }}
        alt={data.full_name}
        src={data.image}
      />
      <Typography
        color="text.primary"
        sx={{
          width: "100%",
          fontSize: 16,
          fontWeight: 400,
          fontFamily: "Ubuntu, sans-serif",
          textTransform: "capitalize !important",
        }}
      >
        {`${data.first_name.toLowerCase()} ${data.second_name.toLowerCase()}`}
      </Typography>
      {data.accommodation.name == "O‘z uyida" ? (
        <HomeIcon sx={{ color: "text.primary" }} />
      ) : data.accommodation.name == "Talabalar turar joyida" ? (
        <ApartmentIcon sx={{ color: "text.primary" }} />
      ) : (
        <LocationIcon sx={{ color: "text.primary" }} />
      )}
    </Box>
  );
}
