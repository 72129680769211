import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { getStudent } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { STUDENT_WITH_ID } from "../redux/action";
import { useNavigate } from "react-router-dom";

export default function TutorWaitingDataTableRow({ data, index }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const waitingStudentWithId = useSelector(state => state.waitingStudentWithId)
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loading, setLoading] = useState(true);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteClose = () => {
    console.log("delete");
  };

  const navigatsiya = () => {
    if (waitingStudentWithId[data.student_hemis_id]["id"] !== undefined) {
      navigate(
        `/students/student_${waitingStudentWithId[data.student_hemis_id]["id"]}`
      );
    }
  };

  return (
    <TableRow onClick={() => navigatsiya()} sx={{ cursor: "pointer" }}>
      <TableCell
        sx={{
          textTransform: "capitalize",
          fontFamily: "Ubuntu, sans-serif",
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        {index + 1}
      </TableCell>
      <TableCell
        sx={{
          textTransform: "capitalize",
          fontFamily: "Ubuntu, sans-serif",
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {waitingStudentWithId[`${data.student_hemis_id}`] == undefined ? (
            <CircularProgress sx={{ maxWidth: 20, maxHeight: 20 }} />
          ) : (
            `${waitingStudentWithId[
              data.student_hemis_id
            ].first_name.toLowerCase()} ${waitingStudentWithId[
              data.student_hemis_id
            ].second_name.toLowerCase()}`
          )}
        </Box>
      </TableCell>
      <TableCell
        sx={{
          textTransform: "capitalize",
          fontFamily: "Ubuntu, sans-serif",
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        {data.tutor_fullname}
      </TableCell>
      <TableCell
        sx={{ fontFamily: "Ubuntu, sans-serif", fontWeight: 400, fontSize: 16 }}
      >
        {data.group}
      </TableCell>
      <TableCell>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}
        >
          <Typography
            sx={{
              fontFamily: "Ubuntu, sans-serif",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            {`${data.created_at.slice(0, 10).replaceAll("-", "/")} `}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Ubuntu, sans-serif",
              fontWeight: 400,
              fontSize: 14,
              ml: 1,
            }}
          >
            {`${data.created_at.slice(11, 16)}`}
          </Typography>
        </Box>
      </TableCell>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Xabarni o'chirishni tasdiqlaysizmi?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Siz tomoningizda yuborilgan xabarni o'chirish uchun <b>O'CHIRISH</b>{" "}
            tugmasini bosing!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontFamily: "Ubuntu, sans-serif" }}
            onClick={handleDialogClose}
          >
            Bekor qilish
          </Button>
          <Button
            sx={{
              fontFamily: "Ubuntu, sans-serif",
              color: "#ff0e0e !important",
            }}
            onClick={handleDeleteClose}
            autoFocus
          >
            O'chirish
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}
