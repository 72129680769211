import { Box } from "@mui/material";
import AllRoutes from "./Routes/AllRoutes";

function App() {
  return (
    <Box>
      <AllRoutes />
    </Box>
  );
}

export default App;
