import React, { useState } from "react";
import { Avatar, Box, IconButton, Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import PhoneIcon from "@mui/icons-material/Phone";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Route, Routes, useNavigate } from "react-router-dom";
const color = [
  "#bd5844",
  "#ff5722",
  "#673ab7",
  "#fde79d",
  "#1f6cfa",
  "#085c25",
  "#4caf50",
  "#e91e63",
];

export default function StudentsItem(props) {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => setWidth(window.innerWidth));
  return (
    <Grid2
      xs={props.view === "list" ? 12 : 6}
      sm={props.view === "list" ? 12 : 6}
      md={props.view === "list" ? width > 1100 ? 6 : 12 : 6}
      lg={props.view === "list" ? width > 1100 ? 6 : 12 : 4}
      sx={{ p: { xs: 0.5, ms: 2 }, position: "relative" }}
    >
      <Paper
        onClick={() => navigate(`/students/student_${props.id}`)}
        sx={{
          borderRadius: 4,
          px: 2,
          py: 3,
          position: "relative",
          overflow: "hidden",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: props.view === "grid" ? "column" : "row",
            }}
          >
            {props.view === "grid" && (
              <Typography
                sx={{
                  fontSize: { xs: 13, sm: 14 },
                  fontWeight: "500",
                  fontFamily: "Ubuntu, sans-serif",
                  background: "#1976d2",
                  color: "#fff",
                  backgroundSize: "2px 100%",
                  borderRadius: 4,
                  px: 0.8,
                  position: "absolute",
                  zIndex: 1,
                  top: 8,
                  left: 8,
                }}
              >
                {props.educationForm}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: props.view === "grid" ? "center" : "start",
                position: "relative",
                py: { xs: 1 },
              }}
            >
              <Avatar
                sx={{
                  display: "flex",
                  background: color[Math.round(Math.random() * 6)],
                  width: { xs: 80 },
                  height: { xs: 80 },
                  ml: { xs: props.view === "grid" ? -2 : 0, sm: 0 },
                }}
                alt="Travis Howard"
                src={props.img}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: props.view === "grid" ? "center" : "flex-start",
                justifyContent: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: props.view === "list" ? "column" : "column",
                    sm: props.view === "list" ? "row" : "row",
                  },
                  p: { xs: props.view === "list" ? 1 : 0 },
                  alignItems: {
                    xs: props.view === "list" ? "start" : "center",
                  },
                  justifyContent: { sm: "center" },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    color="text.primary"
                    sx={{
                      fontSize: {
                        xs: 15,
                        sm:
                          width < 700
                            ? 16
                            : width < 750
                            ? 17
                            : width < 800
                            ? 17
                            : 17,
                        md: 17,
                      },
                      fontWeight: "500",
                      fontFamily: "Ubuntu, sans-serif",
                      mr: { xs: props.view === "list" ? 0.5 : 0, sm: 0.5 },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textTransform: "capitalize"
                    }}
                  >
                    {
                      props.sort == 'firstName' ? 
                        props.firstName.toLowerCase() 
                        : 
                        props.sort == 'secondName' ? props.lastName.toLowerCase() : ''
                    }
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    color="text.primary"
                    sx={{
                      fontSize: {
                        xs: 15,
                        sm:
                          width < 700
                            ? 16
                            : width < 750
                            ? 17
                            : width < 800
                            ? 17
                            : 17,
                        md: 17,
                      },
                      fontWeight: "500",
                      fontFamily: "Ubuntu, sans-serif",
                      textTransform: "capitalize"
                    }}
                  >
                    {
                      props.sort == 'firstName' ? 
                        props.lastName.toLowerCase() 
                        : 
                        props.sort == 'secondName' ? props.firstName.toLowerCase() : ''
                    }
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  px: { xs: props.view === "grid" ? 0 : 1 },
                  display: "flex",
                  flexDirection: "row",
                  justifyContent:
                    props.view === "grid" ? "center" : "start",
                  mt: { xs: props.view === "grid" ? 1 : 0, sm: 0 },
                  width: { xs: "100%" },
                  maxWidth: { xs: width < 500 ? "100%" : 160, sm: 220 },
                  overflow: "hidden",
                  mx: props.view === "grid" ? "auto" : 0,
                }}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    fontSize: { xs: props.view === "grid" ? 12 : 14, sm: 14 },
                    fontWeight: "500",
                    fontFamily: "Ubuntu, sans-serif",
                  }}
                >
                  {props.groupName}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 3,
                      height: 3,
                      borderRadius: 5,
                      mx: width < 376 ? "1px" : "5px",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? "rgba(0, 0, 0, 0.54)"
                          : "rgba(255, 255, 255, 0.7)",
                    }}
                  ></Box>
                </Box>
                <Typography
                  color="text.secondary"
                  sx={{
                    fontSize: { xs: props.view === "grid" ? 12 : 14, sm: 14 },
                    fontWeight: "500",
                    fontFamily: "Ubuntu, sans-serif",
                  }}
                >
                  {props.groupLang}
                </Typography>
              </Box>
              {props.view === "list" && (
                <Box
                  sx={{
                    px: { xs: props.view === "grid" ? 0 : 1 },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mt: props.view === "list" ? 1 : 0,
                    width: { xs: "100%" },
                    maxWidth: { xs: 150 },
                    mx: props.view === "grid" ? "auto" : "0",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: "600",
                      fontFamily: "Ubuntu, sans-serif",
                      background: "#1976d2",
                      color: "#fff",
                      backgroundSize: "2px 100%",
                      borderRadius: 4,
                      px: 0.8,
                      display: "inline-flex",
                    }}
                  >
                    {props.educationForm}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      {props.view === "list" && (
        <>
          <IconButton
            component="a"
            href="tel:+998997565684"
            target="_blank"
            sx={{
              position: "absolute",
              zIndex: 1,
              top: 20,
              right: 20,
            }}
          >
            <PhoneIcon sx={{ color: "green" }} />
          </IconButton>
          <IconButton
            component="a"
            href="https://t.me/ravshan_jumaqulov"
            target="_blank"
            sx={{
              position: "absolute",
              zIndex: 1,
              bottom: 20,
              right: 20,
              display: "flex",
            }}
          >
            <TelegramIcon sx={{ color: "blue" }} />
          </IconButton>
        </>
      )}
      {props.view === "grid" && (
        <>
          <IconButton
            component="a"
            href="tel:+998997565684"
            target="_blank"
            sx={{ position: "absolute", right: 20, top: 20, zIndex: 1 }}
            size="small"
          >
            <PhoneIcon sx={{ color: "green" }} />
          </IconButton>
          <IconButton
            component="a"
            href="https://t.me/ravshan_jumaqulov"
            target="_blank"
            sx={{ position: "absolute", right: 20, top: 70, zIndex: 1 }}
            size="small"
          >
            <TelegramIcon sx={{ color: "#0088cc" }} />
          </IconButton>
        </>
      )}
    </Grid2>
  );
}
