import { Box } from "@mui/material";
import Lottie from "lottie-react";
import React, { useRef } from "react";
import loading from "../lottie/loading.json";

export default function Loading() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        background: "#fff",
        zIndex: 5,
      }}
    >
      <Box>
        <Box sx={{ width: 300 }}>
          <Lottie animationData={loading} />
        </Box>
      </Box>
    </Box>
  );
}
