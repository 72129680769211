import React from 'react';
import { Box } from '@mui/material';
import Loading from '../components/Loading';
import GroupsData from './GroupsData';
import { useSelector } from 'react-redux';
import PageNotFound from './PageNotFound';
import Dashboard from '../components/Dashboard';
import fetching from '../lottie/fetching-loading.json';

export default function Home() {
	const groups = useSelector((state) => state.groups);
	return (
		<Dashboard />
	);
}
