import React, { useEffect } from 'react';
import { getTutors, getTutorsId } from '../api';
import { ALL_HEMIS_ID, ALL_TUTORS, HEMIS_ID, TUTORS } from '../redux/action';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FlashOnRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import PageNotFound from './PageNotFound';
import TutorData from '../components/TutorData';
import fetching from '../lottie/fetching-loading.json';

export default function Tutor() {
	const params = useParams();
	const dispatch = useDispatch();
	const hemisId = useSelector((state) => state.hemisId);
	const allTutors = useSelector((state) => state.allTutors);
	useEffect(() => {
		const request = async () => {
			const data = await getTutorsId(params.page);
			dispatch({
				type: HEMIS_ID,
				value: { page: `page_${params.page}`, data: data.results },
			});
			dispatch({
				type: ALL_HEMIS_ID,
				value: data.results,
			});
		};
		request();
	}, [params.page, params.tutor]);

	useEffect(() => {
		const ids = !!hemisId[`page_${params.page}`]
			? hemisId[`page_${params.page}`].map((el) => el.hemis_id)
			: [];
		if (!!hemisId.page_1) {
			const request = async () => {
				const data = await getTutors(ids);
				dispatch({
					type: TUTORS,
					value: { page: `page_${params.page}`, data: data },
				});
				dispatch({
					type: ALL_TUTORS,
					value: data,
				});
			};
			request();
		}
	}, [hemisId, params.page, params.tutor]);

	if (
		!!allTutors.length &&
		allTutors
			.map(
				(el) =>
					el.employee_id_number == params.tutor.replace(/\D/gi, '')
			)
			.filter((el) => el == true).length == 1
	) {
		return (
			<TutorData
				tutor={
					allTutors.filter(
						(el) =>
							el.employee_id_number ==
							params.tutor.replace(/\D/gi, '')
					)[0]
				}
			/>
		);
	} else if (!allTutors.length) {
		return (
			<Box
				sx={{
					minHeight: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<PageNotFound data={fetching} />{' '}
			</Box>
		);
	} else {
		return (
			<Box
				sx={{
					minHeight: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<PageNotFound />{' '}
			</Box>
		);
	}
}
