import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
  createSvgIcon,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StudentsItem from "./StudentsItem";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const GridIcon = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <rect width="10" height="10" rx="4" fill="currentColor" />
    <rect x="12" y="12" width="10" height="10" rx="4" fill="currentColor" />
    <rect y="12" width="10" height="10" rx="4" fill="currentColor" />
    <rect x="12" width="10" height="10" rx="4" fill="currentColor" />
  </svg>
);

const ListIcon = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <rect width="22" height="6" rx="4" fill="currentColor" />
    <rect y="8" width="22" height="6" rx="4" fill="currentColor" />
    <rect y="16" width="22" height="6" rx="4" fill="currentColor" />
  </svg>
);
const HomeIcon = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.57112 6.40501C6.55651e-08 7.44841 0 8.70704 0 11.2243V12.8975C0 17.1884 1.3113e-07 19.3339 1.28873 20.6669C2.57747 22 4.65164 22 8.8 22H13.2C17.3483 22 19.4226 22 20.7112 20.6669C22 19.3339 22 17.1884 22 12.8975V11.2243C22 8.70704 22 7.44841 21.4289 6.40501C20.8578 5.36163 19.8144 4.71406 17.7276 3.41893L15.5276 2.05356C13.3217 0.684519 12.2187 0 11 0C9.78131 0 8.67833 0.684519 6.47243 2.05356L4.27243 3.41894C2.18564 4.71406 1.14224 5.36163 0.57112 6.40501ZM7.7 16.775C7.24437 16.775 6.875 17.1444 6.875 17.6C6.875 18.0556 7.24437 18.425 7.7 18.425H14.3C14.7556 18.425 15.125 18.0556 15.125 17.6C15.125 17.1444 14.7556 16.775 14.3 16.775H7.7Z"
      fill="currentColor"
    />
  </svg>,
  "HomeIcon"
);

const ChairIcon = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M14 8V0H4V4H0V18H8V14H10V18H18V8H14ZM4 16H2V14H4V16ZM4 12H2V10H4V12ZM4 8H2V6H4V8ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM8 4H6V2H8V4ZM12 12H10V10H12V12ZM12 8H10V6H12V8ZM12 4H10V2H12V4ZM16 16H14V14H16V16ZM16 12H14V10H16V12Z"
      fill="currentColor"
    />
  </svg>,
  "ChairIcon"
);

const LocationIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 24C18.6274 24 24 21.3933 24 18.1776C24 16.5388 22.6044 15.058 20.3585 14C18.988 16.7117 16.898 19.049 14.2434 20.2723C12.8165 20.9298 11.1835 20.9298 9.7566 20.2723C7.10194 19.049 5.01199 16.7117 3.6415 14C1.39554 15.058 0 16.5388 0 18.1776C0 21.3933 5.37258 24 12 24Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8.1433C3 3.64588 7.02944 0 12 0C16.9706 0 21 3.64588 21 8.1433C21 12.6055 18.1275 17.8124 13.6458 19.6745C12.6011 20.1085 11.3989 20.1085 10.3542 19.6745C5.87249 17.8124 3 12.6055 3 8.1433ZM12 11.25C13.4202 11.25 14.5714 10.1308 14.5714 8.75C14.5714 7.36929 13.4202 6.25 12 6.25C10.5798 6.25 9.42857 7.36929 9.42857 8.75C9.42857 10.1308 10.5798 11.25 12 11.25Z"
      fill="currentColor"
    />
  </svg>,
  "LocationIcon"
);

export default function AllStudents({
  students,
  handleHomeIcon,
  handleChairIcon,
  handleLocationIcon,
  homeIconButton,
  chairIconButton,
  locationIconButton,
}) {
  const params = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [sort, setSort] = useState("firstName");
  const [sortType, setSortType] = useState(false);
  const [view, setView] = useState(window.innerWidth < 360 ? "list" : "grid");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(
    Object.keys(params).includes("*") && params["*"] == "" ? 1 : +params.page
  );

  useEffect(() => {
    if (Object.keys(params).includes("*") && params["*"] == "") {
      setPage(1);
    } else if (
      typeof +Object.keys(params)[0] == "number" &&
      +params.page > 0 &&
      +params.page <= Math.ceil(students.length / 24)
    ) {
      setPage(+params.page);
    }
  }, [
    params.page,
    students,
    homeIconButton,
    chairIconButton,
    locationIconButton,
  ]);

  useEffect(() => {
    setData(students.slice((page - 1) * 24, (page - 1) * 24 + 24));
  }, [page, students, homeIconButton, chairIconButton, locationIconButton]);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
    window.innerWidth < 360 && setView("list");
  });

  const handleSortType = () => {
    setSortType(!sortType);
  };

  const handleSort = (event) => {
    setSort(event.target.value);
  };

  useEffect(() => {
    width < 360 && setView("list");
  }, [width]);

  data.sort((a, b) => {
    if (sort == "firstName") {
      if (sortType == true) {
        if (a.first_name > b.first_name) {
          return -1;
        }
      } else {
        if (a.first_name < b.first_name) {
          return -1;
        }
      }
    } else if (sort == "secondName") {
      if (sortType == true) {
        if (a.second_name > b.second_name) {
          return -1;
        }
      } else {
        if (a.second_name < b.second_name) {
          return -1;
        }
      }
    }
  });
  return (
    <Box>
      <Typography
        color="text.primary"
        sx={{
          fontFamily: "Ubuntu, sans-serif",
          fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
          fontWeight: "700",
        }}
      >
        Students
      </Typography>
      <Grid2 container>
        <Grid2 xs={12} md={8} lg={10}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "500" }}
            color="text.secondary"
          >
            Tyutorga biriktirilgan ushbu guruhga tegishli bo'lgan talabalarning
            ro'yxati.
          </Typography>
        </Grid2>
        <Grid2
          xs={12}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <FormControl
            fullWidth
            size="small"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mt: { xs: 4, md: 0 },
              minWidth: { xs: "100%", md: 230 },
            }}
          >
            <Box
              onClick={handleSortType}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 18, fontWeight: "600" }}
                color="text.secondary"
              >
                Saralash
              </Typography>
              <KeyboardArrowUpIcon
                sx={{
                  transition: "0.3s all linear",
                  color: "text.secondary",
                  transform:
                    sortType === false ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </Box>
            <Select
              value={sort}
              onChange={handleSort}
              sx={{
                width: "140px",
                color: "text.secondary",
                fontSize: 18,
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: "600",
                "& fieldset": {
                  border: "none !important",
                },
              }}
            >
              <MenuItem value={"firstName"} sx={{ color: "text.secondary" }}>
                Ismi
              </MenuItem>
              <MenuItem value={"secondName"} sx={{ color: "text.secondary" }}>
                familiyasi
              </MenuItem>
            </Select>
          </FormControl>
        </Grid2>
      </Grid2>
      <Box
        sx={{
          mt: { xs: 1, md: 4 },
          mb: { xs: 2, md: 3 },
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Paper
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              py: 0.5,
              borderRadius: 3,
            }}
          >
            <HomeIcon
              onClick={handleHomeIcon}
              sx={{
                color: homeIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#fff"
                  : "text.secondary",
                background: homeIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#1976d2" : "#121212"
                  : "text.secondary",
                p: 1,
                borderRadius: 3,
                mr: 1,
                ml: 1,
                display: width < 360 && "none",
                transition: "0.3s all linear",
                "&:hover": {
                  color: !homeIconButton
                    ? (theme) =>
                        theme.palette.mode === "light" ? "#1976d2" : "#fff"
                    : (theme) =>
                        theme.palette.mode === "light" ? "#fff" : "#fff",
                },
              }}
            />
            <ChairIcon
              onClick={handleChairIcon}
              sx={{
                color: chairIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#fff"
                  : "text.secondary",
                background: chairIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#1976d2" : "#121212"
                  : "text.secondary",
                p: 1,
                borderRadius: 3,
                mr: 1,
                display: width < 360 && "none",
                transition: "0.3s all linear",
                "&:hover": {
                  color: !chairIconButton
                    ? (theme) =>
                        theme.palette.mode === "light" ? "#1976d2" : "#fff"
                    : (theme) =>
                        theme.palette.mode === "light" ? "#fff" : "#fff",
                },
              }}
            />
            <LocationIcon
              onClick={handleLocationIcon}
              sx={{
                color: locationIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#fff"
                  : "text.secondary",
                background: locationIconButton
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#1976d2" : "#121212"
                  : "text.secondary",
                p: 1,
                borderRadius: 3,
                mr: 1,
                display: width < 360 && "none",
                transition: "0.3s all linear",
                "&:hover": {
                  color: !locationIconButton
                    ? (theme) =>
                        theme.palette.mode === "light" ? "#1976d2" : "#fff"
                    : (theme) =>
                        theme.palette.mode === "light" ? "#fff" : "#fff",
                },
              }}
            />
          </Paper>
        </Box>

        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <GridIcon
            onClick={() => (width > 360 ? setView("grid") : setView("list"))}
            sx={{
              color:
                view === "grid"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#fff"
                  : "text.secondary",
              background:
                view === "grid"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#1976d2" : "#121212"
                  : "none",
              p: 1,
              borderRadius: 3,
              mr: 1,
              display: width < 360 && "none",
              transition: "0.3s all linear",
              "&:hover": {
                color:
                  view === "list"
                    ? (theme) =>
                        theme.palette.mode === "light" ? "#1976d2" : "#fff"
                    : "#fff",
              },
            }}
          />
          <ListIcon
            onClick={() => setView("list")}
            sx={{
              color:
                view === "list"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#fff"
                  : "text.secondary",
              background:
                view === "list"
                  ? (theme) =>
                      theme.palette.mode === "light" ? "#1976d2" : "#121212"
                  : "none",
              p: 1,
              borderRadius: 3,
              transition: "0.3s all linear",
              "&:hover": {
                color:
                  view === "grid"
                    ? (theme) =>
                        theme.palette.mode === "light" ? "#1976d2" : "#fff"
                    : "#fff",
              },
            }}
          />
        </Box>
      </Box>
      <Grid2 container>
        {data.map((el) => {
          return (
            <StudentsItem
              key={el.id}
              id={el.id}
              view={view}
              firstName={el.first_name}
              lastName={el.second_name}
              img={el.image}
              educationForm={el.educationForm.name}
              groupName={el.paymentForm.name}
              groupLang={el.group.educationLang.name}
              sort={sort}
              sortType={sortType}
              student_id_number={el.student_id_number}
            />
          );
        })}
      </Grid2>
    </Box>
  );
}
