import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { getStudentsAll, getWaitingMissia } from "../api";
import WaitingDataTableRow from "./WaitingDataTableRow";
import { useDispatch, useSelector } from "react-redux";
import { WAITING_MISSIA, WAITING_STUDENT_WITH_ID } from "../redux/action";
import { Box, CircularProgress } from "@mui/material";

const columns = [
  { id: "index", label: "Tr", minWidth: 10 },
  { id: "student", label: "Talabaning ismi, familiyasi", minWidth: 170 },
  { id: "tutor", label: "Tyutorning ismi, familiyasi", minWidth: 170 },
  {
    id: "group",
    label: "Talaba guruhi",
    minWidth: 150,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "date",
    label: "Vaqt",
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Boshqaruv",
    minWidth: 50,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export default function WaitingDataTable({ screen }) {
  const dispatch = useDispatch();
  const waitingMissia = useSelector((state) => state.waitingMissia);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(24);
  const [count, setCount] = useState(0);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    const data = await getWaitingMissia(newPage+1);
      if (data.status == 200) {
        setCount(data.data.count);
        dispatch({
          type: WAITING_MISSIA,
          value: { page: `page_${newPage+1}`, data: data.data.results },
        });
      }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const res = async () => {
      const data = await getWaitingMissia(1);
      if (data.status == 200) {
        setCount(data.data.count);
        dispatch({
          type: WAITING_MISSIA,
          value: { page: "page_1", data: data.data.results },
        });
      }
    };
    res();
  }, []);

  useEffect(() => {
    if (!!waitingMissia[`page_${page + 1}`]) {
      const res = async () => {
        const students = await getStudentsAll(
          waitingMissia[`page_${page + 1}`].map((el) => el.student_hemis_id)
        );
        if (students.length > 0) {
          students.map((el) => {
            return dispatch({
              type: WAITING_STUDENT_WITH_ID,
              value: {
                id: el.student_id_number,
                student: el,
              },
            });
          });
        }
      };
      res();
    }
  }, [waitingMissia]);

  return (
    <Paper
      sx={{
        minWidth: "100%",
        overflow: "hidden",
        borderRadius: 3,
        transition: "all 0.3s",
        transform: `translate(${screen ? "-100%" : 0})`,
      }}
    >
      {waitingMissia[`page_${page + 1}`] == undefined ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                      fontFamily: "Ubuntu, sans-serif",
                      fontWeight: 500,
                      fontSize: 16,
                    }}
                    key={column.id}
                    // align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {waitingMissia[`page_${page + 1}`].map((el, index) => {
                return <WaitingDataTableRow data={el} key={index} index={index} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        rowsPerPageOptions={[24]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
