import { Box, InputLabel, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MuiFileInput } from "mui-file-input";
import { LoadingButton } from "@mui/lab";
import { setDocument } from "../api";
import { useSelector } from "react-redux";

export default function AddCatalog(props) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const catalogs = useSelector((state) => state.catalogs);

  const onSubmit = async (data) => {
    const request = await setDocument(
      data.name,
      data.catalog,
      data.comment
    );
    reset();
    console.log(data.name);
  };

  return (
    <Box>
      <Typography
        color="text.primary"
        sx={{
          fontFamily: "Ubuntu, sans-serif",
          fontSize: { xs: 28, sm: 30, md: 34, lg: 36 },
          fontWeight: "700",
        }}
      >
        Fayl Yuklash
      </Typography>
      <Grid2 container>
        <Grid2 xs={12} md={8} lg={8}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "500" }}
            color="text.secondary"
          >
            Sizga berilgan topshiriq(vazifa)larni pdf, word, excel ko'rinishida
            platformaga yuklang. Topshiriqlarni belgilangan vaqt ishida
            topshiring!
          </Typography>
        </Grid2>
      </Grid2>
      <Box sx={{ mt: { xs: 1, md: 4 }, mb: { xs: 2, md: 3 } }}>
        <Typography
          color="text.primary"
          sx={{
            fontSize: 22,
            fontWeight: "600",
            fontFamily: "Ubuntu, sans-serif",
            opacity: 0.75,
          }}
        >
          Forma
        </Typography>
        <Box>
          <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Grid2 container>
              <Grid2 xs={12} md={6}>
                <Box sx={{ p: 0.5 }}>
                  <Typography
                    color="text.primary"
                    sx={{
                      m: 0.5,
                      mt: 2,
                      fontSize: 16,
                      fontFamily: "Ubuntu, sans-serif",
                    }}
                  >
                    Faylni nomlang:
                  </Typography>
                  <TextField
                    placeholder="Topshiriq nomi..."
                    variant="outlined"
                    type="text"
                    {...register("name", { required: true, minLength: 4 })}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: 3,
                        background: (theme) =>
                          theme.palette.mode === "light" ? "#fff" : "#09090d3f",
                      },
                    }}
                  />
                  {!!errors.name && (
                    <Typography
                      color="error.main"
                      sx={{
                        ml: 1,
                        fontSize: 14,
                        fontFamily: "Ubuntu, sans-serif",
                      }}
                    >
                      Katalog nomi kamida 3 belgidan iborat bo'lishi kerak!
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ mt: 2, borderRadius: 3 }}
                  >
                    Yuklash
                  </LoadingButton>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
