import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../pages/PageNotFound";
import Home from "../pages/Home";
import Groups from "../pages/Groups";
import Group from "../pages/Group";
import StudentsData from "../pages/StudentsData";
import Student from "../pages/Student";
import Documents from "../pages/Documents";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "../redux/store";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { getCatalog, getDocument, getUserData } from "../api";
import GeneratePDF from "../GeneratePDF/GeneratePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  ALL_STUDENTS,
  CATALOGS,
  FILES,
  GROUPS,
  USER_DATA,
} from "../redux/action";
import UploadFile from "../pages/UploadFile";
import Messages from "../pages/Messages";
import Topshiriqlar from "../pages/Topshiriqlar";
import TyutorTopshiriqlar from "../pages/TyutorTopshiriqlar";

export default function TutorRoutes(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const userData = useSelector((state) => state.userData);
  const files = useSelector(state => state.files)

  useEffect(() => {
    if (props.auth) {
      const res = async () => {
        const data = await getUserData(props.setAuth);
        
        dispatch({ type: USER_DATA, value: JSON.parse(data) });
        dispatch({ type: GROUPS, value: JSON.parse(data).tutorGroups });
        dispatch({
          type: ALL_STUDENTS,
          value: [JSON.parse(data).tutorGroups.map((el) => el.id)]
            .flat()
            .flat(),
        });
      };
      res();
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const request = async () => {
      const data = await getCatalog();
      dispatch({ type: CATALOGS, value: data });
    };
    request();
  }, [userData, files]);

  useEffect(() => {
    const request = async () => {
      const data = await getDocument("1");
      dispatch({
        type: FILES,
        value: { params: "page_1", data: data.results },
      });
    };
    request();
  }, []);

  return (
    <Header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/groups" element={<Groups />} />
        <Route path="/groups/:groupId" element={<Group />} />
        <Route path="/all_students/*" element={<StudentsData />} />
        <Route path="/all_students/:page" element={<StudentsData />} />
        <Route path="/students/" element={<PageNotFound />} />
        <Route path="/students/:studentId" element={<Student />} />
        <Route path="/documents/*" element={<Documents />} />
        <Route path="/documents/:katalogName" element={<Documents />} />
        <Route path="/documents/:katalogName/:page" element={<Documents />} />
        <Route path="/documents/upload_file" element={<UploadFile />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/assigments" element={<TyutorTopshiriqlar />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Header>
  );
}

